import React, { useEffect, useState, useContext } from 'react'
import classes from './GrantDetails.module.css'
import { Nav, Navbar } from 'react-bootstrap'
import DifficultyBars from '../../UI/DifficultyBars/DifficultyBars'
import { StoreContext } from '../../../Store/StoreContext'
import instance from '../../../Assets/Axios/AxiosInstance'
import { selectProfileImage } from '../../../Assets/Functions/ProfileImagePicker'
import ReportAtPortal from './ReportAtPortal/ReportAtPortal'
import { Mixpanel } from '../../../Assets/Functions/MixPanel/MixPanel'
import thumbsUpFillIcon from '../../../Assets/Images/hand-thumbs-up-fill.svg'
import thumbsDownFillIcon from '../../../Assets/Images/hand-thumbs-down-fill.svg'
import thumbsUpIcon from '../../../Assets/Images/hand-thumbs-up.svg'
import thumbsDownIcon from '../../../Assets/Images/hand-thumbs-down.svg'
import axios from 'axios'
import PopupModal from '../../UI/PopUps/PopupModal'
import shareIcon from '../../../Assets/Images/share-fill.svg'
import { encrypt } from '../../../Assets/Functions/Encrypter'
import formatPrice from '../../../Assets/Functions/PriceFormater'

const GrantDetails = (props) => {
    const { state, actions } = useContext(StoreContext)

    const [grant, setGrant] = useState({
        loading: true,
        region: [],
        industry: [],
        detailsNav: {
            applicationReq: {
                flag: false,
                applicationReq: ''
            },
            elegibility: {
                flag: true,
                compReq: '',
                inelig: '',
                projEleg: '',
                elegAct: '',
                elegCan: '',
                roleReq: ''
            },
            reportingReq: {
                flag: false,
                reportingReq: ''
            }
        },
        currentSubNav: 'elegibility',
        likedClicked: false,
        thumbsDownClicked: false,
        shareClicked: false,
        mixpanelTracked: false,
        signInSignUp: false,
        grantHelpClicked: false
    })

    let thumbsUp, thumbsDown
    if (grant.liked == 0) {
        thumbsUp = thumbsUpIcon
        thumbsDown = thumbsDownFillIcon
    } else if (grant.liked == 1) {
        thumbsUp = thumbsUpFillIcon
        thumbsDown = thumbsDownIcon
    } else {
        thumbsUp = thumbsUpIcon
        thumbsDown = thumbsDownIcon
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()
        if (state.grantDetails.details.liked !== grant.liked && grant.likedClicked) {
            callGrantLiked(source)
        }
        return () => {
            source.cancel('axios liked grant request cancelled')
        }
    }, [grant.liked])


    const callGrantLiked = async (source) => {
        if (checkIfSingleGrantView({ 'liked': grant.liked })) {
            await instance.patch('/api/matches/' + grant.grantID + '/', { liked: grant.liked }, { cancelToken: source.token }).then(res => {
                console.log(res)
                if (state.grantDetails.details.liked == 0 || res.data.liked == 0) {
                    console.log(res.data)
                    actions.setLikedState(res.data)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const detailsBody = {
        applicationReq: <>{grant.detailsNav.applicationReq.applicationReq !== undefined ? grant.detailsNav.applicationReq.applicationReq : <ReportAtPortal link={grant.app_link} title="Applications for this grant is done directly through their portal." />}</>,
        elegibility: <>
            {grant.detailsNav.elegibility.compReq}
            {grant.detailsNav.elegibility.inelig}
            {grant.detailsNav.elegibility.projEleg}
            {grant.detailsNav.elegibility.elegAct}
            {grant.detailsNav.elegibility.elegCan}
            {grant.detailsNav.elegibility.roleReq}
        </>,
        reportingReq: <>
            {grant.detailsNav.reportingReq.reportingReq !== undefined ? grant.detailsNav.reportingReq.reportingReq : <ReportAtPortal link={grant.app_link} title="Reporting for this grant is done directly through their portal." />}
        </>
    }

    const formatText = (str, title) => {
        let temp = []
        let result
        if (str !== undefined && str !== '') {
            temp = str.split(/[+]/g).map((i, k) => {
                if (k === 0 && i !== '') {
                    return <p key={k}>{i}</p>
                }
                if (i !== '') {
                    return <li key={k}>{i}</li>
                }
            });
            result = <div >
                <p><b>{title}</b></p>
                <ul className={classes.listOf}>{temp}</ul>
            </div>
        }
        // console.log(title,str,str == '')
        return result
    }

    // Function used to set the grant page details on first load and when a new grant is selected
    useEffect(() => {
        if (state.grantDetails.details !== false) {
            Mixpanel.trackLinks('#apply', 'Link Tracking For ‘Apply for Grant’ ', { grant_name: state.grantDetails.details.grant.name })
            Mixpanel.trackLinks('#finance', 'Link Tracking For ‘Finance this Grant’ ', { grant_name: state.grantDetails.details.grant.name })
            setGrant(old => {
                let temp = { ...state.grantDetails.details.grant }
                let result = { ...old, ...temp }

                result.difficulty = setDifficulty(temp.process_time)
                result.detailsNav.applicationReq.applicationReq = formatText(state.grantDetails.details.grant.app_req, 'Application Requirements')
                result.detailsNav.elegibility.compReq = formatText(state.grantDetails.details.grant.comp_req, 'Company Requirements')
                result.detailsNav.elegibility.inelig = formatText(state.grantDetails.details.grant.ineligibility, 'Ineligibility')
                result.detailsNav.elegibility.projEleg = formatText(state.grantDetails.details.grant.project_eligibility, 'Project Eligibility')
                result.detailsNav.elegibility.elegAct = formatText(state.grantDetails.details.grant.eligible_activities, 'Eligible Activities')
                result.detailsNav.elegibility.elegCan = formatText(state.grantDetails.details.grant.eligibility_cand, 'Eligible Candidates')
                result.detailsNav.elegibility.roleReq = formatText(state.grantDetails.details.grant.role_req, 'Role Requirements')
                result.detailsNav.reportingReq.reportingReq = formatText(state.grantDetails.details.reporting_req, 'Role Requirements')
                result.loading = false
                result.saved = state.grantDetails.details.saved
                result.grantID = state.grantDetails.details.id
                result.liked = state.grantDetails.details.liked
                result.image = selectProfileImage(temp.name)
                if (!result.mixpanelTracked) {
                    Mixpanel.timeEvent('Grant Viewed')
                    Mixpanel.people.increment(state.grantDetails.details.grant.name)
                    result.mixpanelTracked = true
                }
                return result
            })
        }
        return () => {
            // Mixpanel.track('Grant Viewed', { grant_name: state.grantDetails.details.grant.name })
        }
    }, [state.grantList, state.grantDetails.selected])


    const setDifficulty = (x) => {
        let res
        switch (x) {
            case 'Long':
                res = 3
                break
            case 'Moderate':
                res = 2
                break
            case 'Quick':
                res = 1
                break
        }
        return res
    }


    // sets the flag of the selected detailsNav item, which triggers the function above
    const detailsNavClick = (i) => {
        setGrant((old) => {
            let temp = { ...old };
            Object.keys(temp.detailsNav).forEach((k) => {
                if (i == k) {
                    temp.detailsNav[k].flag = true
                    temp.currentSubNav = k
                }
                else {
                    temp.detailsNav[k].flag = false
                }
            })
            return temp
        })
    }


    const bookMarkClick = (e) => {
        e.stopPropagation()
        if (checkIfSingleGrantView({ 'saved': true })) {
            instance.patch('/api/matches/' + grant.grantID + '/', { saved: !state.grantDetails.details.saved }).then(res => {
                console.log(res)
                actions.setGrantSaved(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const makeCommaList = (item) => {
        let res
        if (item.length > 1) {
            res = item.map((i, k) => {
                if (++k !== item.length) {
                    return i.name + ', '
                }
                else {
                    return i.name
                }
            })
            return res
        }
        else if (item.length == 1) {
            return item[0].name
        }
    }


    const thumbsButtonClicked = (dir) => {
        if (dir === 'up') {
            if (grant.liked == 1) {
                setGrant(old => {
                    let res = { ...old }
                    res.liked = 2
                    res.likedClicked = true
                    return res
                })
            } else {
                setGrant(old => {
                    let res = { ...old }
                    res.liked = 1
                    Mixpanel.track('Thumbs Up Tracking', { grant_name: grant.name })
                    res.likedClicked = true
                    return res
                })
            }
        }
        else {
            if (grant.liked == 0) {
                setGrant(old => {
                    let res = { ...old }
                    res.liked = 2
                    res.likedClicked = true
                    return res
                })
            } else {
                setGrant(old => {
                    let res = { ...old }
                    res.liked = 0
                    res.likedClicked = true
                    res.thumbsDownClicked = true
                    return res
                })
            }
        }
    }

    const shareButtonClicked = () => {
        setGrant(old => {
            let res = { ...old }
            res.shareClicked = true
            return res
        })
    }

    const thumbsDownBackgroundClicked = () => {
        setGrant(old => {
            let res = { ...old }
            res.thumbsDownClicked = false
            Mixpanel.track('Thumbs Down Tracking', {
                doesNotMeetRequirements: false,
                doNotUnderstandApplication: false,
                grantNotAcceptingApplications: false,
                other: '',
                grant_name: res.name
            })
            return res
        })
    }

    const grantHelpButtonClicked = () => {
        setGrant(old => {
            let res = { ...old }
            res.grantHelpClicked = true
            return res
        })
    }

    const shareBackgroundClicked = () => {
        setGrant(old => {
            let res = { ...old }
            res.shareClicked = false
            return res
        })
    }

    const thumbsDownFormSubmitted = (fields) => {
        // console.log('here')
        setGrant(old => {
            let res = { ...old }
            fields.grant_name = res.name
            res.thumbsDownClicked = false
            Mixpanel.track('Thumbs Down Tracking', fields)
            return res
        })

    }

    const grantHelpBackgroundClicked = () => {
        setGrant(old => {
            let res = { ...old }
            res.grantHelpClicked = false
            return res
        })
    }

    const checkIfSingleGrantView = (body) => {
        if (props.singleGrantView && !state.currentUser.loggedIn) {
            setGrant(old => {
                let res = { ...old }
                res.signInSignUp = !res.signInSignUp
                return res
            })
            return false
        } else if (props.singleGrantView) {
            instance.patch('/shared-grant/?grant_id=' + grant.id, body).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
            return false
        }
        else {
            return true
        }
    }

    const visitURL = (url) => {
        Mixpanel.track('Grant Apply Clicked', { grant_name: grant.name })
        window.open(url, '_blank')
    }

    return (
        <div className={`container ` + classes.container}>
            {grant.loading ? '' : <>
                {grant.signInSignUp ? <PopupModal backgroundClicked={checkIfSingleGrantView} type="signInSignUp" /> : ''}
                {grant.thumbsDownClicked ? <PopupModal backgroundClicked={thumbsDownBackgroundClicked} thumbsDownFormSubmitted={thumbsDownFormSubmitted} type="thumbsDown" /> : ''}
                {/* grant link is hard coded in to hellopocketed.io, this is so clearbank users who share our grants are sending users to our platform and not clearbanks  */}
                {grant.shareClicked ? <PopupModal backgroundClicked={shareBackgroundClicked} type="share" grantLink={'https://hellopocketed.io/grant?id=' + encrypt(grant.id) + (state.grantDetails.details.country.name === 'Canada' ? '&country=canada' : '&country=US')} /> : ''}
                {grant.grantHelpClicked ? <PopupModal backgroundClicked={grantHelpBackgroundClicked} type="grantHelp" /> : ''}
                <div className={classes.header + ' flex-column-reverse  flex-sm-row'}>
                    <div className={classes.headerLeft}>
                        <div className={classes.title}>
                            <img src={grant.image} />
                            <div className={classes.titleContent}>
                                <div className={classes.titleHeaders}>
                                    <h2>{grant.name}</h2>
                                    <h3>{grant.issuer}</h3>
                                </div>
                                <button className={"clearButton"} style={{ paddingBottom: "35px", zIndex: 5 }} onClick={(e) => bookMarkClick(e)}>
                                    {state.grantDetails.details.saved ?
                                        <svg className={classes.bookmarkSvg + ' bi bi-bookmark-check-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 0a2 2 0 0 0-2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4zm6.854 5.854a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                        </svg> :
                                        <svg className={classes.bookmarkSvg + ' bi bi-bookmark'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                                            <path fillRule="evenodd" d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                        <div className={classes.headerButtons}>
                            {props.singleGrantView && !state.currentUser.loggedIn ? <>
                                <a onClick={() => checkIfSingleGrantView()} className={classes.btn + ' ' + classes.applyBtn} id='apply'>Apply</a>
                                <a onClick={() => checkIfSingleGrantView()} className={classes.btn + ' ' + classes.getFundedBtn} id='finance'>Finance This Grant</a>
                                <a onClick={() => checkIfSingleGrantView()} className={classes.btn + ' ' + classes.hireGrantWritterBtn} id='hireGW'>Hire a Grant Writer</a>
                                {/* <a onClick={() => checkIfSingleGrantView()} className={classes.btn + ' ' + classes.applyBtn} id='grantHelp'>Grant Help</a> */}
                            </> : <>
                                <a onClick={() => visitURL(grant.program_site)} className={classes.btn + ' ' + classes.applyBtn} target="_blank" id='apply'>Apply</a>
                                <a href="/grant-based-financing" className={classes.btn + ' ' + classes.getFundedBtn} id='finance'>Finance This Grant</a>
                                <a href="/experts/grant-writer" className={classes.btn + ' ' + classes.hireGrantWritterBtn} target='_blank' id='hireGW'>Hire a Grant Writer</a>
                                {/* <button onClick={() => grantHelpButtonClicked()} className={classes.btn + ' ' + classes.applyBtn} id='grantHelp'>Grant Help</button> */}
                            </>}
                        </div>
                    </div>
                    <div className={classes.headerRight}>
                        <div className={classes.headerRightTop}>
                            <button className={"clearButton " + classes.thumbs} onClick={() => thumbsButtonClicked('up')}><img src={thumbsUp} /></button>
                            <button className={"clearButton " + classes.thumbs} onClick={() => thumbsButtonClicked('down')}><img src={thumbsDown} /></button>
                            <button className={"clearButton " + classes.share} onClick={() => shareButtonClicked()}><img src={shareIcon} /></button>
                        </div>
                        <div className={classes.headerRightBottom}>
                            <div className={classes.diffBars + ' d-none d-sm-flex'}>
                                <DifficultyBars difficulty={grant.difficulty} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.threeColumn}>
                    <div className={classes.column}>
                        <div className={classes.columnTop}>
                            <h3>Grant</h3>
                        </div>
                        <div className={classes.columnBottom}>
                            <div className={classes.grantSection}>
                                <p><b>Type:</b> {grant.grant_type.map((i, k) => {
                                    if (++k !== grant.grant_type.length) {
                                        return i.type + ', '
                                    }
                                    else {
                                        return i.type
                                    }
                                })}</p>
                                <p><b>Amount:</b> {grant.amount !== 0 ? '$' + formatPrice(grant.amount) : 'Amount Varies'}</p>
                                <p><b>Regions:</b> {grant.region.length !== 0 ? makeCommaList(grant.region) : 'All Regions'}</p>
                                <p><b>Industries:</b> {grant.industry.length !== 0 ? makeCommaList(grant.industry) : 'All Industries'}</p>
                                <p><b>From:</b> {grant.provider}</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.columnTop}>
                            <h3>Application</h3>
                        </div>
                        <div className={classes.columnBottom}>
                            <div className={classes.applicationSection}>
                                <p><b>Deadline:</b> {grant.deadlines}</p>
                                <p><b>Application Process:</b> {grant.process_time}</p>
                                <p><b>Funding Scheme:</b> {grant.cash_up ? 'Cash upfront required' : 'Cash upfront not required'}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.about}>
                    <h3>About {grant.name}</h3>
                    <p>{grant.description}</p>
                    {grant.value !== '' && grant.value !== undefined ? <>
                        <h3>Grant Value</h3>
                        {formatText(grant.value)}
                    </> : ''
                    }
                    {grant.timeline !== '' && grant.timeline !== undefined ? <>
                        <h3>Grant Timeline</h3>
                        <p>{grant.timeline}</p>
                    </> : ''
                    }
                    {/* <h3>Funding Timeline</h3>
                    <p>{grant.description}</p> */}
                </div>
                <div className={classes.details}>
                    <Navbar className={classes.detailsNavContainer}>
                        <Nav className={classes.detailsNav}>
                            <Nav.Link active={grant.detailsNav['elegibility'].flag} className={grant.detailsNav['elegibility'].flag ? classes.activeNav : classes.nonActiveNav} onClick={() => detailsNavClick('elegibility')}><h3>Eligibility</h3></Nav.Link>
                            <Nav.Link active={grant.detailsNav['applicationReq'].flag} className={grant.detailsNav['applicationReq'].flag ? classes.activeNav : classes.nonActiveNav} onClick={() => detailsNavClick('applicationReq')}><h3>Application Requirements</h3></Nav.Link>
                            <Nav.Link active={grant.detailsNav['reportingReq'].flag} className={grant.detailsNav['reportingReq'].flag ? classes.activeNav : classes.nonActiveNav} onClick={() => detailsNavClick('reportingReq')}><h3>Reporting Requirements</h3></Nav.Link>
                        </Nav>
                    </Navbar>
                    <div className={classes.detailsBody}>
                        {detailsBody[grant.currentSubNav]}
                    </div>
                </div>
            </>}
        </div >
    )
}

export default GrantDetails