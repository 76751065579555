import React, { useContext, useEffect, useRef, useState } from 'react'
import GrantDetails from '../../Components/AppicationComponents/GrantDetails/GrantDetails'
import GrantList from '../../Components/AppicationComponents/GrantList/GrantList'
// import Loading from '../../Components/UI/Loading/Loading'
import GrantSavedPopup from '../../Components/UI/PopUps/GrantSavedPopup/GrantSavedPopup'
// import PopupModal from '../../Components/UI/PopUps/PopupModal'
import Layout from '../../HOC/Layout/Layout'
import { StoreContext } from '../../Store/StoreContext'
import classes from './AvailableGrants.module.css'
import FilterBar from '../../Components/AppicationComponents/FilterBar/FilterBar'
import FirstLoadGrantView from '../../Components/AppicationComponents/GrantDetails/FirstLoadGrantView/FirstLoadGrantView'
import { withRouter } from 'react-router-dom'
import { setFilterParams } from '../../Assets/Functions/Filters/FilterFunctions'
import instance from '../../Assets/Axios/AxiosInstance'
import axios from 'axios'
import AdditionalFilters from '../../Components/AppicationComponents/FilterBar/AdditionFilters/AdditionalFilters'


const AvailableGrants = (props) => {
    const { state, actions } = useContext(StoreContext)
    const screenRef = useRef()
    const [showGrantSaved, setShowGrantSaved] = useState({ flag: true, check: false })
    const [showDetails, setShowDetails] = useState({
        list: ' ',
        details: ' d-none'
    })

    // Used to reset filters on first time load, mainly so if a user filters in Availble Grants, they won't be confused if they navigate to
    // the Saved Grants page and only see a small portion of their saved grants
    useEffect(() => {
        let filterSelection = { ...state.filterSelection }
        let res = { ...filterSelection.selected }
        res = {
            ...res,
            eligibilityCand: [],
            roleReq: [],
            eligibileAct: [],
            eligibileProject: [],
            grantTypes: [],
            new: ["All Grants"],
            amountGte: [],
            processTime: [],
            cashUp: []
        }
        actions.setFilterSelection({ selected: res, level: -1 })
    }, [])


    // Used for setting filters
    useEffect(() => {
        let selectionKeyArray = Object.keys(state.filterSelection.selected)
        actions.setGrantListLoading(true)
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()
        // if the current page is saved grants, add the saved grants filter to the api call, if not, make the api call without the saved grant filter
        if (props.savedGrants) {
            // console.log('saved grants')
            // if any filters are selected, retrieve saved grants with selected filters, otherwise retrieve all saved grants
            let res = setFilterParams(selectionKeyArray, state)
            if (props.match.isExact) {
            }

            instance.get('/api/matches/?saved=True' + res, { cancelToken: source.token }).then(res => {
                console.log('grants filtered', res.data.results)
                actions.setGrantList(res.data)

            }).catch(err => {
                console.log(err)
            })

            return () => {
                source.cancel('axios saved grants request cancelled')
            }
        } else {
            // if any filters are selected, retrieve grants with selected filters, otherwise retrieve all grants
            let res = setFilterParams(selectionKeyArray, state)
            instance.get('/api/matches/?' + res, { cancelToken: source.token }).then(res => {
                console.log('grants filtered', res)
                actions.setGrantList(res.data)

            }).catch(err => {
                console.log(err)
            })

            return () => {
                source.cancel('axios available grants request cancelled')
            }
        }
    }, [state.filterSelection])

    // this is used to determin whether to show Grant Details or Grant List on mobile and tablet views
    useEffect(() => {
        // If first time loading pick List view
        if (!state.grantDetails.picked) {
            setShowDetails({
                list: ' ',
                details: ' d-none',
                filterbar: true
            })
            // If a grant has been picked
        } else if (state.grantDetails.picked && state.grantDetails.details !== false) {
            setShowDetails({
                list: ' d-none',
                details: ' ',
                filterbar: false
            })
            //  If the back button has been clicked
        } else {
            setShowDetails({
                list: ' ',
                details: ' d-none',
                filterbar: true
            })
        }
    }, [state.grantDetails])

    useEffect(() => {
        if (state.grantSaved) {
            setShowGrantSaved({ flag: false, check: true })
            actions.setGrantSaved(false)
        }
    }, [state.grantSaved])

    useEffect(() => {
        if (!showGrantSaved.flag) {
            setShowGrantSaved({ flag: true, check: true })
        }
    }, [showGrantSaved.flag])

    const backgroundClicked = () => {
        actions.setPopup({ flag: false, message: '' })
    }

    // Loads and sets the filter options for the subfilters located in the Additional Filters component
    useEffect(() => {
        if (!state.subFilters.loaded) {
            instance.get('/filter-types/').then(res => {
                // console.log('filter types', res)
                actions.setSubFilters({data: res.data, loaded: true})
            }).catch(err => {
                console.log(err)
            })
        }
    }, [])

    // console.log(props)
    return (
        <Layout>
            {/* <PopupModal backgroundClicked={() => {}}/> */}
            <div className={classes.container} ref={screenRef}>
                <div className={classes.filter}>
                    <FilterBar filterBarDisplay={showDetails.filterbar} showBackButton={showDetails.details} pageName={props.pageName} />
                </div>
                {/* Full Screen */}
                <div className={classes.body + ' d-none d-lg-flex'}>
                    <div className={classes.grantList}>
                        <GrantList mobile={false} />
                    </div>
                    <div className={classes.grantDetails}>
                        {state.additionalFilters.flag ? <div className={classes.additionalFiltersContainer} onClick={(() => actions.setAdditionalFilters({ flag: false }))}>
                            <AdditionalFilters />
                        </div> : <></>}
                        {state.grantDetails.details == false ?
                            <FirstLoadGrantView /> : <GrantDetails />}
                    </div>
                </div>
                {/* Mobile + Tablet */}
                <div className={classes.body + ' d-flex d-lg-none'}>
                    <div className={classes.grantListTablet + showDetails.list} >
                        {state.additionalFilters.flag ? <div className={classes.additionalFiltersContainer} onClick={(() => actions.setAdditionalFilters({ flag: false }))}>
                            <AdditionalFilters />
                        </div> : <></>}
                        <GrantList mobile={true} />
                    </div>
                    <div className={classes.grantDetailsTablet + showDetails.details} style={{ display: '' }}>
                        {state.grantDetails.details == false ?
                            <FirstLoadGrantView /> : <GrantDetails />}
                    </div>
                </div>
            </div>
            {/* {state.popup.flag ? <PopupModal /> : ''} */}
            {showGrantSaved.check && showGrantSaved.flag ? <GrantSavedPopup backgroundClicked={backgroundClicked} /> : ''}
        </Layout>
    )
}

export default withRouter(AvailableGrants)