import React, { useContext } from 'react'
import PopupModal from '../../../Components/UI/PopUps/PopupModal'
import { StoreContext } from '../../../Store/StoreContext'
import classes from './LayoutNoNav.module.css'

const LayoutNoNav = ({ children }) => {
    const {state, actions} = useContext(StoreContext)

    const backgroundClicked = () => {
        actions.setPopup({flag: false, message: ''})
    }

    return (
        <div className={classes.container} id='altLayout'>
            {state.popup.flag ?
                <PopupModal backgroundClicked={backgroundClicked}/> : ''}
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}

export default LayoutNoNav