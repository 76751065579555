import React, { useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router'
import instance from '../../Assets/Axios/AxiosInstance'
import GrantDetails from '../../Components/AppicationComponents/GrantDetails/GrantDetails'
import classes from '../AvailableGrants/AvailableGrants.module.css'
import CryptoJS from 'crypto-js'
import Layout from '../../HOC/Layout/Layout'
import FilterBar from '../../Components/AppicationComponents/FilterBar/FilterBar'
import fakeList from '../../Assets/Images/fake-grant-list.png'
import { StoreContext } from '../../Store/StoreContext'
import Loading from '../../Components/UI/Loading/Loading'
import { decrypt } from '../../Assets/Functions/Encrypter'
import classesAlt from './SingleGrantView.module.css'
import CustomButton from '../../Components/UI/CustomButton/CustomButton'
import PopupModal from '../../Components/UI/PopUps/PopupModal'
import { useLocation } from 'react-router-dom'
import toolsIcon from '../../Assets/Images/tools.svg';


const SingleGrantView = (props) => {
    const screenRef = useRef()
    const { state, actions } = useContext(StoreContext)
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const grantID = useQuery().get('id')
    const country = useQuery().get('country')

    const [grant, setGrant] = useState({
        loading: true,
        grantFound: true
    })


    useEffect(() => {
        try {
            let bytes = decrypt(grantID.replace('/grant/', ""));
            let id = bytes.toString(CryptoJS.enc.Utf8)

            if(id.length < 1) return setGrantFound()
            
            let url = country === 'US' ? '/api/usa-grants/' + id + '/' : '/api/grants/' + id + '/'
            instance.get(url).then(result => {
                if (result) 
                    instance.get('/user/').then(res => {
                        // if user is not signed in, show them the grant and the popup
                        if ('isAuthenticated' in res.data && !res.data.isAuthenticated) {
                            actions.setIndividualGrant({ grant: result.data, loggedIn: false })
                            setGrant(old => {
                                let res = { ...old }
                                res.loading = false
                                return res
                            })
                        }
                        // if user is signed in, show them the grant but not the popup
                        else {
                            actions.setIndividualGrant({ grant: result.data, loggedIn: true })
                            setGrant(old => {
                                let res = { ...old }
                                res.loading = false
                                return res
                            })
                        }
                    })
            })
        } catch {
            setGrantFound()
        }

    }, [])

    const sendTo = (route) => {
        props.history.push(route)
    }

    const showSignInPopUp = () => {
        actions.setCurrentUser({ showSignInPopUp: !state.currentUser.showSignInPopUp })
    }

    const setGrantFound = () => {
        setGrant({ ...grant, grantFound: !grant.grantFound })
    }
    // console.log(grant.loading && !grant.signedIn, grant.loading, grant.signedIn)
    return (
        <Layout>
            {state.currentUser.showSignInPopUp ? <PopupModal type="signInForm" backgroundClicked={() => showSignInPopUp()} /> : ''}
            {!grant.grantFound ? <PopupModal
                type="custom"
                backgroundClicked={() => {}}
                image={toolsIcon}
                title={'Whoops'}
                message={
                    <>
                        Whoops! It looks like this link is no longer active! No worries - visit your <a href="/home">Pocketed account</a> to search all grant programs
                    </>
                } /> : ''
            }
            <div className={classes.container} ref={screenRef}>
                <div className={classes.filter}>
                    <FilterBar pageName="Available Grants" singleGrantView />
                </div>
                {/* Full Screen */}
                <div className={classes.body + ' d-none d-lg-flex'}>
                    <div className={classes.grantList}>
                        <><img className={classesAlt.img} src={fakeList} />
                            <div className={classesAlt.overlay}>
                                {(!grant.loading && !state.currentUser.loggedIn) ? <>
                                    <div className={classesAlt.popup}>
                                        <h4>Want access to all of Pocketed's features?</h4>
                                        <div className={classesAlt.popupBtnContainer}>
                                            <CustomButton css="light-blue" title="Sign In" onClick={() => showSignInPopUp()} />
                                            <p>or</p>
                                            <CustomButton css="dark-blue" title="Create an Account" onClick={() => sendTo('/auth/create-beta-account')} />
                                        </div>
                                    </div></> : <></>}
                            </div>
                        </>
                    </div>
                    <div className={classes.grantDetails}>
                        {grant.loading ? <Loading /> :
                            <GrantDetails singleGrantView />}
                    </div>
                </div>
                {/* Mobile + Tablet */}
                <div className={classes.body + ' d-flex d-lg-none'}>
                    <div className={classes.grantDetailsTablet} >
                        {grant.loading ? <Loading /> :
                            <GrantDetails singleGrantView />}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default withRouter(SingleGrantView)