import React, { useState } from 'react'
import CustomButton from '../../../Components/UI/CustomButton/CustomButton'
import CustomInput from '../../../Components/UI/CustomInputs/CustomInput'
import classes from './ContactUs.module.css'
import logo from '../../../Assets/Images/logo.png'
import ReCAPTCHA from "react-google-recaptcha";
import instance from '../../../Assets/Axios/AxiosInstance'

const ContactUs = () => {
    const [captcha, setCaptcha] = useState(true)
    const [contactState, setContactState] = useState({
        form: {
            name: {
                type: 'text',
                label: 'Your Name',
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                edit: true,
                value: ''
            },
            email: {
                type: 'text',
                label: 'Email',
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                edit: true,
                value: ''
            },
            recieveEmail: {
                type: 'checkbox',
                label: '*I agree to receive emails from Pocketed. (We promise to only send emails that relate to you and your company. You can unsubscribe at any time.)',
                elementConfig: {
                    required: true,
                    className: classes.checkbox
                },
                edit: true,
                value: ''
            },
            companyName: {
                type: 'text',
                label: 'Company Name',
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                edit: true,
                value: ''
            },
            message: {
                type: 'textarea',
                label: 'Message',
                elementConfig: {
                    placeholder: '',
                    rows: 4,
                    required: true
                },
                edit: true,
                value: ''
            },

        },
        captcha: false,
        loading: true,
        editForm: false
    })

    const formChange = (e, i) => {
        e.persist()
        setContactState(old => {
            let temp = { ...old }
            temp.form[i].value = e.target.value
            return temp
        })
    }

    const formSubmit = (e) => {
        e.preventDefault()
        let formdata = new FormData()
        formdata.append('company_name', contactState.form.companyName.value)
        formdata.append('contact_name', contactState.form.name.value)
        formdata.append('message', contactState.form.message.value)
        formdata.append('contact_email', contactState.form.email.value)

        instance.post('/contact/', formdata).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    const captchaCompleted = (e) => {
        if (e == null) {
            document.getElementById('contact-submit-btn').disabled = true
        }
        else {
            document.getElementById('contact-submit-btn').disabled = false
        }
    }

    return (
        <form className={classes.form} onSubmit={(e) => formSubmit(e)}>
            <a href='/'><img className={classes.img} src={logo} /></a>
            <div className={classes.header}>
                <h2>Powerful funding right in your back pocket.</h2>
            </div>
            <div className={classes.content}>
                {
                    Object.keys(contactState.form).map((i, k) => {
                        let theForm = { ...contactState.form }
                        let theType
                        if (theForm[i].edit) {
                            theType = theForm[i].type + '-edit'
                        } else {
                            theType = theForm[i].type + '-display'
                        }
                        return (
                            <div key={k} className={classes.inputElements}>
                                <CustomInput
                                    id={i}
                                    type={theType}
                                    label={theForm[i].label}
                                    inputConfig={theForm[i].elementConfig}
                                    value={theForm[i].value}
                                    change={(e) => formChange(e, i)}
                                />
                            </div>
                        )
                    })
                }
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
                    onChange={(e) => captchaCompleted(e)}
                />
            </div>
            <div className={classes.formButtons}>
                <CustomButton css="light-blue" title="Send" type="submit" disabled={captcha} id="contact-submit-btn" />
            </div>
        </form>

    )
}

export default ContactUs