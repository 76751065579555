import React, { useContext } from 'react'
import classes from './SignInSignUpPopUp.module.css'
import envelop from '../../../../Assets/Images/icon-woman.svg'
import { StoreContext } from '../../../../Store/StoreContext'

const SignInSignUpPopUp = (props) => {
    const {actions} = useContext(StoreContext)

    const signInCicked = () => {
        actions.setCurrentUser({showSignInPopUp: true})
        props.closePopUp()
    }

    return (
        <div className={classes.container} onClick={(e) => e.stopPropagation()}>
            <div className={classes.image}>
                <div className={classes.icon}>
                    <img src={envelop} />
                </div>
            </div>
            <div className={classes.message}>
                <h2>You need an Account to gain access to all of Pocketed's Features!</h2>
                <div className={classes.linkContainer}>
                    <a className={'light-blue'} onClick={() => signInCicked()}>Sign In</a>
                    <p>or</p>
                    <a className={'dark-purple'} href="/auth/create-account">Create an Account</a>
                </div>
            </div>
        </div>
    )
}

export default SignInSignUpPopUp