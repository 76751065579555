import classes from './GrantBasedFinancing.module.css'
import React from 'react'
import WufooForm from '../../../../Components/UI/WufooForm/WufooForm'
import Layout from '../../../../HOC/Layout/Layout'

const GrantBasedFinancing = () => {
    return (
        <Layout>
            <div className={classes.container}>
            <WufooForm type="applyFinancing" />
            </div>
        </Layout>
    )
}

export default GrantBasedFinancing