import React, { useContext, useState } from 'react';
import instance from '../../../Assets/Axios/AxiosInstance';
import { StoreContext } from '../../../Store/StoreContext';
import classes from './PasswordReset.module.css';
import logo from '../../../Assets/Images/logo.png';
import CustomButton from '../../../Components/UI/CustomButton/CustomButton';
import CustomInput from '../../../Components/UI/CustomInputs/CustomInput';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { Mixpanel } from '../../../Assets/Functions/MixPanel/MixPanel';

const PasswordReset = () => {
    const { actions } = useContext(StoreContext);
    const [profileState, setProfileState] = useState({
        form: {
            email: {
                type: 'email',
                label: 'Email',
                elementConfig: {
                    placeholder: '',
                    required: true,
                },
                edit: true,
                value: '',
            },
        },
        loading: true,
        editForm: false,
        formComplete: false,
    });

    const formChange = (e, i) => {
        if (e instanceof Date) {
            setProfileState(old => {
                let temp = { ...old };
                temp.form[i].elementConfig.selected = e;
                return temp;
            });
        } else {
            e.persist();
            setProfileState(old => {
                let temp = { ...old };
                temp.form[i].value = e.target.value;
                return temp;
            });
        }
    };

    const formSubmit = e => {
        e.preventDefault();
        let email = document.getElementById('email').value;

        let formdata = new FormData();
        formdata.append('email', email);

        instance
            .post('/accounts/password_reset/', formdata)
            .then(res => {
                Mixpanel.track('Password Reset Success', res.response);
                console.log(res.response);
                setProfileState(old => {
                    let res = { ...old, formComplete: true };
                    return res;
                });
            })
            .catch(err => {
                Mixpanel.track('Password Reset Failed', err.response);
                // console.log(err.response.status, err.response.data.error)
                if (err.response.status == 422) {
                    actions.setPopup({ flag: true, message: 'error' });
                } else {
                    setProfileState(old => {
                        let temp = { ...old };
                        temp.form['email'].hint = <p className={classes.error}>Error: your email is not registered</p>;
                        return temp;
                    });
                }
            });
    };

    return (
        <form className={classes.form} onSubmit={e => formSubmit(e)}>
            <a href="/">
                <img className={classes.img} src={logo} />
            </a>
            <div className={classes.header}>
                <h2>Password Reset</h2>
            </div>
            {profileState.formComplete ? (
                <div className={classes.formCompleteMsg}>
                    <p>Please check your email to reset your password!</p>
                </div>
            ) : (
                <>
                    <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
                    <div className={classes.formButtons}>
                        <CustomButton css="light-blue" title="Reset Password" type="submit" id="reset-password-submit-btn" />
                    </div>
                </>
            )}
        </form>
    );
};

export default PasswordReset;
