import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomButton from '../../../Components/UI/CustomButton/CustomButton';
import classes from './LoginForm.module.css';
import axios from '../../../Assets/Axios/AxiosInstance';
import qs from 'qs';
import LocalStorageService from '../../../Assets/LocalStorage/LocalStorageService';
import { withRouter } from 'react-router-dom';
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import Cookies from 'js-cookie';
import CustomInput from '../../../Components/UI/CustomInputs/CustomInput';
import logo from '../../../Assets/Images/Clearco/CLEARCO_BLACK-WORDMARK.png';
import pocketedlogo from '../../../Assets/Images/logo.png';
import Loading from '../../../Components/UI/Loading/Loading';
import { StoreContext } from '../../../Store/StoreContext';
import { Mixpanel } from '../../../Assets/Functions/MixPanel/MixPanel';

const LoginForm = props => {
    const { actions } = useContext(StoreContext);

    const [profileState, setProfileState] = useState({
        form: {
            email: {
                type: 'text',
                label: 'Email',
                elementConfig: {
                    placeholder: '',
                },
                edit: true,
                value: '',
            },
            password: {
                type: 'password',
                label: 'Password',
                elementConfig: {
                    placeholder: '',
                },
                edit: true,
                value: '',
            },
        },
        loading: false,
        editForm: false,
        signinFailed: {
            flag: false,
            message: '',
        },
    });

    // const registerClick = (e) => {
    //     e.preventDefault()
    //     props.history.push('/auth/register')
    // }

    const loginClick = e => {
        e.preventDefault();
        setProfileState(old => {
            let res = { ...old };
            res.loading = true;
            return res;
        });
        let email = document.getElementById('email');
        let password = document.getElementById('password');
        let data = {
            csrfmiddlewaretoken: Cookies.get('csrftoken'),
            username: email.value,
            password: password.value,
        };

        axios
            .post('/accounts/login/', qs.stringify(data))
            .then(res => {
                console.log('Successful login.', res.data);
                Mixpanel.track('Login Success', res.data);
                if (props.noRedirect) {
                    props.callback();
                } else {
                    if (res.data.showCampaign) actions.setPopup({ referralPopup: true });
                    props.history.push('/home');
                }
            })
            .catch(async err => {
                console.log(err.response);
                Mixpanel.track('Login Failed', err.response);
                if (err.response.status == 500) {
                    setProfileState(old => {
                        let res = {
                            ...old,
                            signinFailed: {
                                flag: true,
                                message: <>Whoops something went wrong, please try signing in again!</>,
                            },
                            loading: false,
                        };
                        return res;
                    });
                } else if ('grantprofile' in err.response.data && !err.response.data.grantprofile) {
                    props.history.push('/auth/create-grantprofile/');
                } else if ('activated' in err.response.data && !err.response.data.activated) {
                    setProfileState(old => {
                        let res = {
                            ...old,
                            signinFailed: {
                                flag: true,
                                message: (
                                    <>
                                        Your account has not been activated, click{' '}
                                        <a className={classes.redirect} onClick={() => props.history.push('/auth/resend-activation/')}>
                                            here
                                        </a>{' '}
                                        to resend your activation email.
                                    </>
                                ),
                            },
                            loading: false,
                        };
                        return res;
                    });
                } else if ('beta' in err.response.data && err.response.data.beta) {
                    props.history.push('/checkback-soon');
                } else if ('waitlist' in err.response.data && err.response.data.waitlist) {
                    props.history.push('/checkback-soon');
                } else {
                    setProfileState(old => {
                        let res = {
                            ...old,
                            signinFailed: {
                                flag: true,
                                message: 'Sign in failed, your email or password is incorrect',
                            },
                            loading: false,
                        };
                        return res;
                    });
                }
                console.log('Unsuccessful login.', err.response);
            });
    };

    return (
        <>
            {profileState.loading ? (
                <div className={classes.loading}>
                    <Loading />
                    <p>Please Wait...</p>
                </div>
            ) : (
                <Form className={classes.container}>
                    <div className={classes.logoContainer}>
                        <a href="/">
                            <img className={classes.img} src={pocketedlogo} />
                        </a>
                        <p>&</p>
                        <a href="/">
                            <img className={classes.img} src={logo} />
                        </a>
                    </div>
                    <h2>Sign In</h2>
                    <input type="hidden" name="csrfmiddlewaretoken" value={document.cookie} />
                    <div className={classes.content}>
                        {Object.keys(profileState.form).map((i, k) => {
                            let theForm = { ...profileState.form };
                            let theType;
                            if (theForm[i].edit) {
                                theType = theForm[i].type + '-edit';
                            } else {
                                theType = theForm[i].type + '-display';
                            }
                            return (
                                <div key={k} className={classes.inputElements}>
                                    <CustomInput id={i} type={theType} label={theForm[i].label} inputConfig={theForm[i].elementConfig} />
                                </div>
                            );
                        })}
                        <a href="/auth/reset-password" className={classes.passwordResetLink}>
                            I forgot my password
                        </a>
                        {profileState.signinFailed.flag ? <p className={classes.failedSignin}>{profileState.signinFailed.message}</p> : ''}
                    </div>
                    <div className={classes.formButtons}>
                        <CustomButton title="Sign In" css="dark-blue" onClick={e => loginClick(e)} type="submit"></CustomButton>
                        {/* <p>
                            Don’t have an account? <a href="/auth/create-account">Sign up!</a>
                        </p> */}
                        {/* <CustomButton title="register" css="white" onClick={(e) => registerClick(e)}></CustomButton> */}
                    </div>
                    {/* <div className={classes.socialLogins}>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        onSuccess={(res) => console.log(res)}
                        onFailure={(err) => console.log(err)}
                        cookiePolicy={'single_host_origin'}
                        className={classes.googleButton}
                    />
                    <FacebookLogin
                        appId="751330382405727"
                        fields="name,email,picture"
                        icon="fa-facebook"
                        textButton="Sign in with Facebook"
                        callback={(r) => console.log(r)}
                        cssClass={classes.facebookButton}
                    />

                </div> */}
                </Form>
            )}
        </>
    );
};

export default withRouter(LoginForm);
