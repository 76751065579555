import React, { useEffect, useState } from 'react'
import classes from './GrantHelpPopUp.module.css'
import glasses from './eyeglasses.svg'
import tool from './gear-wide-connected.svg'
import book from './journals.svg'
import pen from './pen.svg'
import CustomButton from '../../CustomButton/CustomButton'

const GrantHelpPopUp = (props) => {
    const [grantExpertClicked, setGrantExpertClicked] = useState(false)

    return (
        <div className={classes.container} onClick={(e) => e.stopPropagation()}>
            <div className={classes.title}>
                {grantExpertClicked ?
                    <div className={classes.backBtn} onClick={() => setGrantExpertClicked(false)}>
                        <CustomButton
                            title={<svg width="1em" height="1em" viewBox="0 0 16 16" className={"bi bi-arrow-left " + classes.svg} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /></svg>}
                            css="clear" />
                    </div>
                    : ''
                }
                <h2>Get Help Securing Your Grant Funding</h2>
            </div>
            {!grantExpertClicked ?
                <div className={classes.grid}>
                    <HelpBtn title={'Access Insights & FAQ'} message={"Visit Pocketed's Knowledge Centre"} icon={glasses} handleClick={() => { }} />
                    <HelpBtn title={'Hire a Grant Writer'} message={'Increase your application success!'} icon={pen} handleClick={() => { }} />
                    <HelpBtn title={'Application Automation Tool'} message={'Save time & money'} icon={tool} handleClick={() => { }} soon />
                    <HelpBtn title={'Book a Consultation'} message={'Talk to an expert'} icon={book} handleClick={() => { }} soon />
                </div>
                : ''
            }

            {/* { grantExpertClicked ? <GrantExpertPage /> : ''} */}

        </div>
    )
}

const HelpBtn = ({ title, icon, handleClick, soon, message }) => {
    return (
        <div className={classes.item} >
            <img className={classes.icon} src={icon} onClick={() => handleClick()} />
            <div>
                <h3>{title}</h3>
                <p>{message}</p>
            </div>
            {soon ? <div className={classes.comingSoonBanner}>
                <p>
                    COMING SOON!
                </p>
            </div> : ''}
        </div>
    )
}

// const GrantExpertPage = (props) => {
//     return (
//         <div className={classes.subcontainer}>
//             <div>
//                 <img className={classes.icon} />
//             </div>
//             <div className={classes.description}>
//                 <h3>Talk to a Grant Expert</h3>
//                 <p>
//                     Bacon ipsum dolor amet burgdoggen pork prosciutto leberkas doner, rump hamburger sausage sirloin pancetta ribeye fatback bresaola pastrami spare ribs. Kielbasa jerky leberkas, tri-tip cupim hamburger turkey pastrami t-bone capicola tail.
//                 </p>
//                 <p>
//                     Cow boudin turducken sausage jowl t-bone ground round.
//                 </p>
//                 <a href="#" className={classes.btn + ' ' + classes.applyBtn} id='bookExpert'>Book Now</a>
//             </div>
//         </div>
//     )
// }


export default GrantHelpPopUp