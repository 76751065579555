import React from 'react'
import classes from './About.module.css'
import storyImage from '../../Assets/Images/Headshots/9.png'
import van from '../../Assets/Images/Headshots/1.png'
import bri from '../../Assets/Images/Headshots/7.png'
import iri from '../../Assets/Images/Headshots/6.png'
import les from '../../Assets/Images/Headshots/8.png'
import aria from '../../Assets/Images/Headshots/2.png'
import tom from '../../Assets/Images/Headshots/4.png'
import sam from '../../Assets/Images/Headshots/3.png'
import ben from '../../Assets/Images/Headshots/5.png'
import blobFunding from '../../Assets/Images/blob-power.png'
import wavelines1 from '../../Assets/Images/wave-lines-1.png'
import wavelines2 from '../../Assets/Images/wave-lines-2.png'
import Footer from '../../Components/Footer/Footer'
import AltNav from '../../Components/Navigation/AlternateNavBar/AltNav'
import AlternateLayout from '../../HOC/Layout/AlternateLayout/AlternateLayout'

const About = () => {
    const profileCards = [
        {
            name: 'Vanessa Bayubaskoro',
            title: 'Data Engineer',
            funded: 'Venture for Canada',
            img: van
        },
        {
            name: 'Brianna Blaney',
            title: 'Co-Founder & CEO',
            funded: 'IAP',
            img: bri
        },
        {
            name: 'Irina Drapic',
            title: 'Data Engineer',
            funded: 'Career Launcher',
            img: iri
        },
        {
            name: 'Lesley George',
            title: 'Marketing Coordinator',
            funded: 'DS4Y',
            img: les
        },
        {
            name: 'Aria Hahn',
            title: 'Co-Founder & CTO',
            funded: 'IAP',
            img: aria
        },
        {
            name: 'Tom Laventure',
            title: 'Front End Developer',
            funded: 'DS4Y',
            img: tom
        },
        {
            name: 'Samuel Selvarajah',
            title: 'Full Stack Developer',
            funded: 'DS4Y',
            img: sam
        },
        {
            name: 'Ben Walter',
            title: 'Marketing Specialist',
            funded: 'Venture for Canada',
            img: ben
        },
    ]

    const numberFacts = [
        {
            title: '200+',
            msg: 'available grants on Pocketed'
        },
        {
            title: '$2.9B',
            msg: 'available in Canadian grant funding'
        },
        {
            title: 'FREE',
            msg: "access to Pocketed's Intelligent Grant Matching"
        },
        {
            title: '$1.5M',
            msg: 'awarded to start-ups through Pocketed'
        },
    ]

    return (
        <AlternateLayout>
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <h1>About Us</h1>
                    </div>
                    <div className={classes.story}>
                        <div className={classes.storyContent}>
                            <div className={classes.storyMsg}>
                                <h2>Our Story</h2>
                                <p>
                                    In 2020, a lot of things happened. We saw companies around us wind down their operations or close entirely. However, we were witnessing our own companies thrive.<br /><br />

                                    <b>Our secret weapon? Grant funding.</b> We finally figured out how to effectively leverage grant funding, securing almost half a million dollars in non-dilutive funding.<br /><br />

                                We built Pocketed to make grant funding more accessible to every business owner. Impactful funding, right in your back pocket<br /><br />

                                - Brianna Blaney & Aria Hahn,<br />
                                Pocketed Co-Founders
                            </p>
                            </div>
                        </div>
                        <div className={classes.storyImg}>
                            <img src={storyImage} />
                        </div>
                    </div>
                    <div className={classes.profiles}>
                        <img className={classes.profilesBackground} src={wavelines1} />
                        <div className={classes.profilesContainer}>
                            <div className={classes.profileHeader}>
                                <h2>Our (Grant Funded) Team</h2>
                            </div>
                            <div className={classes.profileCards}>
                                {profileCards.map((i, k) => {
                                    return (
                                        <div className={classes.card}>
                                            <div className={classes.cardImgContainer}>
                                                <img src={i.img} />
                                            </div>
                                            <div className={classes.cardContent}>
                                                <p className={classes.cardName}>{i.name}</p>
                                                <p className={classes.cardTitle}>{i.title}</p>
                                                <p className={classes.cardFunded}>FUNDED BY<br />{i.funded}</p>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                    <div className={classes.numbers}>
                        <img className={classes.numbersBackground} src={wavelines2} />
                        <div className={classes.numbersOuterContainer}>
                            <div className={classes.numbersContainer}>
                                <h2>pocketed by the numbers</h2>
                                <div className={classes.facts}>
                                    {numberFacts.map((i, k) => {
                                        return (
                                            <div className={classes.factCards} key={k}>
                                                <h3>{i.title}</h3>
                                                <p>{i.msg}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.stats}>
                        <div className={classes.statsContent}>
                            <div className={classes.statsImg}>
                                <img src={blobFunding} />
                            </div>
                            <div className={classes.statsMsg}>
                                <h3>We've built Pocketed to bring grant funding to you quickly and easily</h3>
                                <ul>
                                    <li>Our <b className={classes.bold}>user-focused platform</b> is built to make finding your grant a smooth, intuitive journey. See ya later government websites.</li>
                                    <li>By using our <b className={classes.bold}>Intelligent Grant Matching tool,</b> you'll be able to uncover grants that can be easily overlooked. Avoid funding FOMO forever.</li>
                                    <li>Pocketed offers fast, founder-friendly <b className={classes.bold}>grant-based financing</b> integrated directly into Pocketed's platform. It's your funding needs, all under one roof. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </AlternateLayout>
    )
}

export default About