import React, { useEffect, useState } from 'react'
import classes from './SharePopUp.module.css'
import envelop from '../../../../Assets/Images/envelope.png'
import pencil from '../../../../Assets/Images/pencil-fill.svg'

const SharePopUp = (props) => {
    const [success, setSuccess] = useState(false)
    const copyLink = () => {
        navigator.clipboard.writeText(props.grantLink)
        setSuccess(true)
    }

    useEffect(() => {
        let timer
        if (success) {
            timer = setTimeout(() => {
                setSuccess(false)
            }, 2000)
        }
        return () => {
            if (success) {
                setSuccess(false)
                clearTimeout(timer)
            }
        }
    }, [success])


    return (
        <div className={classes.container} onClick={(e) => e.stopPropagation()}>
            <div className={classes.image}>
                <div className={classes.icon}>
                    <img src={envelop} />
                </div>
            </div>
            <div className={classes.message}>
                <h2>Share this Grant with a Friend</h2>
                <div className={classes.linkContainer}>
                    <p>Click to Copy</p>
                    <div className={classes.link} onClick={() => copyLink()}>
                        <p>
                            {props.grantLink}
                        </p>
                        <img src={pencil} />
                    </div>
                    {success ? <p className={classes.success}>Link copied to clipboard!</p> : ''}
                </div>
            </div>
        </div>
    )
}

export default SharePopUp