import React from 'react'
import classes from './FirstLoadGrantView.module.css'
import graphic from '../../../../Assets/Images/graphic-server.png'
import iconMoney from '../../../../Assets/Images/icon-money.png'
import iconSpeachBubble from '../../../../Assets/Images/icon-speachBubbles.png'
import iconWave from '../../../../Assets/Images/icon-wave.png'

const FirstLoadGrantView = () => {
    const threeIcons = [
        {
            img: iconWave,
            txt: 'Get Help',
            href: 'https://pocketed.zendesk.com/hc/en-us/requests/new',
            id: 'get_help'
        },
        {
            img: iconMoney,
            txt: 'Learn more about Grant-Based Financing',
            href: '/grant-based-financing',
            id:'learn_about_financing'
        },
        {
            img: iconSpeachBubble,
            txt: 'FAQ',
            href: 'https://pocketed.zendesk.com/hc/en-us',
            id: 'faq'
        },
    ]

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div className={classes.header}>
                    <h2>Welcome to your grant explorer!</h2>
                    <p>Explore your matched grants on the left by using filters above</p>
                </div>
                <div className={classes.threeIconRow}>
                    {threeIcons.map((i, k) => {
                        return (
                            <a className={classes.iconContainer} key={k} href={i.href} id={i.id}>
                                <img src={i.img} />
                                <p>{i.txt}</p>
                            </a>
                        )
                    })}
                </div>
                <div className={classes.mainIcon}>
                    <img src={graphic} />
                </div>
            </div>
        </div>
    )
}

export default FirstLoadGrantView