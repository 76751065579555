import React from 'react'
import Bar from './Bar/Bar';
import classes from './DifficultyBars.module.css'


const DifficultyBars = ({difficulty}) => {
    const messageOptions = {
        1 : "Quick Application Process",
        2 : "Moderate Application Process",
        3 : "Long Application Process"
    }

    const dbtip = {
        1: 'These grants generally take under an hour',
        2: 'These grants have multiple steps and generally take between 1-5 hours. This may include calls, surveys or longer descriptions of the project or position',
        3: 'These grants generally take a full day or more but are worth 10-100X then the smaller, shorter ones'
    }

    const numberOfBars = 3;
    let bars = [];
    for (let x = 1; x <= numberOfBars; x++) {
        bars.push(<Bar key={x} height={(x / numberOfBars) * 100 + "%"} solid={difficulty >= x} />)
    }

    return (
        <div className={classes.difficultyBars}>
            <div className={classes.diffBars}>
                {bars}
            </div>
            <h3>{messageOptions[difficulty]}</h3>
            <div className={classes.dbtip}>
                {dbtip[difficulty]}
            </div>
        </div>
    )
}

export default DifficultyBars