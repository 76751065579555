import React, { useEffect, useState } from 'react'
import CustomButton from '../../../Components/UI/CustomButton/CustomButton'
import classes from '../UserProfile/UserProfile.module.css'
import instance from '../../../Assets/Axios/AxiosInstance'
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper'
import { onlyEssentialGrantFormElements } from '../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileCreationFormElements'
import { formatDate } from '../../../Assets/Functions/DateFormater'
import Loading from '../../../Components/UI/Loading/Loading'
import StopInteractionBackground from '../../../HOC/StopInteractionBackground/StopInteractionBackground'
import { Mixpanel } from '../../../Assets/Functions/MixPanel/MixPanel'
import { formChangeFunction } from '../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileFormFunctions'

const GrantProfile = () => {
    const [profileState, setProfileState] = useState({ ...onlyEssentialGrantFormElements(), loading: true, editingGrantProfile: false, message: 'please wait...', profileCreation: false })
    
    useEffect(() => {
        instance.get('/api/grantprofiles/').then(res => {
            console.log(res)
            if (res.data.results.length !== 0) {
                let data = { ...res.data.results[0] }
                setProfileState(old => {
                    let oldstate = { ...old }
                    Object.keys(oldstate.form).forEach(i => {
                        oldstate.form[i].edit = false
                    })
                    // console.log(data.industry)
                    oldstate.form.founderType.value = data.founder_details.map(i => {
                        return i.type
                    })
                    // oldstate.form.country.value = { label: data.country.name, value: data.country.abbreviation }
                    oldstate.form.country.value = data.country.abbreviation
                    if (data.country.abbreviation == "CA") {
                        oldstate.form.region_ca.value = data.region.map((i) => {
                            return i.name
                        })
                    }
                    else{
                        oldstate.form.region_us.value = data.region.map((i) => {
                            return i.name
                        })
                        oldstate.form.region_us.show = true
                        oldstate.form.region_ca.show = false
                    }
                    oldstate.form.businessType.value = data.business_type.type
                    oldstate.form.businessName.value = data.company_name
                    oldstate.form.website.value = data.company_site
                    oldstate.form.companySize.value = data.company_size
                    oldstate.form.annualRevenue.value = data.annual_revenue
                    oldstate.form.registrationDate.value = deformatDate(data.registration_date)
                    if (data.industry.length == 0) {
                        oldstate.form.industry.value = oldstate.form.industry.options
                    }
                    else {
                        oldstate.form.industry.value = data.industry.map((i) => {
                            return i.name
                        })
                    }
                    oldstate.form.description.value = data.description
                    oldstate.form.companyStatus.value = data.incorporated
                    oldstate.form.annualRevenue.value = data.annual_revenue
                    oldstate.grantProfileID = data.id
                    oldstate.form.phone.value = data.phone_number
                    let backupform = deconstructObject(oldstate.form)
                    oldstate.backupForm = { ...backupform }
                    oldstate.loading = false
                    return oldstate
                })
            }
            else {
                instance.get('/dropdowns/').then(r => {
                    // console.log(r.data)
                    setProfileState(old => {
                        let res = { ...old }
                        res.form.industry.options = r.data.industries.map(i => {
                            return { label: i.name, value: i.name }
                        }).sort((a, b) => {
                            if (a.value > b.value) {
                                return 1
                            }
                            else {
                                return -1
                            }
                        })
                        res.form.region.options = r.data.regions.map(i => {
                            return { label: i.name, value: i.name }
                        }).sort((a, b) => {
                            if (a.value > b.value) {
                                return 1
                            }
                            else {
                                return -1
                            }
                        })
                        res.form.founderType.options = r.data.founder_types.map(i => {
                            return { label: i.type, value: i.type }
                        }).sort((a, b) => {
                            if (a.value > b.value) {
                                return 1
                            }
                            else {
                                return -1
                            }
                        })
                        res.form.annualRevenue.options = [...r.data.annual_revenue.options]
                        Object.keys(res.form).forEach(i => {
                            res.form[i].edit = true
                        })
                        res.editForm = true
                        res.loading = false
                        res.profileCreation = true
                        return res
                    })
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const removeItemFromList = (item, stateIndex) => {
        console.log(item, stateIndex)
        setProfileState(old => {
            let temp = { ...old }
            let index = temp.form[stateIndex].value.indexOf(item)
            temp.form[stateIndex].value.splice(index, 1)
            return temp
        })
    }

    const deconstructObject = (obj) => {
        let newObj = new Object
        Object.keys(obj).map((i) => {
            newObj[i] = { ...obj[i] }
        })
        return newObj
    }

    const deformatDate = (date) => {
        const [year, month, day] = date.split('-')
        const d = new Date(year, month, day)
        return d
    }

    const formChange = formChangeFunction(setProfileState)
    const editForm = () => {
        setProfileState(old => {
            let temp = { ...old }
            temp.loading = true
            return temp
        })
        instance.get('/dropdowns/').then(r => {
            console.log(r.data)
            setProfileState(old => {
                let res = { ...old }
                res.form.industry.options = r.data.industries.map(i => {
                    return { label: i.name, value: i.name }
                }).sort((a, b) => {
                    if (a.value > b.value) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                res.form.country.options = r.data.countries.map(i => {
                    return {label: i.name, value: i.abbreviation}
                })
                res.form.region_ca.options = r.data.regions_ca.map(i => {
                    return { label: i.name, value: i.name }
                }).sort((a, b) => {
                    if (a.value > b.value) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                res.form.region_us.options = r.data.regions_us.map(i => {
                    return { label: i.name, value: i.name }
                }).sort((a, b) => {
                    if (a.value > b.value) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                res.form.founderType.options = r.data.founder_types.map(i => {
                    return { label: i.type, value: i.type }
                }).sort((a, b) => {
                    if (a.value > b.value) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                res.form.annualRevenue.options = [...r.data.annual_revenue.options]
                Object.keys(res.form).forEach(i => {
                    res.form[i].edit = true
                })
                res.editForm = true
                res.loading = false
                return res
            })
        })

        // setProfileState(old => {
        //     let temp = { ...old }
        //     Object.keys(temp.form).forEach(i => {
        //         temp.form[i].edit = true
        //     })
        //     temp.editForm = true
        //     return temp
        // })
    }

    const formSavedSuccessfully = () => {
        setProfileState(old => {
            let temp = { ...old }
            Object.keys(temp.form).forEach(i => {
                temp.form[i].edit = false
            })
            temp.loading = false
            temp.editForm = false
            temp.editingGrantProfile = false
            temp.message = 'please wait...'
            return temp
        })
    }

    const cancelForm = () => {
        setProfileState(old => {
            let temp = { ...old }
            Object.keys(temp.form).forEach(i => {
                temp.form[i].edit = false
            })
            temp.form = temp.backupForm
            temp.editForm = false
            return temp
        })
    }

    const saveForm = (e) => {
        e.preventDefault()
        setProfileState(old => {
            let res = { ...old }
            res.loading = true
            res.editingGrantProfile = true
            res.message = 'Your wish is granted. Sit tight, new grants are on their way!'
            return res
        })

        let temp = profileState.form
        let body = new Object()
        Object.keys(temp).forEach(i => {
            if (temp[i].value instanceof Date) {
                body[temp[i].entryValue] = formatDate(temp[i].value)
            } else if (temp[i].show !== undefined && !temp[i].show) {
                // If form element is hidden, do not add it to the request body
            }
            else if (temp[i].multiple) {
                let res = temp[i].value.map(i => {
                    if (typeof i == 'string') {
                        return i
                    } else {
                        return i.value
                    }
                })
                body[temp[i].entryValue] = res
            }
            else {
                body[temp[i].entryValue] = temp[i].value
            }
        })
        if (profileState.profileCreation) {
            body['awarded_grant_types'] = []
            body['bus_goals'] = []
            instance.post('/api/grantprofiles/', body).then(res => {
                console.log('success', res)
                formSavedSuccessfully()
            }).catch(err => {
                // temporary addition until backend is fixed
                formSavedSuccessfully()
                console.log(err.response)
            })
        } else {
            instance.patch('/api/grantprofiles/', body).then(res => {
                console.log('success', res)
                formSavedSuccessfully()
            }).catch(err => {
                // temporary addition until backend is fixed
                formSavedSuccessfully()
                console.log(err.response)
            })
        }
    }

    return (
        <form className={classes.form}>
            {profileState.editingGrantProfile ? <StopInteractionBackground /> : null}
            {profileState.loading ? <div className={classes.loading}><Loading /><p>{profileState.message}</p></div> : <>
                <div className={classes.header}>
                    <h2>Grant Profile</h2>
                </div>
                <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} removeItem={removeItemFromList} />
                <div className={classes.formButtons}>
                    {profileState.editForm ?
                        <><CustomButton title="Save" css="dark-purple" onClick={(e) => saveForm(e)} /> <CustomButton title='Cancel' css='white' onClick={() => cancelForm()} /></> :
                        <CustomButton title="Edit" css="dark-purple" onClick={() => editForm()} />
                    }
                </div>
            </>}
        </form>
    )
}

export default GrantProfile