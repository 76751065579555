import React, { useContext, useState } from 'react'
import { StoreContext } from '../../../Store/StoreContext'
import classes from './ResendActivationLink.module.css'
import logo from '../../../Assets/Images/logo.png'
import instance from '../../../Assets/Axios/AxiosInstance'
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper'
import CustomButton from '../../../Components/UI/CustomButton/CustomButton'

const ResendActivationLink = () => {
    const { actions } = useContext(StoreContext)
    const [profileState, setProfileState] = useState({
        form: {
            email: {
                type: 'email',
                label: "Email",
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                id: 'email',
                edit: true,
                value: '',
                hint: ''
            },
        },
        loading: true,
        editForm: false,
        formComplete: false
    })


    const formChange = (e, i) => {
        if (e instanceof Date) {
            setProfileState(old => {
                let temp = { ...old }
                temp.form[i].elementConfig.selected = e
                return temp
            })
        }
        else {
            e.persist()
            setProfileState(old => {
                let temp = { ...old }
                temp.form[i].value = e.target.value
                return temp
            })
        }
    }

    const formSubmit = (e) => {
        e.preventDefault()
        let formdata = new FormData()
        let email = document.getElementById('email').value;
        formdata.append('email', email)

        instance.post('/re-activate/', formdata).then(res => {
            setProfileState(old => {
                let res = { ...old, formComplete: true }
                return res
            })
        }).catch(err => {

            // console.log(err.response.status, err.response.data.error)
            if (err.response.status == 422) {
                actions.setPopup({ flag: true, message: 'error' })
            } else{
                setProfileState(old => {
                    let res = { ...old }
                    res.form.email.hint = (<p className={classes.error}>Error: User email not registered to an account.</p>)
                    return res
                })
            }
        })

    }


    return (
        <form className={classes.form} onSubmit={(e) => formSubmit(e)}>
            <a href='/'><img className={classes.img} src={logo} /></a>
            <div className={classes.header}>
                <h2>Re-send Email Activation Link</h2>
            </div>
            {profileState.formComplete ? <div className={classes.formCompleteMsg}>
                <div className={classes.success}>
                    <p>Activation link sent, please check your email.</p>
                </div>
            </div> :
                <>
                    <div className={classes.content}>
                        {
                            <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
                        }
                    </div>
                    <div className={classes.formButtons}>
                        <CustomButton css="light-blue" title="Send" type="submit" id="reset-password-submit-btn" />
                    </div>
                </>
            }
        </form>
    )
}

export default ResendActivationLink