import React, { useEffect, useRef, useContext } from 'react'
import { StoreContext } from '../../../../Store/StoreContext'
import classes from './GrantSavedPopup.module.css'

const GrantSavedPopup = () => {
    const {actions} = useContext(StoreContext)
    const fadeRef = useRef()

    useEffect(() => {
        let currentOpacity = 95;
        fadeRef.current.opacity = currentOpacity + '%'
        let interval
        setTimeout(() => {
            interval = setInterval(() => {
                if(currentOpacity > 0 && fadeRef.current !== null){
                    fadeRef.current.style.opacity = currentOpacity + '%'
                    currentOpacity--
                }
                else{
                    clearInterval(interval)
                    actions.setGrantSaved(false)
                }
            }, 10)
        }, 500)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className={classes.container + ' ' + classes.fadeOut}>
            <div className={classes.message} ref={fadeRef}>
                Grant Saved!
            </div>
        </div>
    )
}

export default GrantSavedPopup