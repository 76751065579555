import React from 'react'
import CustomDropDown from '../../../UI/CustomButton/CustomDropDown/CustomDropDown'
import DropDownItem from '../../../UI/CustomButton/CustomDropDown/DropDownItem/DropDownItem'
import classes from './SetFilterOptions.module.css'

const SetFilterOptions = ({ title, param, options, inputConfig, checkbox, itemSelected, multiple }) => {
    let message;
    if (checkbox && multiple) {
        message = <p className={classes.message}>Select all that Apply</p>
    }
    return (
        <CustomDropDown title={title} {...inputConfig} >
            {message}
            {/* {console.log(options)} */}
            {options.map((i, k) => {
                return (
                    <DropDownItem
                        key={k}
                        title={i.label}
                        value={i.value}
                        param={param}
                        checkbox={checkbox}
                        itemSelected={itemSelected}
                        checked={i.checked}
                        multiple={multiple}
                    />
                )
            })}
        </CustomDropDown>
    )
}

export default SetFilterOptions