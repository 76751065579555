import React, { useContext, useEffect, useState } from 'react'
import classes from './GrantList.module.css'
import GrantListItem from './GrantListItem/GrantListItem'
import SearchBar from '../../UI/SearchBar/SearchBar'
import { StoreContext } from '../../../Store/StoreContext'
import PaginationBar from '../../UI/PaginationBar/PaginationBar'
import formatPrice from '../../../Assets/Functions/PriceFormater'
import Loading from '../../UI/Loading/Loading'
// import PopupModal from '../../UI/PopUps/PopupModal'

const GrantList = (props) => {
    const { state, actions } = useContext(StoreContext)
    const [list, setList] = useState([])
    const [emptyListReturned, setEmptyListReturned] = useState(false)

    useEffect(() => {
        if (state.grantList !== undefined) {
            let temp = [...state.grantList]
            // console.log('here')
            let result = temp.map((i, k) => {
                // console.log(i.grant)
                if (i.grant !== undefined) {
                    // console.log(i.liked)
                    // let itemSelected = false
                    // Add selected class to selected item
                    // if (k == state.grantDetails.selected && state.grantDetails.picked) {
                    //     itemSelected = true
                    // }
                    let formattedAmount, grantName
                    if (i.grant.amount !== 0) {
                        formattedAmount = '$' + formatPrice(i.grant.amount)
                    }
                    else {
                        formattedAmount = 'Amount Varies'
                    }

                    if(i.grant.name.length > 75){
                        grantName = i.grant.name.slice(0, 75) + '...'
                    } else{
                        grantName = i.grant.name
                    }
                    return <GrantListItem
                        title={grantName}
                        subTitle={i.grant.issuer}
                        amount={formattedAmount}
                        type={i.grant.grant_type[0]?.type}
                        expiroryDate={i.grant.deadlines}
                        new={i.new}
                        saved={i.saved}
                        liked={i.liked}
                        id={i.id}
                        theKey={k}
                        key={k}
                    />

                }
            })
            setList(result)
            if(state.grantListLoading){
                actions.setGrantListLoading(false)
            }
        }
    }, [state.grantList, props.mobile])

    useEffect(() => {
        if (list.length !== 0 && !state.grantListLoading) {
            setEmptyListReturned(false)
        } else if (!state.grantListLoading) {
            setEmptyListReturned(true)
        }
    }, [list])

    // const itemClicked = (i) => {
    //     actions.setGrantPicked({ picked: true, details: state.grantList[i], selected: i })
    //     setFlag(true)
    // }

    let listBody;

    if (emptyListReturned && !state.grantListLoading) {
        listBody = <div className={classes.emptyList}>
            <p>Currently no grants match the filters you've selected. To pocket your grant funding try adjusting your filters to view more grants.</p>
            <p><b>P.S:</b> Adjusting your grant profile settings on your account page should help you discover more funding!</p>
        </div>
    } else if (state.grantListLoading) {
        listBody = <div className={classes.loading}><Loading /><p>Loading...</p></div>
    }
    else {
        listBody = list
    }
    return (
        <div className={`container ` + classes.container}>
            <SearchBar />
            {listBody}
            <PaginationBar />
        </div>
    )
}

export default GrantList