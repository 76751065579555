import React from 'react'
import classes from './Loading.module.css'
import { useLoading, Grid } from '@agney/react-loading'

const Loading = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Grid width="50" />,
        loaderProps: {
            style: { color: '#F9A464' }
        }
      });
// console.log(containerProps, indicatorEl)
return (
    <div className={classes.container} {...containerProps}>
        {indicatorEl}
    </div>
)
}

export default Loading