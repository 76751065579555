import React, { useContext } from 'react'
import { StoreContext } from '../../../Store/StoreContext'
import CustomPopUp from './CustomPopUp/CustomPopUp'
import ErrorPopUp from './ErrorPopUp/ErrorPopUp'
import classes from './PopupModal.module.css'
import SharePopUp from './SharePopUp/SharePopUp'
import SignInPopUp from './SignInPopUp/SignInPopUp'
import SignInSignUpPopUp from './SignInSignUpPopUp/SignInSignUpPopUp'
import ThumbsDownPopUp from './ThumbsDownPopUp/ThumbsDownPopUp'
import GrantHelpPopUp from './GrantHelpPopUp/GrantHelpPopUp'

const PopupModal = (props) => {
    const { state, actions } = useContext(StoreContext)
    let popup

    switch (props.type) {
        case 'error':
            popup = <ErrorPopUp message={state.popup.message} />
            break
        case 'custom':
            popup = <CustomPopUp message={props.message} title={props.title} image={props.image} />
            break
        case 'thumbsDown':
            popup = <ThumbsDownPopUp thumbsDownFormSubmitted={props.thumbsDownFormSubmitted} />
            break;
        case 'share':
            popup = <SharePopUp grantLink={props.grantLink} />
            break;
        case 'signInSignUp':
            popup = <SignInSignUpPopUp closePopUp={props.backgroundClicked} signInClicked={props.signInClicked} />
            break;
        case 'signInForm':
            popup = <SignInPopUp />
            break;
        case 'grantHelp':
            popup = <GrantHelpPopUp />
            break;
        default:
            popup = null
            break
    }


    return (
        <div className={classes.popupContainer} onClick={() => props.backgroundClicked()}>
            {popup}
        </div>
    )
}

export default PopupModal