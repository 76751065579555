import React from 'react'
import { withRouter } from 'react-router-dom'
import WufooFormTemplate from '../../../Components/UI/WufooForm/WufooForm'
import classes from './PublicForms.module.css'

import AlternateLayout from '../../../HOC/Layout/AlternateLayout/AlternateLayout'
import Footer from '../../../Components/Footer/Footer'

const HireExpert = (props) => {
    let form;

    switch(props.match.params.path){
        case 'lawyers':
            form='hireLawyer'
            break
        case 'grant-writer':
            form='hireWriter'
            break
        // case '':
        //     form=''
        //     break
        default:
            props.history.push('/')
    }

    return(
        <AlternateLayout>
            <div className={classes.container}>
                <div className={classes.content}>
                    <WufooFormTemplate type={form} />
                </div>
            </div>
            <Footer />
        </AlternateLayout>
    )
}

export default withRouter(HireExpert)