import React, { useContext, useEffect } from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink, withRouter } from 'react-router-dom'
import classes from './Navigation.module.css'
import faq from '../../Assets/Images/icon-FAQ-Question-Mark.png'
import searching from '../../Assets/Images/icon-searching.png'
import pocketedicon from '../../Assets/Images/logoSymbol.png'
import icon from '../../Assets/Images/Clearco/CLEARCO_WHITE-LOGOMARK.png'
import instance from '../../Assets/Axios/AxiosInstance'
import signout from '../../Assets/Images/icon-Logout.png'
import { StoreContext } from '../../Store/StoreContext'
import { Mixpanel } from '../../Assets/Functions/MixPanel/MixPanel'

const Navigation = (props) => {
    const { actions, state } = useContext(StoreContext)


    const openSideNav = (action) => {
        if (action) {
            document.getElementById("theSideNav").style.width = "250px";
        }
        else {
            document.getElementById("theSideNav").style.width = "0";
        }
    }

    const signOut = () => {
        instance.post('/accounts/logout/').then(res => {
            Mixpanel.track('User In Platform')
            actions.setCurrentUser({ user: '' })
            props.history.push('/auth/signin')

        }).catch(err => {
            console.log(err)
            Mixpanel.track('User In Platform')
            actions.setCurrentUser({ user: '' })
            props.history.push('/auth/signin')
        })
    }
    return (
        <>
            <Nav className={`flex-column d-sm-flex d-none ` + classes.navbar}>
                <NavLink to="/home" exact={true} className={classes.navHeader} id='home'>
                    <img src={pocketedicon} className={classes.icon} />
                    <img src={icon} className={classes.clearcoIcon} />
                </NavLink>
                <div className={classes.navLinks}>
                    <NavLink to="/home" activeClassName={classes.active} exact={true} id='home'>
                        <svg className={classes.svg + ' bi bi-house-door-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 10.995V14.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5V11c0-.25-.25-.5-.5-.5H7c-.25 0-.5.25-.5.495z" />
                            <path fillRule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                        </svg>
                        <p>Home</p>
                    </NavLink>
                    <NavLink to="/available-grants" activeClassName={classes.active} exact={true} id='available_grants'>
                        <img src={searching} className={classes.svg} />
                        <p>Grants</p>
                    </NavLink>

                    <NavLink to="/saved-grants" activeClassName={classes.active} id='saved_grants'>
                        <svg className={classes.svg + ' bi bi-bookmark-check-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4 0a2 2 0 0 0-2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4zm6.854 5.854a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                        </svg>
                        <p>Saved</p>
                    </NavLink>
                    <NavLink to="/account/" activeClassName={classes.active} id='account'>
                        <svg className={classes.svg + ' bi bi-gear-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z" />
                        </svg>
                        <p>Account</p>
                    </NavLink>
                    <a href="https://pocketed.zendesk.com/hc/en-us" target="_blank" >
                        <img src={faq} className={classes.svg} />
                        <p>FAQ</p>
                    </a>
                </div>
                <a className={classes.signoutBtn} onClick={() => signOut()}>
                    {state.currentUser.loggedIn ? <>
                        <img src={signout} />
                        Sign Out
                    </> : <></>}
                </a>
            </Nav>
            <Nav className={`d-sm-none ` + classes.navbarMobile}>
                <NavLink to="/" exact={true} className={classes.navHeaderMobile}>
                    <img src={icon} className={classes.icon} />
                </NavLink>
                <div className={classes.burgerMenu}>
                    <svg onClick={() => openSideNav(true)} width="1em" height="1em" viewBox="0 0 16 16" className={classes.burgerIcon + " bi bi-justify"} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </div>
                <div id="theSideNav" className={classes.sideNav}>
                    <div className={classes.navLinksMobile}>
                        <a className={classes.closebtn} onClick={() => openSideNav(false)} >X</a>
                        <NavLink to="/home" activeClassName={classes.active} exact={true} id='home_mobile'>
                            <svg className={classes.svg + ' bi bi-house-door-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5 10.995V14.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5V11c0-.25-.25-.5-.5-.5H7c-.25 0-.5.25-.5.495z" />
                                <path fillRule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                            </svg>
                            <h3>Home</h3>
                        </NavLink>

                        <NavLink to="/available-grants" activeClassName={classes.active} exact={true} id='available_grants_mobile'>
                            <img src={searching} className={classes.svg} />
                            <h3>Grants</h3>
                        </NavLink>
                        <NavLink to="/saved-grants" activeClassName={classes.active} id='saved_grants_mobile'>
                            <svg className={classes.svg + ' bi bi-bookmark-check-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4 0a2 2 0 0 0-2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4zm6.854 5.854a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                            </svg>
                            <h3>Saved</h3>
                        </NavLink>
                        <NavLink to="/account" activeClassName={classes.active} id='account_mobile'>
                            <svg className={classes.svg + ' bi bi-gear-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z" />
                            </svg>
                            <h3>Account</h3>
                        </NavLink>
                        <a href="https://pocketed.zendesk.com/hc/en-us" target="_blank" >
                            <img src={faq} className={classes.svg} />
                            <h3>FAQ</h3>
                        </a>
                        <a className={classes.signoutBtnMobile} onClick={() => signOut()}>
                            {state.currentUser.loggedIn ? <>
                                <img src={signout} />
                                <p>Sign Out</p>
                            </> : <></>}
                        </a>
                    </div>
                </div>
            </Nav>
        </>
    )
}

export default withRouter(Navigation)