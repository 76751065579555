import React, { createContext, useEffect, useReducer } from 'react'
import { Mixpanel } from '../Assets/Functions/MixPanel/MixPanel'
import { useActions } from './Actions'
import { initialState, Reducer } from './Reducer'

const StoreContext = createContext(initialState)

const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const actions = useActions(dispatch)

    // useEffect(() => {
    //     console.log(state)
    // }, [state])

    useEffect(() => {
        window.addEventListener('beforeunload', () => {
            if (state.currentUser.user !== '') {
                Mixpanel.track('User In Platform')
            }
        })

        return () => {
            if (state.currentUser.user !== '') {
                Mixpanel.track('User In Platform')
            }
        }
    }, [state.currentUser.user])

    // console.log(state)
    return (
        <StoreContext.Provider value={{ state, actions, }}>
            {children}
        </StoreContext.Provider>
    )
}


export { StoreContext, StoreProvider }