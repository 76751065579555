export const deformatDate = (date) => {
    const [year, month, day] = date.split('-')
    const d = new Date(year, month, day)
    return d
}


export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let res = [year, month, day].join('-');
    return res
}


export const displayDeadline = (date) => {
    let deadline
    if (date == 'Open Until Filled') {
        deadline = date
    }
    else if (deformatDate(date) < Date.now() || date == null) {
        // deadline = new Date().getFullYear() + ' deadline coming soon'
        deadline = 'Future Deadlines Coming Soon'
    }
    else {
        deadline = date
    }
    return deadline
}