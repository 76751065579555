import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import instance from '../../Assets/Axios/AxiosInstance';
import { Mixpanel } from '../../Assets/Functions/MixPanel/MixPanel';
import { StoreContext } from '../../Store/StoreContext';


const PrivateRoute = ({ component: Component, authLevel: AuthLevel, ...rest }) => {
    const [auth, setAuth] = useState(true)
    const { state, actions } = useContext(StoreContext)
    const script = document.createElement('script');

    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=fcdb48d5-87ab-4d46-912b-1556ed5130e6';
    script.id = 'ze-snippet';

    

    // Checks if user is logged in, redirects to login page if not
    useEffect(() => {
        instance.get('/user/').then(res => {
            if ('isAuthenticated' in res.data && !res.data.isAuthenticated) {
                setAuth(false)
            } else {
                // Initiaites tracking of user interactions on mixpanel
                Mixpanel.identify(res.data.username)
                Mixpanel.people.set_once({ $email: res.data.username })
                document.body.appendChild(script);
                if (state.currentUser.user !== res.data.username) {
                    actions.setCurrentUser({ user: res.data.username, loggedIn: true })
                    Mixpanel.timeEvent('User In Platform')
                }
            }
        }).catch(err => {
            console.log(err)
            setAuth(false)
        })
        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        }
    }, [])

    return (
        <Route {...rest} render={(props) => (
            auth ? <Component {...props} /> : <Redirect to="/auth/signin" />
        )} />
    );
}

export default withRouter(PrivateRoute);