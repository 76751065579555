const initialState = {
    // For determining whether to show the grant list search bar
    showSearchBar: false,
    // For which Grant to show in details
    grantDetails: {
        picked: false,
        details: false,
        selected: 0
    },
    // For determining whether the 'grant saved' notification should show
    grantSaved: false,
    // For determining when to show a pop up, and what content is in the popup
    popup: {
        flag: false,
        message: '',
        type: '',
        referralPopup: false
    },
    // The Grants that appear in the Grant List
    grantList: [],
    // User ID for registration of grant profiles, believe to be no longer relevant
    userID: null,
    // Filter Selection for setting and saving filter bar selections
    filterSelection: {
        type: 'All',
        selected: {
            new: ["All Grants"]
        },
        page: 1,
        search: '',
        level: -1
    },
    // for whether the grant list is loading or not
    grantListLoading: false,
    // For counting number of retrieved grants a user matched with under the current filtering
    grantCount: 0,
    // Setting the current platform user for Mixpanel tracking purposes, logged in state, for displaying sign in popup, and for eventually determining account access level
    currentUser: {
        loggedIn: false,
        user: '',
        adminLevel: 0,
        showSignInPopUp: false
    },
    // For if a user is viewing the platform for the first time, and what step of the tutorial they are on, not developed yet
    tutorialState: {
        flag: false,
        step: 0
    },
    // Used to show/hide additional filters panel on the Available/Saved Grants page
    additionalFilters: {
        flag: false,
    },
    // Used to display popup on Auth forms when an API call fails
    authFailed: false,
    // Used to display popup on Account Creation forms when the total matched grants to the user are low.
    shortGrantList: false,
    // Used to set the SubFilters shown in Additional Filters Component
    subFilters: {
        loaded: false,
        data:{}
    }
}

const types = {
    SET_SHOW_SEARCHBAR: 'SET_SHOW_SEARCHBAR',
    SET_GRANT_PICKED: 'SET_GRANT_PICKED',
    SET_GRANT_SAVED: 'SET_GRANT_SAVED',
    SET_POPUP: 'SET_POPUP',
    SET_GRANT_LIST: 'SET_GRANT_LIST',
    SET_USER_ID: 'SET_USER_ID',
    SET_FILTER_SELECTION: 'SET_FILTER_SELECTION',
    SET_GRANT_LIST_LOADING: 'SET_GRANT_LIST_LOADING',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    SET_LIKED_STATE: 'SET_LIKED_STATE',
    SET_TUTORIAL_STATE: 'SET_TUTORIAL_STATE',
    SET_INDIVIDUAL_GRANT: 'SET_INDIVIDUAL_GRANT',
    SET_ADDITIONAL_FILTERS: 'SET_ADDITIONAL_FILTERS',
    SET_AUTH_FAILED: 'SET_AUTH_FAILED',
    SET_SHORT_GRANT_LIST: 'SET_SHORT_GRANT_LIST',
    SET_SUBFILTERS: 'SET_SUBFILTERS'
}

const findAndReplaceListItem = (oldArray, newItem) => {
    let index = oldArray.findIndex(i => i.id == newItem.id)
    oldArray[index] = newItem
    return oldArray
}

const Reducer = (state = initialState, action) => {
    let result
    switch (action.type) {
        case types.SET_SHOW_SEARCHBAR:
            let tempState = { ...state }
            result = {
                ...tempState,
                showSearchBar: action.payload
            }
            // Return user to grant list view if on mobile or tablet and search icon is clicked
            if (action.payload && tempState.grantDetails.picked) {
                result.grantDetails.picked = false
            }
            return result
        case types.SET_GRANT_PICKED:
            return {
                ...state,
                grantDetails: {
                    ...state.grantDetails,
                    ...action.payload
                }
            }
        case types.SET_GRANT_SAVED:
            if (!action.payload) {
                return {
                    ...state,
                    grantSaved: false
                }
            }
            else {
                let oldState = { ...state }
                let newList = [...findAndReplaceListItem(oldState.grantList, action.payload)]
                if (state.grantDetails.details !== false) {
                    // console.log(state.grantDetails.details.id == action.payload.id, action.payload)
                    if (state.grantDetails.details.id == action.payload.id) {
                        oldState.grantDetails.details = action.payload
                    }
                }
                return {
                    ...oldState,
                    grantList: newList,
                    grantSaved: action.payload.saved
                }
            }
        case types.SET_POPUP:
            let tempStatePopup = { ...state.popup }
            return {
                ...state,
                popup: {
                    ...tempStatePopup,
                    ...action.payload
                }
            }
        case types.SET_GRANT_LIST:
            return {
                ...state,
                grantList: action.payload.results,
                grantCount: action.payload.count,
            }
        case types.SET_USER_ID:
            return {
                ...state,
                userID: action.payload
            }
        case types.SET_FILTER_SELECTION:
            return {
                ...state,
                filterSelection: {
                    ...state.filterSelection,
                    ...action.payload
                }
            }
        case types.SET_GRANT_LIST_LOADING:
            return {
                ...state,
                grantListLoading: action.payload,
                grantDetails: {
                    ...state.grantDetails,
                    picked: false
                }
            }
        case types.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    ...action.payload
                }
            }
        case types.SET_LIKED_STATE:
            let oldState = { ...state }
            let newList = [...findAndReplaceListItem(oldState.grantList, action.payload)]
            return {
                ...state,
                grantList: newList,
                grantDetails: {
                    ...state.grantDetails,
                    details: action.payload
                }
            }
        case types.SET_TUTORIAL_STATE:
            return {
                ...state,
                tutorialState: action.payload
            }
        case types.SET_INDIVIDUAL_GRANT:
            return {
                ...state,
                grantDetails: {
                    ...state.grantDetails,
                    details: {
                        grant: action.payload.grant,
                        id: 0,
                        new: false,
                        saved: false
                    },
                    selected: true
                },
                currentUser: {
                    ...state.currentUser,
                    loggedIn: action.payload.loggedIn
                }
            }
        case types.SET_ADDITIONAL_FILTERS:
            return {
                ...state,
                additionalFilters: {
                    ...state.additionalFilters,
                    ...action.payload
                }
            }
        case types.SET_AUTH_FAILED:
            return{
                ...state,
                authFailed: action.payload
            }
        case types.SET_SHORT_GRANT_LIST:
            return {
                ...state,
                shortGrantList: action.payload
            }
        case types.SET_SUBFILTERS:
            return{
                ...state,
                subFilters: {
                    loaded: action.payload.loaded,
                    data: action.payload.data
                }
            }
    }
}


export { Reducer, types, initialState }