import mixpanel from 'mixpanel-browser';
import publicIp from "public-ip";

mixpanel.init(process.env.REACT_APP_MIXPANEL)
let env_check = process.env.REACT_APP_NODE_ENV === 'production';

(async () => {
    await publicIp.v4().then(res => {
        checkIP(res)
    }).catch(async err => {
        console.log(err)
        await publicIp.v6().then(res => {
            checkIP(res)
        }).catch(err => {
            console.log(err)
        })
    })
	//=> '46.5.21.123'
})();

const checkIP = (ip) => {
    const deepndIP = [
        '70.36.49.212',
        '70.78.124.8',
        '99.227.5.209',
        '24.67.184.66',
        '154.20.200.8',
        '2001:569:7bb9:7f00:d498:a0fb:88e1:2967',
        '184.146.63.89',
        '174.7.235.82',
        '2001:569:756a:2800:20d8:fd0f:c66f:ddcd',
    ]

    if(deepndIP.includes(ip)){
        mixpanel.register({"$ignore":true});
    }
}


let actions = {
    identify: (id) => {
        if(env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if(env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if(env_check) mixpanel.track(name, props);
    },
    trackLinks: (id, eventname, props) => {
        if(env_check) mixpanel.track_links(id, eventname, props)
    },
    timeEvent: (props) => {
        if(env_check) mixpanel.time_event(props)
    },
    trackForms: (props) => {
        if(env_check) mixpanel.track_forms(props)
    },
    people: {
        set: (props) => {
            if(env_check) mixpanel.people.set(props);
        },
        increment: (props) => {
            if(env_check) mixpanel.people.increment(props);
        },
        set_once: (props) => {
            if(env_check) mixpanel.people.set_once(props)
        }
    },
};

export let Mixpanel = actions