import React from 'react'
import CustomInput from '../CustomInput'

const InputArrayMapper = ({ profileState, classes, formChange }) => {
    return (
        <div className={classes.content}>
            {
                Object.keys(profileState.form).map((i, k) => {
                    // console.log(k < profileState.formInputUpperLimit && k >= profileState.formInputLowerLimit)
                    if (profileState.formInputUpperLimit == undefined || (k < profileState.formInputUpperLimit && k >= profileState.formInputLowerLimit)) {
                        let theForm = { ...profileState.form }
                        let theType
                        // console.log(theForm[i])
                        if(theForm[i].show !== undefined && !theForm[i].show){
                            // console.log(theForm[i].show !== undefined, !theForm[i].show)
                            return
                        }

                        if (theForm[i].edit) {
                            theType = theForm[i].type + '-edit'
                        } else {
                            theType = theForm[i].type + '-display'
                        }
                        return (
                            <div key={k} className={classes.inputElements + ' ' + theForm[i].additionalClass}>
                                <CustomInput
                                    id={i}
                                    type={theType}
                                    label={theForm[i].label}
                                    inputConfig={theForm[i].elementConfig}
                                    value={theForm[i].value}
                                    change={(e) => formChange(e, i, theForm[i].multiple)}
                                    hint={theForm[i].edit ? theForm[i].hint : ''}
                                    error={theForm[i].edit ? theForm[i].error : ''}
                                    options={theForm[i].options}
                                >   
                                {/* if the dropdown value is currently empty, assign the selected prop to an empty option */}
                                    {theForm[i].value === '' || theForm[i].value?.length === 0? <option selected hidden></option>: ''}
                                    {typeof theForm[i].options == 'undefined' ? '' : theForm[i].options.map((ii, kk) => {
                                        // console.log(theForm[i].value == '')
                                        //if the options array is provided as an array of strings, show the string value in the option choice 
                                        if (typeof ii == 'string') {
                                            if (theForm[i].value == ii) {
                                                return <option key={kk} selected value={ii}>{ii}</option>
                                            }
                                            else {
                                                return <option key={kk}>{ii}</option>
                                            }
                                        }
                                        else {
                                            if (theForm[i].value.toString() == ii.value.toString() && theForm[i].value !== '') {
                                                return <option key={kk} selected value={ii.value}>{ii.label}</option>
                                            }
                                            else {
                                                return <option key={kk} value={ii.value}>{ii.label}</option>
                                            }
                                        }
                                    })}
                                </CustomInput>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default InputArrayMapper