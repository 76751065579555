import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import instance from '../../../../Assets/Axios/AxiosInstance'
import Loading from '../../../../Components/UI/Loading/Loading'
import classes from './AccountActivated-Beta.module.css'

const AccountActivatedBeta = ({ history }) => {
    const [message, setMessage] = useState({ message: <></>, flag: true })
    const { path, token } = useParams()

    useEffect(() => {
        instance.get('/activate/' + path + '/' + token + '/').then(res => {
            history.push('/checkback-soon')
        }).catch(err => {
            console.log(err.response)
            setMessage(old => {
                let res = { ...old }
                res.flag = false
                res.message = <><p>{err.response.data.error}</p></>
                return res
            })
        })
    }, [])

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                {message.flag ? <Loading /> : <>
                    {message.message}
                </>
                }
            </div>
        </div >
    )
}

export default withRouter(AccountActivatedBeta)