import React from 'react'
import { withRouter } from 'react-router-dom'
import AlternateLayout2 from '../../../HOC/Layout/AlternateLayout2/AlternateLayout2'
import CrossAndBlobLayout from '../../../HOC/Layout/CrossAndBlobLayout/CrossAndBlobLayout'
import classes from './FormComplete.module.css'
import image from '../../../Assets/Images/unlockPurple.svg'

const FormComplete = (props) => {
    let header;
    let subHeader;
    let btn;
    // console.log(props.match.params.path)
    switch (props.match.params.path) {
        case 'application':
            header = <h2>Thank you for applying to be an Beta Tester!</h2>
            subHeader = <>
                <h3>You're one step close to unlocking impactful funding</h3>
                <h3 className={classes.highlight}>Beta Launch Coming Spring 2021!</h3>
            </>
            btn = <a href='/'>Return To Home Page</a>
            break;
        case 'refer':
            header = <h2>Thank you for referring a start-up!</h2>
            subHeader = <h3>They're one step close to unlocking impactful funding</h3>
            btn = <a href="/beta-referral/refer">Refer Another Venture</a>
            break;
        default:
            props.history.push('/404')
            break;
    }


    return (
        <AlternateLayout2>
            <CrossAndBlobLayout>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.imgContainer}>
                            <img src={image} />
                        </div>
                        {header}
                        {subHeader}
                        <div className={classes.btnContainer}>
                            {btn}
                        </div>
                        <div className={classes.postscript}>
                            <p>PS - Don't miss out! Check your spam and promotions folder for your confirmation email</p>
                        </div>
                    </div>
                </div>
            </CrossAndBlobLayout>
        </AlternateLayout2>
    )
}

export default withRouter(FormComplete)