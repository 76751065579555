import React, { useState } from 'react'
import { FormControl } from 'react-bootstrap'
import CustomButton from '../../CustomButton/CustomButton'
import classes from './ThumbsDownPopUp.module.css'

const ThumbsDownPopUp = (props) => {
    const [thumbsDownState, setThumbsDownState] = useState({
        doesNotMeetRequirements: false,
        doNotUnderstandApplication: false,
        grantNotAcceptingApplications: false,
        other: ''
    })

    const formChanged = (formID, e) => {
        e.persist()
        // console.log(e.currentTarget.value)
        switch (formID) {
            case 1:
                setThumbsDownState(old => {
                    old.doesNotMeetRequirements = e.currentTarget.checked
                    return old
                })
                break
            case 2:
                setThumbsDownState(old => {
                    old.doNotUnderstandApplication = e.currentTarget.checked
                    return old
                })
                break
            case 3:
                setThumbsDownState(old => {
                    old.grantNotAcceptingApplications = e.currentTarget.checked
                    return old
                })
                break
            case 4:
                setThumbsDownState(old => {
                    old.other = e.currentTarget.value
                    return old
                })
                break
        }

    }

    return (
        <div className={classes.container} onClick={(e) => e.stopPropagation()}>
            <div className={classes.header}>
                <h2>Help us improve your grant matches! Why wasn't this grant a good match?</h2>
            </div>
            <form className={classes.formContainer} onSubmit={() => props.thumbsDownFormSubmitted(thumbsDownState)}>
                <div >
                    <input type="checkbox" name="choice" value="choice-1" id="choice-1" onChange={e => formChanged(1, e)} />
                    <label htmlFor="choice-1">My company does not meet the requirements</label>
                </div>
                <div>
                    <input type="checkbox" name="choice" value="choice-2" id="choice-2" onChange={e => formChanged(2, e)} />
                    <label htmlFor="choice-2">I do not understand the application process</label>
                </div>
                <div>
                    <input type="checkbox" name="choice" value="choice-3" id="choice-3" onChange={e => formChanged(3, e)} />
                    <label htmlFor="choice-3">The grant is not currently accepting applications</label>
                </div>
                <div>
                    <label htmlFor="extraInfo">Other:</label>
                    <FormControl id="extraInfo" onChange={e => formChanged(4, e)} />
                </div>
                <div className={classes.buttonContainer}>
                    <CustomButton title="Submit" type="submit" css="dark-purple" />
                </div>
            </form>
        </div>
    )
}

export default ThumbsDownPopUp