import React from 'react'
import classes from './Bar.module.css'

const Bar = ({height, solid}) => {
    const style = {
        height: height
    }
    let filled = solid? classes.solid : '';
    
    return(
        <div className={classes.bar + ' ' + filled} style={style}>

        </div>
    )
}

export default Bar