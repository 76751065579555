import React, { useContext, useState, useRef, useEffect } from 'react'
import { Mixpanel } from '../../../Assets/Functions/MixPanel/MixPanel'
import { StoreContext } from '../../../Store/StoreContext'
import classes from './SearchBar.module.css'

const SearchBar = () => {
    const { state, actions } = useContext(StoreContext)
    const [searchState, setSearchState] = useState({
        showButtonDisplay: 'none',
    })
    const [trackSearch, setSearchTrack] = useState('')
    const inputRef = useRef()
    let trackSearchTimeout

    useEffect(() => {
        if (state.showSearchBar) {
            inputRef.current.focus()
            if (inputRef.current.value !== '') {
                setSearchState(old => {
                    let res = { ...old }
                    res.showButtonDisplay = 'flex'
                    return res
                })
            }
        } else {
            setSearchState(old => {
                let res = { ...old }
                res.showButtonDisplay = 'none'
                return res
            })
        }
    }, [state.showSearchBar])

    useEffect(() => {
        if (trackSearch !== '') {
            trackSearchTimeout = setTimeout(() => {
                Mixpanel.track('User Search', { searchText: trackSearch })
            }, 2000)
        }
        return () => {
            clearTimeout(trackSearchTimeout)
        }
    }, [trackSearch])

    const inputChange = (e) => {
        e.persist()
        if (e.target.value !== '') {
            setSearchState(old => {
                let res = { ...old }
                res.showButtonDisplay = 'flex'
                return res
            })
            actions.setFilterSelection({ search: e.target.value })
            setSearchTrack(e.target.value)
        }
        else {
            setSearchState(old => {
                let res = { ...old }
                res.showButtonDisplay = 'none'
                return res
            })
            actions.setFilterSelection({ search: '' })
            setSearchTrack('')
        }
    }

    const clearText = () => {
        inputRef.current.value = ''
        setSearchState(old => {
            let res = { ...old }
            res.showButtonDisplay = 'none'
            return res
        })
        actions.setFilterSelection({ search: '' })
    }



    return (
        <div className={classes.searchBarContainer} style={state.showSearchBar ? { height: '40px' } : { height: 0 }}>
            <input className={classes.inputBar} type="text" onChange={(e) => inputChange(e)} ref={inputRef} placeholder="search" style={state.showSearchBar ? {} : { border: 'none' }}></input>
            <button className={"clearButton " + classes.closeButton} style={{ display: searchState.showButtonDisplay }} onClick={() => clearText()}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>
            </button>
        </div>
    )
}

export default SearchBar