import React from 'react';
import { Form } from 'react-bootstrap';
import classes from './CustomInput.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import MultiSelect from 'react-multi-select-component';

const CustomInput = props => {
    let theInput;
    switch (props.type) {
        case 'text-display':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <p id={props.id}>{props.value}</p>
                </>
            );
            break;
        case 'text-edit':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <Form.Control className={classes.input} id={props.id} type="text" {...props.inputConfig} onChange={props.change} value={props.value}></Form.Control>
                </>
            );
            break;
        case 'date-display':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <p id={props.id}>{props.value}</p>
                </>
            );

            break;
        case 'date-edit':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <Form.Control className={classes.input} id={props.id} type="date" {...props.inputConfig} onChange={props.change} value={props.value}></Form.Control>
                </>
            );
            break;
        case 'email-edit':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <Form.Control className={classes.input} id={props.id} type="email" {...props.inputConfig} onChange={props.change} value={props.value}></Form.Control>
                </>
            );
            break;
        case 'email-display':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <p id={props.id}>{props.value}</p>
                </>
            );
            break;
        case 'password-edit':
            // console.log(props.type)
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <Form.Control className={classes.input} id={props.id} type="password" {...props.inputConfig} onChange={props.change}></Form.Control>
                </>
            );
            break;
        case 'dropdown-edit':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <Form.Control className={classes.input} onChange={props.change} title={props.value} id={props.id} {...props.inputConfig} as="select">
                        {props.children}
                    </Form.Control>
                </>
            );
            break;
        case 'dropdown-display':
            if (typeof props.value == 'boolean') {
                theInput = (
                    <>
                        <Form.Label className={classes.label} htmlFor={props.id}>
                            {props.label}
                        </Form.Label>
                        <p id={props.id}>{props.value ? 'Incorporated' : 'Unincorporated'}</p>
                    </>
                );
            } else if (typeof props.value == 'array') {
                // console.log(props.value)
            } else if (typeof props.value == 'object') {
                theInput = (
                    <>
                        <Form.Label className={classes.label} htmlFor={props.id}>
                            {props.label}
                        </Form.Label>
                        <p id={props.id}>{props.value.label}</p>
                    </>
                );
            } else {
                theInput = (
                    <>
                        <Form.Label className={classes.label} htmlFor={props.id}>
                            {props.label}
                        </Form.Label>
                        <p id={props.id}>{props.value}</p>
                    </>
                );
            }
            break;
        case 'dropdown-multiple-display':
            let value;
            if (props.value !== undefined && props.value.length > 0) {
                value = props.value.map((i, k) => {
                    if (typeof i !== 'string' && ++k == props.value.length) {
                        return i.value;
                    } else if (typeof i !== 'string') {
                        return i.value + ', ';
                    } else if (++k == props.value.length) {
                        return i;
                    } else {
                        return i + ', ';
                    }
                });
            } else if (props.value !== undefined && props.value.length == 0) {
                value = 'None Selected';
            }
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <p>{value}</p>
                </>
            );
            break;
        case 'dropdown-multiple-edit':
            // console.log(props)
            let arrayValue = props.value.map(i => {
                if (typeof i == 'string') {
                    return { value: i, label: i };
                } else {
                    return i;
                }
            });

            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <MultiSelect options={props.options} value={arrayValue} onChange={props.change} labelledBy={'Select'} hasSelectAll={false} disableSearch className="multiselect-input" />
                </>
            );
            break;
        case 'textarea-edit':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <Form.Control className={classes.input} id={props.id} onChange={props.change} value={props.value} {...props.inputConfig} as="textarea" />
                </>
            );
            break;
        case 'textarea-display':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <p id={props.id}>{props.value}</p>
                </>
            );
            break;
        case 'checkbox-edit':
            theInput = (
                <>
                    <Form.Check type="checkbox" label={props.label} onChange={props.change} {...props.inputConfig} />
                </>
            );
            break;
        case 'date-picker-edit':
            // console.log(props)
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <DatePicker selected="" onChange={props.change} {...props.inputConfig} selected={props.value} showMonthYearPicker dateFormat="MMMM yyyy" />
                </>
            );
            break;
        case 'date-picker-display':
            const op = { year: 'numeric', month: 'long' };
            // console.log(props)
            let res = props.value.toLocaleString('en-US', op);
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <p id={props.id}>{res}</p>
                </>
            );
            break;
        case 'phone-edit':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <PhoneInput required placeholder="" value={props.value} onChange={props.change} defaultCountry="CA" />
                </>
            );
            break;
        case 'phone-display':
            theInput = (
                <>
                    <Form.Label className={classes.label} htmlFor={props.id}>
                        {props.label}
                    </Form.Label>
                    <p id={props.id}>{props.value}</p>
                </>
            );
            break;
    }

    return (
        <>
            {theInput}
            {props.hint ? props.hint : null}
            {props.error ? props.error : null}
        </>
    );
};

export default CustomInput;
