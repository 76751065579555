import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import CountDownTimer from '../../../../Components/UI/CountdownTimer/CountDownTimer'
import AlternateLayout2 from '../../../../HOC/Layout/AlternateLayout2/AlternateLayout2'
import CrossAndBlobLayout from '../../../../HOC/Layout/CrossAndBlobLayout/CrossAndBlobLayout'
import classes from './CheckBack-BetaConfirmed.module.css'

const CheckBackBetaConfirmed = (props) => {
    return (
        <AlternateLayout2>
            <CrossAndBlobLayout>
                <div className={classes.container}>
                    {/* <CountDownTimer timerFinished={() => props.history.push('/auth/sign-in')} /> */}
                    <div className={classes.header}>
                        <h2>Thank you for creating your account!</h2>
                        <p>You'll have access to Pocketed in Fall 2021 <br/>(don't worry, we'll send you an email as a reminder)!</p>
                    </div>
                    {/* <div className={classes.link}>
                        <a href="/beta-referral/refer">Refer A Friend</a>
                    </div> */}
                </div>
            </CrossAndBlobLayout>
        </AlternateLayout2>
    )
}

export default withRouter(CheckBackBetaConfirmed)