import React from 'react'
import Navigation from '../../Components/Navigation/Navigation'
import classes from './Layout.module.css'

const Layout = ({children}) => {
    return (
        <div className={classes.container + ' flex-column flex-sm-row'}>
            <Navigation/>
            {children}
        </div>
    )
}

export default Layout