import React, { useContext } from 'react'
import LoginForm from '../../../../Containers/Auth/LoginForm/LoginForm'
import { StoreContext } from '../../../../Store/StoreContext'
import classes from './SignInPopUp.module.css'

const SignInPopUp = () => {
    const {actions} = useContext(StoreContext)

    const signInCallback = () => {
        actions.setCurrentUser({loggedIn: true, showSignInPopUp: false})
    }

    return (
        <div className={classes.container} onClick={(e) => e.stopPropagation()}>
            <LoginForm noRedirect callback={signInCallback}/>
        </div>
    )
}

export default SignInPopUp