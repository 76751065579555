import React from 'react'
import AlternateLayout2 from '../../HOC/Layout/AlternateLayout2/AlternateLayout2'
import classes from './Page404.module.css'
import image from '../../Assets/Images/404Icon.svg'
import AltNav2 from '../../Components/Navigation/AlternateNavBar2/AltNav2'
// import image from '../../Assets/Images/blob-pencil.png'

const Page404 = () => {
    return (
        <AlternateLayout2>
            <AltNav2/>
            <div className={classes.container}>
                <div className={classes.content}>
                    <img className={classes.img} src={image}/>
                    <div className={classes.message}>
                        <h2>Oops!</h2>
                        <p>
                            You've stumbled on a page that no longer exists.<br/>
                            Click below to scout out your next funding opportunity.
                        </p>
                        <div className={classes.btnContainer}>
                            <a className={classes.btn} href="/">Back to Home Page</a>
                        </div>
                    </div>
                </div>
            </div>
        </AlternateLayout2>
    )
}

export default Page404