import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import classes from './AltNav2.module.css'
import logoWhite from '../../../Assets/Images/logoWhite.png'

const AltNav2 = (props) => {
    return (
        <Nav className={classes.container} id='altnavbar'>
            <div className={classes.content}>
                <div className={classes.dummy}>
                    {/* dummy div */}
                </div>
                <NavLink to='/' className={classes.icon}>
                    <img src={logoWhite} className={classes.img} />
                    {/* <h1 id="navHeader">Pocketed</h1> */}
                </NavLink>
                <div className={classes.authLinks}>
                    <NavLink to='/' className={classes.faq + ' d-none'}>
                        <p id="navfactLink">FAQ</p>
                    </NavLink>
                    <NavLink to='/Auth/login' className={classes.signin}>
                        {/* <p className={'landing-page-button2'} id="signIn">Alpha Sign In</p> */}
                    </NavLink>
                </div>
            </div>
        </Nav>
    )
}

export default AltNav2