import React from 'react'
import { withRouter } from 'react-router-dom'
import Footer from '../../../Components/Footer/Footer'
import AltNav from '../../../Components/Navigation/AlternateNavBar/AltNav'
import WufooFormTemplate from '../../../Components/UI/WufooForm/WufooForm'
import AlternateLayout from '../../../HOC/Layout/AlternateLayout/AlternateLayout'
import classes from './PublicForms.module.css'

const PublicForms = (props) => {
    let form;

    switch (props.match.params.path) {
        case 'contact-us':
            form = 'contactUs'
            break
        // case 'knowledge-centre':
        //     form=''
        //     break
        case 'submit-grant':
            form = 'submitGrant'
            break
        case 'become-referral-partner':
            form = 'referralPartner'
            break
        case 'grant-writer':
            form = 'becomeWriter'
            break
        // case '':
        //     form=''
        //     break
        default:
            props.history.push('/')
    }

    return (
        <AlternateLayout>
            <div className={classes.container}>
                <div className={classes.content}>
                    <WufooFormTemplate type={form} />
                </div>
            </div>
            <Footer/>
        </AlternateLayout>
    )
}

export default withRouter(PublicForms)