import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import CountDownTimer from '../../../Components/UI/CountdownTimer/CountDownTimer'
import AlternateLayout2 from '../../../HOC/Layout/AlternateLayout2/AlternateLayout2'
import CrossAndBlobLayout from '../../../HOC/Layout/CrossAndBlobLayout/CrossAndBlobLayout'
import classes from './CheckBack.module.css'

const CheckBack = (props) => {

    return (
        <AlternateLayout2>
            <CrossAndBlobLayout>
                <div className={classes.container}>
                    {/* <CountDownTimer timerFinished={() => props.history.push('/auth/create-account')}/>  */}
                    <div className={classes.header}>
                        <h2>Check back in Fall 2021 to create your Pocketed Account!</h2>
                    </div>
                    {/* <div className={classes.link}>
                        <a href="/beta-referral/refer">Refer A Friend</a>
                    </div> */}
                </div>
            </CrossAndBlobLayout>
        </AlternateLayout2>
    )
}

export default withRouter(CheckBack)