// take dropdown options retrieved from backend, and set it to the form state
export const setDropdownOptions = (r, setState) => {
    setState(old => {
        let res = { ...old }
        res.form.industry.options = r.data.industries.map(i => {
            return { label: i.name, value: i.name }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.country.options = r.data.countries.map(i => {
            return { label: i.name, value: i.abbreviation }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.region_ca.options = r.data.regions_ca.map(i => {
            return { label: i.name, value: i.name }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.region_us.options = r.data.regions_us.map(i => {
            return { label: i.name, value: i.name }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.businessGoals.options = r.data.business_goals.map(i => {
            return { label: i.type, value: i.type }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.founderType.options = r.data.founder_types.map(i => {
            return { label: i.type, value: i.type }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.businessType.options = r.data.business_types.map(i => {
            return { label: i.type, value: i.type }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.awardedTypes.options = r.data.grant_types.map(i => {
            return { label: i.type, value: i.type }
        }).sort((a, b) => {
            if (a.value > b.value) {
                return 1
            }
            else {
                return -1
            }
        })
        res.form.annualRevenue.options = [...r.data.annual_revenue.options]
        res.form.capitolAmount.options = [...r.data.raised_capital.options]
        res.form.awardedAmount.options = [...r.data.award_amount.options]

        res.loading = false
        return res
    })
}

export const formChangeFunction = (setState) => {

    return (e, i, multiple) => {
        // if changed input submits undefined, clear input
        if (typeof e == 'undefined') {
            setState(old => {
                let temp = { ...old }
                temp.form[i].value = ''
                return temp
            })
        }
        // if changed input is a dropdown that allows multiple selections, then treat it as such
        else if (multiple) {
            setState(old => {
                let temp = { ...old }
                temp.form[i].value = e
                temp.form[i].error = ''
                return temp
            })
        }
        // if changed input is a date, then treat it as such
        else if (e instanceof Date) {
            setState(old => {
                let temp = { ...old }
                temp.form[i].value = e

                return temp
            })
        }
        // if changed input is a number, then treat it as such
        else if (!isNaN(e)) {
            setState(old => {
                let temp = { ...old }
                temp.form[i].value = e
                return temp
            })
        }
        else {
            e.persist()
            setState(old => {
                let temp = { ...old }
                // check if this element should affect the display of other input elements
                if (temp.form[i].hasReliants !== undefined && temp.form[i].hasReliants) {
                    // if changed input is a simple true/false question, show reliantInputs on true, and show reliantInputsFalse on false
                    if (typeof temp.form[i].reliantInputs[0] == 'string') {
                        temp.form[i].reliantInputs.forEach(ii => {
                            temp.form[ii].show = (e.target.value == 'true')
                        })
                        if (temp.form[i].reliantInputsFalse !== undefined) {
                            temp.form[i].reliantInputsFalse.forEach(ii => {
                                temp.form[ii].show = (e.target.value == 'false')
                            })
                        }
                        // if not a simple true/false question, but reliantInputs display is dependent on choice of changed input, do the below
                    } else {
                        temp.form[i].reliantInputs.forEach(ele => {
                            // if changed value matches the reliantInputs match value, set show value of the element that matches the display value of the reliantInput to true
                            if (ele.match == e.target.value) {
                                temp.form[ele.display].show = true
                                temp.form[ele.display].elementConfig.required = true
                            }
                            // else set show value of the element that matches the display value of the reliantInput to false, and reset value of the input
                            else{
                                temp.form[ele.display].show = false
                                temp.form[ele.display].value = temp.form[ele.display].initialValue
                                temp.form[ele.display].elementConfig.required = false
                            }
                        })

                    }
                }
                temp.form[i].value = e.target.value
                return temp
            })
        }
    }
}

// returns a function that checks inputs on submit to make sure all required inputs have been filled out before submitting
export const checkRequiredFunction = (state) => {
    return (formInputs) => {
        let res = { flag: false }
        Object.keys(formInputs).forEach((i, k) => {
            if (formInputs[i].elementConfig.required !== undefined && 
                formInputs[i].elementConfig.required && 
                formInputs[i].value.length == 0 && 
                !res.flag && 
                k < state.formInputUpperLimit &&
                formInputs[i].show) {
                res = { flag: true, ele: i }
            }
        })
        return res
    }
}

// returns a function that changes form page and updates the upper and lower form input limits
export const goToPageFunction = (profileState, setProfileState) => {
    return (num) => {
        if (num === 1) {
            setProfileState(old => {
                let temp = { ...old }
                temp.page = 1
                temp.formInputUpperLimit = profileState.firstUpperLimit
                temp.formInputLowerLimit = profileState.lowerLimit
                temp.displayPaymentForm = false
                return temp
            })
        } else if (num === 2) {
            setProfileState(old => {
                let temp = { ...old }
                temp.page = 2
                temp.formInputUpperLimit = profileState.secondUpperLimit
                temp.formInputLowerLimit = profileState.firstUpperLimit
                temp.displayPaymentForm = false
                return temp
            })
        } else if(num === 3){
            setProfileState(old => {
                let temp = { ...old }
                temp.page = 3
                temp.formInputUpperLimit = profileState.thirdUpperLimit
                temp.formInputLowerLimit = profileState.secondUpperLimit
                temp.displayPaymentForm = false
                return temp
            })
        }
    }
}