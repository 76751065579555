import React from 'react'
import { Dropdown } from 'react-bootstrap'
import classes from './DropDownItem.module.css'
import check from '../../../../../Assets/Images/check-square.svg'
import checktrue from '../../../../../Assets/Images/check-true-square.svg'

const DropDownItem = ({ title, checkbox, checked, itemSelected, multiple, param }) => {
    // let icon = new Image()
    // if(checked){
    //     icon.src = checktrue
    // } else{
    //     icon.src = check
    // }


    return (
        <Dropdown.Item className={classes.checkbox} onSelect={(e) => itemSelected(e, multiple, param)} eventKey={title}>
            {checkbox ?
                <div className={classes.checkboxBtn}>
                    <input type="checkbox" checked={checked}/>
                    <p>{title}</p>
                </div> :
                <div className={classes.checkboxBtn}>
                    <p><b>{title}</b></p>
                </div>
            }
        </Dropdown.Item>
    )
}

export default DropDownItem