import React, { useState, useEffect, useRef, useContext } from 'react'
import CustomButton from '../../UI/CustomButton/CustomButton'
import classes from './FilterBar.module.css'
import { StoreContext } from '../../../Store/StoreContext'
import filterOptions from '../../../Assets/Functions/Filters/FilterOptions'
import SetFilterOptions from './SetFilterOptions/SetFilterOptions'
import filterIcon from '../../../Assets/Images/filter.svg'
import PopupModal from '../../UI/PopUps/PopupModal'

const FilterBar = ({ pageName, filterBarDisplay, singleGrantView }) => {
    const { actions, state } = useContext(StoreContext)
    const [filters, setFilters] = useState({
        mainFilter: {
            title: '',
            options: [],
            checkbox: false,
            inputConfig: {
                variant: "primary",
                css: "dark d-none d-lg-flex"
            },
            inputConfigMobile: {
                variant: "primary",
                css: "dark"
            },
        },
        rightFilters: [
            {
                title: '',
                options: [],
                checkbox: false,
            }
        ],
        signInSignUp: false
    })
    const [scrollButton, setScrollButtons] = useState({ left: false, right: false })
    const [scrollButtonMobile, setScrollButtonsMobile] = useState({ left: false, right: false })
    const scrollRef = useRef()

    useEffect(() => {
        setFilters(old => {
            let res = { ...old }
            let filterSelection = { ...state.filterSelection }
            res.rightFilters = filterOptions['All'].subselection.map((i) => {
                let filterColor = 'white-filter'
                return {
                    title: i.title,
                    param: i.param,
                    checkbox: true,
                    allowMultiple: i.multiple,
                    itemSelected: subItemSelected,
                    options: i.options.map(ii => {
                        let flag = false
                        if (filterSelection.selected[i.param] !== undefined && filterSelection.selected[i.param].indexOf(ii.name) > -1) {
                            flag = true
                            filterColor = "dark"
                        }
                        if (i.param == 'new' && ii.name === 'All Grants' && filterSelection.selected[i.param][0] === 'All Grants') {
                            flag = true
                            filterColor = 'white-filter'
                        }
                        return { label: ii.name, checked: flag, value: ii.value }
                    }),
                    inputConfig: {
                        variant: "primary",
                        css: filterColor
                    },
                }
            })

            return res
        })
    }, [state.filterSelection])


    const subItemSelected = (e, multiple, param) => {
        let filterSelection = { ...state.filterSelection }
        let res = { ...filterSelection.selected }
        // check to see if selected filter parent is already in the selected object, if it isn't create a new key/value pair with the parent/value
        if (res[param] !== undefined && res[param].length > 0) {
            let flag = res[param].indexOf(e)
            // if key/value is already in selection remove it, if selection allows for multiple, add it to the selected key, otherwise replace it
            if (flag > -1) {
                res[param].splice(flag, 1)
            }
            else if (multiple) {
                res[param].push(e)
            }
            else {
                res[param] = [e]
            }
        }
        else {
            res[param] = [e]
        }

        actions.setFilterSelection({ selected: res })
    }


    useEffect(() => {
        const slider = document.querySelectorAll('#scrollbar');
        let bar1 = document.querySelector('#container1')
        let bar2 = document.querySelector('#container2')

        let isDown = false;
        let startX;
        let scrollLeft;

        Array.from(slider).map((s, k) => {
            setTimeout(() => {
                // console.log(s.scrollWidth, s.offsetWidth)
                if (s.scrollWidth !== s.offsetWidth) {
                    setScrollButtons({ left: false, right: true })
                    setScrollButtonsMobile({ left: false, right: true })
                    bar1.classList.add(classes.fadeRight)
                    bar2.classList.add(classes.fadeRight)
                }
            }, 1)

            s.addEventListener('mousedown', (e) => {
                isDown = true;
                startX = e.pageX - s.offsetLeft;
                scrollLeft = s.scrollLeft;
            });
            s.addEventListener('mouseleave', () => {
                isDown = false;
            });
            s.addEventListener('mouseup', () => {
                isDown = false;
            });
            s.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - s.offsetLeft;
                const walk = (x - startX) * 2;
                s.scroll(scrollLeft - walk, 0)
            });

            s.addEventListener('scroll', () => {
                // console.log(s.scrollLeft + s.offsetWidth, s.scrollWidth)
                // checks if scroll bar is all the way right, if so removes right arrow
                if ((Math.floor(s.scrollLeft + s.offsetWidth)) >= s.scrollWidth - 10) {
                    // console.log("if")
                    if (k === 0) {
                        if (bar1.classList.contains(classes.fadeBothSides)) {
                            bar1.classList.remove(classes.fadeBothSides)
                            bar1.classList.add(classes.fadeLeft)
                            setScrollButtons({ left: true, right: false })
                        }
                    }
                    else {
                        if (bar2.classList.contains(classes.fadeBothSides)) {
                            bar2.classList.remove(classes.fadeBothSides)
                            bar2.classList.add(classes.fadeLeft)
                            setScrollButtonsMobile({ left: true, right: false })
                        }
                    }
                }
                // checks if scroll bar is all the way left, if so removes left arrow
                else if (s.scrollLeft === 0) {
                    // console.log('if')
                    if (k === 0) {
                        if (bar1.classList.contains(classes.fadeBothSides)) {
                            bar1.classList.remove(classes.fadeBothSides)
                            bar1.classList.add(classes.fadeRight)
                            setScrollButtons({ left: false, right: true })
                        }
                    }
                    else {
                        if (bar2.classList.contains(classes.fadeBothSides)) {
                            bar2.classList.remove(classes.fadeBothSides)
                            bar2.classList.add(classes.fadeRight)
                            setScrollButtonsMobile({ left: false, right: true })
                        }
                    }
                }
                // if previos two conditions are not met, shows right and left arrow
                else {
                    // console.log('else')
                    if (k === 0) {
                        if (!bar1.classList.contains(classes.fadeBothSides)) {
                            bar1.classList.add(classes.fadeBothSides)
                            bar1.classList.remove(classes.fadeRight)
                            bar1.classList.remove(classes.fadeLeft)
                            setScrollButtons({ left: true, right: true })
                        }
                    }
                    else {
                        if (!bar2.classList.contains(classes.fadeBothSides)) {
                            bar2.classList.add(classes.fadeBothSides)
                            bar2.classList.remove(classes.fadeRight)
                            bar2.classList.remove(classes.fadeLeft)
                            setScrollButtonsMobile({ left: true, right: true })
                        }
                    }
                }
            })
        })
    }, [])



    const showSearchBar = () => {
        if(checkIfSingleGrantView()){
            const temp = !state.showSearchBar
            actions.showSearchBar(temp)
        }
    }



    const scrollRight = (x) => {
        const slider = document.querySelectorAll('#scrollbar');
        Array.from(slider).map((s, k) => {
            s.scrollTo({ top: 0, left: s.scrollLeft + 150, behavior: 'smooth' })
        })
    }

    const scrollLeft = () => {
        const slider = document.querySelectorAll('#scrollbar');
        Array.from(slider).map((s, k) => {
            s.scrollTo({ top: 0, left: s.scrollLeft - 150, behavior: 'smooth' })
        })
    }

    const additionalFiltersToggle = () => {
        if(checkIfSingleGrantView()){
            actions.setAdditionalFilters({ flag: !state.additionalFilters.flag })
        }
    }

    const checkIfSingleGrantView = () => {
        if (singleGrantView && !state.currentUser.loggedIn) {
            console.log('here')
            setFilters({...filters, signInSignUp: !filters.signInSignUp})
            return false
        } 
        else {
            return true
        }
    }




    let backArrow, filterbar, headerPosition, minHeight
    if (filterBarDisplay) {
        backArrow = ' d-none '
        filterbar = ' d-flex d-lg-none'
        headerPosition = ''
        minHeight = '90px'
    } else if (singleGrantView) {
        backArrow = ' d-none '
        filterbar = ' d-none '
    }
    else {
        backArrow = ''
        filterbar = ' d-none '
        headerPosition = ' justify-content-center justify-content-lg-between '
        minHeight = ''
    }

    return (
        <div className={`container d-flex flex-column align-items-start flex-lg-row align-items-lg-end ` + classes.container} style={{ minHeight: minHeight }}>
            {filters.signInSignUp ? <PopupModal backgroundClicked={checkIfSingleGrantView} type="signInSignUp" /> : ''}
            <div className={classes.leftFilters + ' justify-content-lg-end'}>
                <div className={classes.leftFiltersBottom + ' ' + headerPosition}>
                    <div className={classes.leftFiltersTop + ' ' + backArrow + ' d-lg-none mr-1'}>
                        <CustomButton
                            onClick={() => actions.setGrantPicked({ picked: true, details: false })}
                            title={<svg width="1em" height="1em" viewBox="0 0 16 16" className={"bi bi-arrow-left " + classes.svg} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>}
                            css="clear"
                        >
                        </CustomButton>
                    </div>
                    <h2 className={'d-none d-lg-flex ' + classes.pageName}>{pageName} {state.grantCount !== 0 ? '(' + state.grantCount + ')' : ''}</h2>
                    <h2 style={state.grantDetails.details ? { paddingLeft: '20px' } : {}} className={'d-flex d-lg-none ' + classes.pageName}>{pageName} {state.grantCount !== 0 && !state.grantListLoading ? '(' + state.grantCount + ')' : ''}</h2>
                    <div className={classes.searchButtonContainer}>
                        <button className={classes.searchButton + ' clearButton'} onClick={() => showSearchBar()}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className={classes.placement + ' d-none d-lg-flex align-self-center'}>
                <div className={classes.lineBreak + ' d-none d-lg-flex '}> </div>
                <div className={classes.filterContainer} id="container1">
                    <div className={classes.rightFilters + ' scrollx d-none d-lg-flex '} id="scrollbar">
                        <ul className={classes.scrollBar} ref={scrollRef} >
                            {filters.rightFilters.map((i, k) => {
                                return (
                                    <li className={classes.scrollItem} key={k} onClick={(e) => checkIfSingleGrantView(e)}>
                                        <SetFilterOptions
                                            title={i.title}
                                            param={i.param}
                                            inputConfig={i.inputConfig}
                                            options={i.options}
                                            checkbox={i.checkbox}
                                            itemSelected={i.itemSelected}
                                            multiple={i.allowMultiple}
                                        />
                                    </li>
                                )
                            })}
                            <li className={classes.allFiltersBtn}>
                                <CustomButton title='All Filters' css='dark' onClick={() => additionalFiltersToggle()} />
                            </li>
                        </ul>
                    </div>
                </div>
                {scrollButton.left ?
                    <div className={classes.shiftLeft}>
                        <button className="clearButton" onClick={() => scrollLeft('d')} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </button>
                    </div> : ''}
                {scrollButton.right ?
                    <div className={classes.shiftRight}>
                        <button className="clearButton" onClick={() => scrollRight('d')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                    </div> : ''}
            </div>
            <div className={classes.placement + filterbar}>
                <div className={classes.filterContainer} id='container2'>
                    <div className={classes.mobileFilter + ' scrollx d-flex d-lg-none'} id="scrollbar" >
                        <ul className={classes.scrollBar} >
                            <li>
                                <CustomButton css='clear' onClick={() => additionalFiltersToggle()}>Filters <img src={filterIcon} /></CustomButton>
                            </li>
                        </ul>
                    </div>
                </div>
                {scrollButtonMobile.left ?
                    <div className={classes.shiftLeft}>
                        <button className="clearButton" onClick={() => scrollLeft('m')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </button>
                    </div> : ''}
                {scrollButtonMobile.right ?
                    <div className={classes.shiftRight}>
                        <button className="clearButton" onClick={() => scrollRight('m')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                    </div> : ''}
            </div>
        </div>
    )
}

export default FilterBar