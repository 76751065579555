import React from 'react'
import { withRouter } from 'react-router-dom'
import WufooFormTemplate from '../../../Components/UI/WufooForm/WufooForm'
import classes from './PublicForms.module.css'

import AlternateLayout from '../../../HOC/Layout/AlternateLayout/AlternateLayout'
import Footer from '../../../Components/Footer/Footer'

const ReferFriend = () => {

    return(
        <AlternateLayout>
            <div className={classes.container}>
                <div className={classes.content}>
                    <WufooFormTemplate type="refer-a-friend" />
                </div>
            </div>
            <Footer />
        </AlternateLayout>
    )
}

export default withRouter(ReferFriend)