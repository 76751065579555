import React from 'react'
import classes from './FooterCOA.module.css'

const FooterCOA = () => {
    return(
        <div className={classes.copyContainer}>
        <div className={classes.copyLinks}>
            <a href="https://www.termsfeed.com/live/9abcc86c-c28d-4463-8378-d2a94406e1cb">Privacy Policy</a>
            <a href="https://www.termsofusegenerator.net/live.php?token=mMW0qecwQIJUkX9tVcV5czxaHoawFP1I">Terms of Use</a>
        </div>
        <div className={classes.copywrite}>
            <p>&#169;2021 DeepND Inc</p>
        </div>
    </div>
    )


}

export default FooterCOA