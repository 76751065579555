import { Redirect, Route, Switch } from 'react-router-dom';
// import Layout from './HOC/Layout/Layout';
import AvailableGrants from './Containers/AvailableGrants/AvailableGrants';
import Auth from './Containers/Auth/Auth';
import Account from './Containers/Account/Account';
import SingleGrantView from './Containers/SingleGrantView/SingleGrantView';
import PrivateRoute from './HOC/PrivateRoute/PrivateRoute';
import LandingPage from './Containers/LandingPage/LandingPage';
import GetFunded from './Containers/GetFunded/GetFunded';
import Page404 from './Containers/404/Page404';
import HomePage from './Containers/HomePage/HomePage';
import FormComplete from './Containers/AlphaReferral/FormComplete/FormComplete';
import About from './Containers/About/About';
import GrantBasedFinancing from './Containers/SocialForms/PrivateForms/GrantBasedFinancing/GrantBasedFinancing';
import HireExpert from './Containers/SocialForms/PublicForms/HireExpert';
import PublicForms from './Containers/SocialForms/PublicForms/PublicForms';
import SemiPrivateRoute from './HOC/SemiPrivateRoute/SemiPrivateRoute';
import CheckBack from './Containers/AlphaReferral/CheckBack/CheckBack';
import CheckBackBetaConfirmed from './Containers/AlphaReferral/CheckBack/CheckBack-BetaConfirmed/CheckBack-BetaConfirmed';
import ReferFriend from './Containers/SocialForms/PublicForms/ReferFriend';

function App() {
    return (
        <Switch>
            {/* <Route path='/' exact component={LandingPage} /> */}
            <Route path="/" exact>
                <Redirect to="/auth/signin" />
            </Route>
            <PrivateRoute path="/home" component={HomePage} />
            <PrivateRoute path="/available-grants" component={() => <AvailableGrants savedGrants={false} pageName="Available Grants" />} />
            <PrivateRoute path="/saved-grants" component={() => <AvailableGrants savedGrants pageName="Saved Grants" />} />
            <SemiPrivateRoute path="/Auth/:path/:uid" component={Auth} />
            <SemiPrivateRoute path="/Auth/:path" component={Auth} />
            <SemiPrivateRoute path="/activate-account/:path/:token" component={Auth} />
            <SemiPrivateRoute path="/confirm-password-reset/:path/:token" component={Auth} />
            <PrivateRoute path="/account" component={Account} />
            <Route path="/grant/:grantID/:country" component={SingleGrantView} />
            <Route path="/grant" component={SingleGrantView} />
            <Route path="/form-complete/:path" component={FormComplete} />
            <Route path="/about" component={About} />
            <Route path="/get-funded" component={GetFunded} />
            <PrivateRoute path="/grant-based-financing" component={GrantBasedFinancing} />
            <Route path="/experts/:path" component={HireExpert} />
            <Route path="/info/:path" component={PublicForms} />
            <Route path="/checkback" component={CheckBack} />
            <Route path="/checkback-soon" component={CheckBackBetaConfirmed} />
            <Route path="/refer-your-friends" component={ReferFriend} />
            <Route path="*" component={Page404} />
        </Switch>
    );
}

export default App;
