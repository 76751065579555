import React, { useEffect, useState } from 'react'
import instance from '../../../Assets/Axios/AxiosInstance'
import CustomButton from '../../../Components/UI/CustomButton/CustomButton'
import CustomInput from '../../../Components/UI/CustomInputs/CustomInput'
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper'
import classes from './UserProfile.module.css'
import Loading from '../../../Components/UI/Loading/Loading'
import { Link } from 'react-router-dom'

const UserProfile = () => {
    const [profileState, setProfileState] = useState({
        form: {
            email: {
                type: 'email',
                label: "Email",
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                edit: false,
                value: '',
            },
            firstName: {
                type: 'text',
                label: "First Name",
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                id: 'fname',
                edit: false,
                value: ''
            },
            lastName: {
                type: 'text',
                label: "Last Name",
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                id: 'lname',
                edit: false,
                value: ''
            },
        },
        loading: true,
        editForm: false,
        formComplete: false
    })

    useEffect(() => {
        instance.get('/api/users/').then(res => {
            console.log(res)
            let data = { ...res.data.results[0] }
            setProfileState(old => {
                let oldstate = { ...old }
                oldstate.form.email.value = data.email
                oldstate.form.firstName.value = data.first_name
                oldstate.form.lastName.value = data.last_name
                let backupform = deconstructObject(oldstate.form)
                oldstate.backupForm = { ...backupform }
                oldstate.loading = false
                return oldstate
            })
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const deconstructObject = (obj) => {
        let newObj = new Object
        Object.keys(obj).map((i) => {
            newObj[i] = { ...obj[i] }
        })
        return newObj
    }


    const formChange = (e, i) => {
        if (e instanceof Date) {
            setProfileState(old => {
                let temp = { ...old }
                temp.form[i].elementConfig.selected = e
                return temp
            })
        }
        else {
            e.persist()
            setProfileState(old => {
                let temp = { ...old }
                temp.form[i].value = e.target.value
                return temp
            })
        }
    }


    const editForm = () => {
        setProfileState(old => {
            let temp = { ...old }
            Object.keys(temp.form).forEach(i => {
                temp.form[i].edit = true
            })
            temp.editForm = true
            return temp
        })
    }

    const cancelForm = () => {
        setProfileState(old => {
            let temp = { ...old }
            Object.keys(temp.form).forEach(i => {
                temp.form[i].edit = false
            })
            temp.form = temp.backupForm
            temp.editForm = false
            return temp
        })
    }

    const formSavedSuccessfully = () => {
        setProfileState(old => {
            let temp = { ...old }
            Object.keys(temp.form).forEach(i => {
                temp.form[i].edit = false
            })
            temp.editForm = false
            return temp
        })
    }


    const saveForm = (e) => {
        e.preventDefault()
        let temp = profileState.form

        let formdata = new FormData()
        formdata.append('first_name', temp['firstName'].value)
        formdata.append('last_name', temp['lastName'].value)
        formdata.append('email', temp['email'].value)

        instance.patch('/api/user', formdata).then(res => {
            console.log('success', res)
            formSavedSuccessfully()
        }).catch(err => {
            console.log(err.response)
        })
    }

    return (
        <form className={classes.form}>
            {profileState.loading ? <div className={classes.loading}><Loading /><p>Please wait...</p></div> : <>
                <div className={classes.header}>
                    <h2>Account</h2>
                </div>
                <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
                <Link className={classes.changePasswordLink} to="/account/change-password">Change Password</Link> 
                <div className={classes.formButtons}>
                    {/* {profileState.editForm ?
                    <><CustomButton title="Save" css="dark-purple" onClick={(e) => saveForm(e)} /> <CustomButton title='Cancel' css='white' onClick={() => cancelForm()} /></> :
                    <CustomButton title="Edit" css="dark-purple" onClick={() => editForm()} />
                } */}
                </div>
            </>}
        </form>
    )
}

export default UserProfile