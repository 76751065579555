/*
 * DropDownSelections.js
 *
 * This file contains arrays that populate the dropdown fields used in account creation,
 * located in GrantProfileCreationFormElements.js. Note that not all fields are filled
 * using these arrays - some are populated using setDropdownOptions() located in 
 * GrantProfileFormFunctions which makes a call to /dropdowns/ instead. To change those dropdowns,
 * go to backend/models/choices/
 * 
 * NOTE: Options set in backend/models/choices must then be set in the Django admin page
 * (https://hellopocketed.io/admin/)
 */

// Used by incorporated field
export const incorporated = [
    { label: 'Incorporated', value: true },
    { label: 'Unincorporated', value: false },
]

// Used by company size field
export const companySize = [
    { label: "Under 10 Employees", value: 10 },
    { label: "11 - 50 Employees", value: 50 },
    { label: "50 - 75 Employees", value: 75 },
    { label: "76 - 100 Employees", value: 100 },
    { label: "101 - 150 Employees", value: 150 },
    { label: "151 - 200 Employees", value: 200 },
    { label: "201 - 500 Employees", value: 500 },
    { label: "Over 500 Employees", value: 501 }
]

// Used by the following fields:
// raisedCapitol, accessedFunding, cashRestraint, sredSubmitted, sredConsultant, sredFuture,
// engageRD, taxCredits,
export const yesNoCheck = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

// Only used by nonDilutive field
export const heckYesCheck = [
    { label: 'Heck Yes', value: true },
    { label: 'Not Yet', value: false }
]

/*
export const countryAbb = [
    { label: 'Canada', value: 'CA' },
    { label: 'United States of America', value: 'US' }
]
*/

/*
export const businessTypes = [
    'For-profit',
    'Non-profit',
    'Charity',
    'Government Institution',
    'Test Option'
]
*/