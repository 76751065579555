import React, { useContext } from 'react'
import Footer from '../../../Components/Footer/Footer'
import PopupModal from '../../../Components/UI/PopUps/PopupModal'
import { StoreContext } from '../../../Store/StoreContext'
import classes from './AlternateLayout2.module.css'

const AlternateLayout2 = ({ children }) => {
    const { state, actions } = useContext(StoreContext)

    const backgroundClicked = () => {
        actions.setPopup({flag: false, message: ''})
    }
    return (
        <div className={classes.container} id='altLayout2'>
            {state.popup.flag ? <PopupModal backgroundClicked={backgroundClicked} type='error'/> : ''}
            <div className={classes.content}>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default AlternateLayout2