import React, { useEffect, useRef, useState } from 'react'
import { formatDate } from '../../../../Assets/Functions/DateFormater'
import CustomButton from '../../../../Components/UI/CustomButton/CustomButton'
import logo from '../../../../Assets/Images/Clearco/CLEARCO_BLACK-WORDMARK.png'
import pocketedlogo from '../../../../Assets/Images/logo.png'
import classes from '../GrantProfileForm.module.css'
import { withRouter } from 'react-router-dom'
import InputArrayMapper from '../../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper'
import Loading from '../../../../Components/UI/Loading/Loading'
import StopInteractionBackground from '../../../../HOC/StopInteractionBackground/StopInteractionBackground'
import instance from '../../../../Assets/Axios/AxiosInstance'
import { ClearCONoGPFormElements } from '../../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileCreationFormElements'
import { Mixpanel } from '../../../../Assets/Functions/MixPanel/MixPanel'
import { checkPassword } from '../../../../Assets/Functions/ProfileCreation'
import { checkRequiredFunction, formChangeFunction, goToPageFunction, setDropdownOptions } from '../../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileFormFunctions'

// user/grant profile creation form for Clearco users first sign in, attaches no flag to user upon creation
const GrantProfileForm = (props) => {

    const [profileState, setProfileState] = useState({
        ...ClearCONoGPFormElements(),
        formInputUpperLimit: 5,
        formInputLowerLimit: 0,
        page: 1,
        firstUpperLimit: 5,
        secondUpperLimit: 19,
        thirdUpperLimit: 1000
    })
    const pageRef = useRef()
    const errorRef = useRef()


    useEffect(() => {

        // instance.get('/user/').then(res => {
        //     if ('isAuthenticated' in res.data && !res.data.isAuthenticated) {
        //         props.history.push('/auth/signin')
        //     }
        //     if ('username' in res.data) {
        //         console.log(res)
        //         Mixpanel.identify(res.data.username)
        //     }
        //     console.log(res.data)
        // }).catch(err => {
        //     console.log(err)
        // })

        // gets dropdown options from backend
        instance.get('/dropdowns/').then(r => {
            console.log(r.data)
            setDropdownOptions(r, setProfileState)
        })

    }, [])

    const formChange = formChangeFunction(setProfileState)
    const checkRequired = checkRequiredFunction(profileState)
    const goToPage = goToPageFunction(profileState, setProfileState)

    // changes page if not on page 3, otherwise submits form
    const formSubmit = (e) => {
        e.preventDefault()
        if (profileState.page == 1) {
            let res = checkPassword(profileState.form.password.value, profileState.form.confirmPassword.value)
            if (res.flag) {
                console.log(res)
                setProfileState(old => {
                    let temp = {...old}
                    temp.form.password.hint = <p ref={errorRef} className={classes.err}>{res.msg}</p>
                    if (errorRef.current) {
                        errorRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                    return temp
                })
            } else {
                let body = new FormData()
                body.append('old_password', "ChangeMe!")
                body.append('new_password1', profileState.form['password'].value)
                body.append('new_password2', profileState.form['password'].value)
                body.append('first_name', profileState.form['firstName'].value)
                body.append('last_name', profileState.form['lastName'].value)

                instance.post('/accounts/name_password_change/', body).then(res => {
                    console.log(res)
                    setProfileState(old => {
                        let temp = {...old}
                        temp.page = 2
                        temp.formInputUpperLimit = profileState.secondUpperLimit
                        temp.formInputLowerLimit = profileState.firstUpperLimit
                        temp.form.password.hint = ''
                        if (pageRef.current) {
                            pageRef.current.scrollIntoView({
                                behavior: "smooth",
                            })
                        }
                        return temp
                    })
                }).catch(err => {
                    console.log(err)
                })
            }

        } else if (profileState.page == 2) {
            setProfileState(old => {
                let temp = { ...old }
                let res = checkRequired(temp.form)
                if (res.flag) {
                    console.log(res)
                    temp.form[res.ele].error = <p ref={errorRef} className={classes.err}>This field is required</p>
                    if (errorRef.current) {
                        // let parentEle = document.getElementById('altLayout')
                        errorRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                } else {
                    temp.page = 3
                    temp.formInputUpperLimit = profileState.thirdUpperLimit
                    temp.formInputLowerLimit = profileState.secondUpperLimit
                    if (pageRef.current) {
                        pageRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                }
                return temp
            })
        }
        else {
            setProfileState(old => {
                let res = { ...old }
                res.loading = true
                return res
            })
            let temp = profileState.form
            let grantprofile = new Object()
            setProfileState(old => {
                let res = { ...old }
                res.loading = true
                res.gpCreating = true
                return res
            })
            Object.keys(temp).forEach((i) => {
                if (temp[i].value instanceof Date) {
                    grantprofile[temp[i].entryValue] = formatDate(temp[i].value)
                } else if (temp[i].show !== undefined && !temp[i].show) {
                    // do nothing unless awardedTypes is the input, in that case assign an empty array
                    if (i == 'awardedTypes') {
                        grantprofile[temp[i].entryValue] = []
                    }
                }
                else if (temp[i].multiple) {
                    grantprofile[temp[i].entryValue] = temp[i].value.map(i => i.value)
                } else if (temp[i].entryValue === undefined) {

                }
                else {
                    grantprofile[temp[i].entryValue] = temp[i].value
                }

            })

            instance.post('/api/grantprofiles/', grantprofile).then(res => {
                console.log(res.response)
                Mixpanel.track('Grant Profile Created', grantprofile)
                props.history.push('/home')
            }).catch(err => {
                Mixpanel.track('Grant Profile Creation Failed', grantprofile)
                console.log(err.response)
                props.history.push('/home')
                setProfileState(old => {
                    let res = { ...old }
                    res.loading = true
                    return res
                })
            })
            // let temp = profileState.form
            // let grantprofile = new Object()

            // Object.keys(temp).forEach((i, k) => {
            //     if (k > firstUpperLimit) {
            //         if (temp[i].value instanceof Date) {
            //             grantprofile[temp[i].entryValue] = formatDate(temp[i].value)
            //         } else if (temp[i].show !== undefined && !temp[i].show) {
            //             // do nothing unless awardedTypes is the input, in that case assign an empty array
            //             if (i == 'awardedTypes') {
            //                 grantprofile[temp[i].entryValue] = []
            //             }
            //         }
            //         else if (temp[i].multiple) {
            //             grantprofile[temp[i].entryValue] = temp[i].value.map(i => i.value)
            //         } else if (temp[i].entryValue === undefined) {

            //         }
            //         else {
            //             grantprofile[temp[i].entryValue] = temp[i].value
            //         }
            //     }
            // })
            // let timeout
            // instance.post('/api/grantprofiles/', grantprofile).then(res => {
            //     console.log(res.response)
            //     Mixpanel.track('Grant Profile Created', grantprofile)
            //     console.log('success!')
            //     // props.history.push('/home')
            // }).catch(err => {
            //     Mixpanel.track('Grant Profile Creation Failed', grantprofile)
            //     console.log(err.response)
            //     // console.log('failure!', body)
            //     clearTimeout(timeout)
            //     setProfileState({ ...profileState, gpCreationFailed: true, loading: false })
            //     // props.history.push('/home')
            // })

            // timeout = setTimeout(() => {
            //     setProfileState(old => {
            //         let res = { ...old }
            //         res.loading = false
            //         res.formFinished = true
            //         return res
            //     })
            // }, 10000)
        }
    }


    let buttons;
    // determines which buttons to show based on page
    if (profileState.page == 1) {
        buttons = <div className={classes.firstFormPageBtn}>
            <CustomButton css="light-blue" title="Next" type="submit" />
        </div>
    } else if (profileState.page == 2) {
        buttons = <div className={classes.secondFormPageBtn}>
            <a className={"light-blue"} onClick={() => goToPage(1)}>Previous</a>
            <CustomButton css="light-blue" title="Next" type="submit" />
        </div>
    } else {
        buttons = <div className={classes.thirdFormPageBtn}>
            <a className={"light-blue"} onClick={() => goToPage(2)}>Previous</a>
            <CustomButton css="dark-blue" title="Create Your Account" type="submit" />
        </div>
    }

    return (
        <form className={classes.form} onSubmit={(e) => formSubmit(e)} ref={pageRef}>
            {profileState.gpCreating ? <StopInteractionBackground /> : ''}
            {profileState.loading ? <div className={classes.loading}><Loading /><p>Sit tight! Here comes the cash &#128176;</p></div> : <>
                <div className={classes.logoContainer}>
                    <a href='/'><img className={classes.img} src={pocketedlogo} /></a>
                    <p>&</p>
                    <a href='/'><img className={classes.img} src={logo} /></a>
                </div>
                {profileState.formFinished ? <>
                    <div className={classes.successMessage}>Grant funding awaits! To pocket it, <b>check your email</b> to confirm your account.</div></> : <>
                    <div className={classes.header}>
                        <h2>Complete Your Profile</h2>
                        <p>Takes less than 3 mins</p>
                    </div>
                    <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
                    <div className={classes.bottomContainer}>
                        {profileState.page !== 1 ? <p className={classes.requiredEx}>* required</p> : ''}
                        <div className={classes.formButtons}>
                            {buttons}
                        </div>
                        <p>Page {profileState.page} of  {profileState.freeAccount ? 3 : 4}</p>
                    </div>
                </>}
            </>}
        </form>
    )
}

export default withRouter(GrantProfileForm)