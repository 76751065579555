import React from 'react'
import { DropdownButton } from 'react-bootstrap'
import classes from './CustomDropDown.module.css'

const CustomDropDown = ({ children, type, title, css }) => {

    return (
        <DropdownButton className={classes.button + ' ' + css} title={title} >
            {children}
        </DropdownButton>
    )
}

export default CustomDropDown