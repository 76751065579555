import React from 'react'
import classes from './CrossAndBlobLayout.module.css'
import crossesBlue from '../../../Assets/Images/crossesBlue.png'
import crossesWhite from '../../../Assets/Images/crossesWhite.png'
import blobTopRight from '../../../Assets/Images/blobTopRight.png'
import blobBottomLeft from '../../../Assets/Images/blobBottomLeft.png'
import AltNav2 from '../../../Components/Navigation/AlternateNavBar2/AltNav2'
// import Footer from '../../../Components/Footer/Footer'


const CrossAndBlobLayout = ({ children }) => {
    return (
        <div className={classes.container}>
            <AltNav2 />
            <img className={classes.crossBlue} src={crossesBlue} />
            <img className={classes.crossWhite} src={crossesWhite} />
            <img className={classes.blobBottomLeft} src={blobBottomLeft} />
            <img className={classes.blobTopRight} src={blobTopRight} />
            <div className={classes.dummy}></div>
            {children}
        </div>
    )
}

export default CrossAndBlobLayout