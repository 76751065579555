import React, { useEffect, useRef, useState, useContext } from 'react';
import CustomButton from '../../../Components/UI/CustomButton/CustomButton';
import logo from '../../../Assets/Images/logo.png';
import classes from './GrantProfileForm.module.css';
import instance from '../../../Assets/Axios/AxiosInstance';
import { withRouter } from 'react-router-dom';
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper';
import { GrantProfileCreationFormElements } from '../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileCreationFormElements';
import { formatDate } from '../../../Assets/Functions/DateFormater';
import Loading from '../../../Components/UI/Loading/Loading';
import StopInteractionBackground from '../../../HOC/StopInteractionBackground/StopInteractionBackground';
import { Mixpanel } from '../../../Assets/Functions/MixPanel/MixPanel';
import { checkPassword } from '../../../Assets/Functions/ProfileCreation';
import { checkRequiredFunction, formChangeFunction, goToPageFunction, setDropdownOptions } from '../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileFormFunctions';
import { StoreContext } from '../../../Store/StoreContext';
import { cleanData } from '../../../Assets/Functions/MixPanelSanitizer';

// Standard user/grant profile creation form, attaches no flag to user upon creation, currently not in use
const GrantProfileForm = () => {
    const { actions, state } = useContext(StoreContext);

    const [profileState, setProfileState] = useState({
        ...GrantProfileCreationFormElements,
        formInputUpperLimit: 8,
        formInputLowerLimit: 0,
        page: 1,
        loading: true,
        gpCreating: false,
        gpCreationFailed: false,
        formFinished: false,
        firstUpperLimit: 8,
        secondUpperLimit: 20,
        thirdUpperLimit: 1000,
    });
    const pageRef = useRef();
    const errorRef = useRef();

    useEffect(() => {
        instance.get('/dropdowns/').then(r => {
            setDropdownOptions(r, setProfileState);
        });
    }, []);

    const formChange = formChangeFunction(setProfileState);
    const checkRequired = checkRequiredFunction(profileState);
    const goToPage = goToPageFunction(profileState, setProfileState);

    // changes page if not on page 3, otherwise submits form
    const formSubmit = e => {
        e.preventDefault();
        if (profileState.page == 1) {
            let formdata = new FormData();
            formdata.append('email', profileState.form['email'].value);
            instance
                .post('/user-exists/', formdata)
                .then(res => {
                    setProfileState(old => {
                        let temp = { ...old };
                        let res = checkPassword(temp.form.password.value, temp.form.confirmPassword.value);
                        if (res.flag) {
                            temp.form.password.hint = (
                                <p ref={errorRef} className={classes.err}>
                                    {res.msg}
                                </p>
                            );
                            if (errorRef.current) {
                                errorRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }
                        } else {
                            temp.page = 2;
                            temp.formInputUpperLimit = profileState.secondUpperLimit;
                            temp.formInputLowerLimit = profileState.firstUpperLimit;
                            temp.form.password.hint = '';
                            if (pageRef.current) {
                                pageRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }
                        }
                        return temp;
                    });
                })
                .catch(err => {
                    // console.log(err.response.data['user-exists'])
                    if ('user-exists' in err.response.data && err.response.data['user-exists']) {
                        setProfileState(old => {
                            let res = { ...old };
                            res.form['email'].error = (
                                <p ref={errorRef} className={classes.err}>
                                    This email is already registered with our platform
                                </p>
                            );
                            return res;
                        });
                    }
                });
        } else if (profileState.page == 2) {
            setProfileState(old => {
                let temp = { ...old };
                let res = checkRequired(temp.form);
                if (res.flag) {
                    temp.form[res.ele].error = (
                        <p ref={errorRef} className={classes.err}>
                            This field is required
                        </p>
                    );
                    if (errorRef.current) {
                        // let parentEle = document.getElementById('altLayout')
                        errorRef.current.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                } else {
                    temp.page = 3;
                    temp.formInputUpperLimit = profileState.thirdUpperLimit;
                    temp.formInputLowerLimit = profileState.secondUpperLimit;
                    if (pageRef.current) {
                        pageRef.current.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                }
                return temp;
            });
        } else {
            setProfileState(old => {
                let res = { ...old };

                res.loading = true;
                return res;
            });

            let temp = profileState.form;
            let res = checkRequired(temp);
            if (res.flag) {
                setProfileState(old => {
                    let res = { ...old };
                    res.loading = false;
                    return res;
                });

                temp[res.ele].error = (
                    <p ref={errorRef} className={classes.err}>
                        This field is required
                    </p>
                );
                if (errorRef.current) {
                    // let parentEle = document.getElementById('altLayout')
                    errorRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            } else {
                let body = new Object();
                let user = new Object();
                let grantprofile = new Object();
                Object.keys(temp).forEach((i, k) => {
                    console.log(i, k);
                    if (k < 6) {
                        user[temp[i].entryValue] = temp[i].value;
                    } else if (k > 6) {
                        if (temp[i].value instanceof Date) {
                            grantprofile[temp[i].entryValue] = formatDate(temp[i].value);
                        } else if (temp[i].show !== undefined && !temp[i].show) {
                            // do nothing unless awardedTypes is the input, in that case assign an empty array
                            if (i == 'awardedTypes') {
                                grantprofile[temp[i].entryValue] = [];
                            }
                        } else if (temp[i].multiple) {
                            grantprofile[temp[i].entryValue] = temp[i].value.map(i => i.value);
                        } else if (temp[i].entryValue === undefined) {
                        } else {
                            grantprofile[temp[i].entryValue] = temp[i].value;
                        }
                    }
                });
                user['username'] = temp.email.value;
                body['grantprofile'] = { ...grantprofile, referral_code: user.referral_code };
                body['user'] = user;
                // let timeout;
                instance
                    .post('/api/create-profile/', body)
                    .then(res => {
                        Mixpanel.track('Grant Profile Created', cleanData(body));
                        // clearTimeout(timeout)
                        actions.setAuthFailedState(false);
                        setProfileState({ ...profileState, loading: false, formFinished: true });
                    })
                    .catch(err => {
                        Mixpanel.track('Grant Profile Creation Failed', cleanData(body));
                        // clearTimeout(timeout)
                        actions.setAuthFailedState(true);
                        setProfileState({ ...profileState, gpCreationFailed: true, loading: false });
                    });

                // timeout = setTimeout(() => {
                //     actions.setAuthFailedState(true)
                //     setProfileState({ ...profileState, gpCreationFailed: true, loading: false })
                // }, 10000)
            }
        }
    };

    let buttons;
    // determines which buttons to show based on page
    if (profileState.page == 1) {
        buttons = (
            <div className={classes.firstFormPageBtn}>
                <CustomButton css="light-blue" title="Next" type="submit" />
            </div>
        );
    } else if (profileState.page == 2) {
        buttons = (
            <div className={classes.secondFormPageBtn}>
                <a className={'light-blue'} onClick={() => goToPage(1)}>
                    Previous
                </a>
                <CustomButton css="light-blue" title="Next" type="submit" />
            </div>
        );
    } else {
        buttons = (
            <div className={classes.thirdFormPageBtn}>
                <a className={'light-blue'} onClick={() => goToPage(2)}>
                    Previous
                </a>
                <CustomButton css="dark-blue" title="Create Your Account" type="submit" />
            </div>
        );
    }

    return (
        <form className={classes.form} onSubmit={e => formSubmit(e)} ref={pageRef}>
            {profileState.gpCreating ? <StopInteractionBackground /> : ''}
            {profileState.loading ? (
                <div className={classes.loading}>
                    <Loading />
                    <p>Sit tight! Here comes the cash &#128176;</p>
                </div>
            ) : (
                <>
                    <a href="/">
                        <img className={classes.img} src={logo} />
                    </a>
                    {profileState.formFinished ? (
                        <>
                            <div className={classes.successMessage}>
                                Grant funding awaits!
                                <br /> To access your Pocketed, check your email to verify your address
                                <br />
                                <br /> Can't find the email? Be sure to check your <b>spam</b> or <b>junk</b> folder!
                                <br />
                                <br /> Still can't find it?{' '}
                                <CustomButton css="light-blue" title="" type="submit">
                                    <a style={{ color: 'inherit' }} href="https://pocketed.zendesk.com/hc/en-us/requests/new">
                                        Click Here for help!
                                    </a>
                                </CustomButton>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.header}>
                                <h2>Complete Your Profile</h2>
                                <p>Takes less than 3 mins!</p>
                            </div>
                            <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
                            <div className={classes.bottomContainer}>
                                {profileState.page !== 1 ? <p className={classes.requiredEx}>* required</p> : ''}
                                <div className={classes.formButtons}>{buttons}</div>
                                <p>
                                    Page {profileState.page} of {profileState.freeAccount ? 3 : 4}
                                </p>
                            </div>
                        </>
                    )}
                </>
            )}
        </form>
    );
};

export default withRouter(GrantProfileForm);
