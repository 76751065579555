import React, { useEffect, useRef, useState } from 'react'
import CustomButton from '../../../../Components/UI/CustomButton/CustomButton'
import logo from '../../../../Assets/Images/logo.png'
import classes from '../GrantProfileForm.module.css'
import instance from '../../../../Assets/Axios/AxiosInstance'
import { withRouter } from 'react-router-dom'
import InputArrayMapper from '../../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper'
import { AlphaNoGPGrantFormElements } from '../../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileCreationFormElements'
import { formatDate } from '../../../../Assets/Functions/DateFormater'
import Loading from '../../../../Components/UI/Loading/Loading'
import StopInteractionBackground from '../../../../HOC/StopInteractionBackground/StopInteractionBackground'
import { Mixpanel } from '../../../../Assets/Functions/MixPanel/MixPanel'
import PopupModal from '../../../../Components/UI/PopUps/PopupModal'
import { setDropdownOptions, formChangeFunction, checkRequiredFunction, goToPageFunction } from '../../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileFormFunctions'

// Grant profile creation form for users with an account but no GP, should not be releveant any longer
const GrantProfileFormAlphaNoGP = (props) => {

    const [profileState, setProfileState] = useState({
        ...AlphaNoGPGrantFormElements(),
        formInputUpperLimit: 13,
        formInputLowerLimit: 0,
        page: 1,
        firstUpperLimit: 13,
        secondUpperLimit: 1000
    })
    const pageRef = useRef()
    const errorRef = useRef()


    useEffect(() => {

        // gets dropdown options from backend
        instance.get('/dropdowns/').then(r => {
            // console.log(r.data)
            setDropdownOptions(r, setProfileState)
        })

    }, [])

    

    const formChange = formChangeFunction(setProfileState)
    const checkRequired = checkRequiredFunction(profileState)
    const goToPage = goToPageFunction(profileState, setProfileState)

    // changes page if not on page 3, otherwise submits form
    const formSubmit = (e) => {
        e.preventDefault()
        if (profileState.page == 1) {
            setProfileState(old => {
                let temp = { ...old }
                let res = checkRequired(temp.form)
                if (res.flag) {
                    console.log(res)
                    temp.form[res.ele].error = <p ref={errorRef} className={classes.err}>This field is required</p>
                    if (errorRef.current) {
                        // let parentEle = document.getElementById('altLayout')
                        errorRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                } else {
                    temp.page = 3
                    temp.formInputUpperLimit = profileState.secondUpperLimit
                    temp.formInputLowerLimit = profileState.firstUpperLimit
                    if (pageRef.current) {
                        pageRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                }
                return temp
            })
        }
        else {
            setProfileState(old => {
                let res = { ...old }
                res.loading = true
                return res
            })
            let temp = profileState.form
            let grantprofile = new Object()
            // setProfileState(old => {
            //     let res = { ...old }
            //     res.loading = true
            //     res.gpCreating = true
            //     return res
            // })
            Object.keys(temp).forEach((i) => {
                if (temp[i].value instanceof Date) {
                    grantprofile[temp[i].entryValue] = formatDate(temp[i].value)
                } else if (temp[i].show !== undefined && !temp[i].show) {
                    // do nothing unless awardedTypes is the input, in that case assign an empty array
                    if (i == 'awardedTypes') {
                        grantprofile[temp[i].entryValue] = []
                    }
                }
                else if (temp[i].multiple) {
                    grantprofile[temp[i].entryValue] = temp[i].value.map(i => i.value)
                } else if (temp[i].entryValue === undefined) {

                }
                else {
                    grantprofile[temp[i].entryValue] = temp[i].value
                }

            })

            instance.post('/api/grantprofiles/', grantprofile).then(res => {
                console.log(res.response)
                Mixpanel.track('Grant Profile Created', grantprofile)
                props.history.push('/home')
            }).catch(err => {
                Mixpanel.track('Grant Profile Creation Failed', grantprofile)
                console.log(err.response)
                props.history.push('/home')
                setProfileState(old => {
                    let res = { ...old }
                    res.loading = true
                    return res
                })
            })
        }
    }


    let buttons;
    // determines which buttons to show based on page
    if (profileState.page == 1) {
        buttons = <div className={classes.firstFormPageBtn}>
            <CustomButton css="light-blue" title="Next" type="submit" />
        </div>
    } else {
        buttons = <div className={classes.thirdFormPageBtn}>
            <a className={"light-blue"} onClick={() => goToPage(1)}>Previous</a>
            <CustomButton css="dark-blue" title="Create Your Account" type="submit" />
        </div>
    }

    return (
        <form className={classes.form} onSubmit={(e) => formSubmit(e)} ref={pageRef}>
            {profileState.gpCreating ? <StopInteractionBackground /> : ''}
            {profileState.loading ? <div className={classes.loading}><Loading /><p>Sit tight! Here comes the cash &#128176;</p></div> : <>
                <a href='/'><img className={classes.img} src={logo} /></a>
                    <div className={classes.header}>
                        <h2>Complete Your Profile</h2>
                    </div>
                    <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
                    <p className={classes.requiredEx}>* required</p>
                    <div className={classes.formButtons}>
                        {buttons}
                    </div>
                    <p>Page {profileState.page} of 2</p>
            </>}
        </form>
    )
}

export default withRouter(GrantProfileFormAlphaNoGP)