import filterOptions from "./FilterOptions"


export const setFilterParams = (keyArray, state) => {
    let selections = { ...state.filterSelection.selected }

    // Filtering
    let subFilterResult = keyArray.map(i => {
        if (selections[i].length !== 0) {
            let res
            if (selections[i] == 'Cash upfront required' || selections[i] == 'New Grants') {
                res = i + '=True'
            } else if (selections[i] == 'Cash upfront not required') {
                res = i + '=False'
            } else if(selections[i] == 'All Grants'){

            } 
            else {
                res = i + '=' + selections[i]
            }
            return res
        }
    }).filter(i => i !== undefined).join('&')

    // subFilterResult = subFilterResult.filter(i => i !== undefined)
    // subFilterResult = subFilterResult.join('&')
    // console.log(subFilterResult)
    // Pagination
    if(state.filterSelection.page !== 1){
        subFilterResult += '&page=' + state.filterSelection.page
    }

    // Search
    if(state.filterSelection.search !== ''){
        subFilterResult += '&searchInput=' + state.filterSelection.search
    }

    if (state.filterSelection.type !== 'All' && state.filterSelection.type !== 'RAndD') {
        return 'grantTypes=' + filterOptions[state.filterSelection.type].title + '&' + subFilterResult
    } else if(state.filterSelection.type == 'RAndD'){
        return 'grantTypes=' + 'Research %26 Development&' + subFilterResult
    } else {
        return subFilterResult
    }
}