import crypto from 'crypto';

const ALGORITHM = 'aes-256-cfb';
const KEY = process.env.REACT_APP_URL_SECRET;

const encrypt = (param) => {
    const hash = crypto.createHash('sha256');
    hash.update(KEY);
    const keyBytes = hash.digest();
    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv(ALGORITHM, keyBytes, iv);
    let enc = [iv, cipher.update(param.toString(), 'utf8')];
    enc.push(cipher.final());

    return encodeURIComponent(Buffer.concat(enc).toString('base64'));
}

const decrypt = (param) => {
    const hash = crypto.createHash('sha256');
    hash.update(KEY);
    const keyBytes = hash.digest();

    const contents = Buffer.from(decodeURIComponent(param), 'base64');
    const iv = contents.slice(0, 16);
    const textBytes = contents.slice(16);
    const decipher = crypto.createDecipheriv(ALGORITHM, keyBytes, iv);
    let res = decipher.update(textBytes, '', 'utf8');
    res += decipher.final('utf8');
    return res;
}

export { encrypt, decrypt }