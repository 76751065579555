import React, { useContext } from 'react'
import AltNav from '../../../Components/Navigation/AlternateNavBar/AltNav'
import PopupModal from '../../../Components/UI/PopUps/PopupModal'
import { StoreContext } from '../../../Store/StoreContext'
import classes from './AlternateLayout.module.css'

const AlternateLayout = ({ children }) => {
    const {state, actions} = useContext(StoreContext)

    const backgroundClicked = () => {
        actions.setPopup({flag: false, message: ''})
    }

    return (
        <div className={classes.container} id='altLayout'>
            <AltNav />
            {state.popup.flag ?
                <PopupModal backgroundClicked={backgroundClicked} type='error'/> : ''}
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}

export default AlternateLayout