import React from 'react'
import { NavLink, Route, Switch, withRouter } from 'react-router-dom'
import Layout from '../../HOC/Layout/Layout'
import classes from './Account.module.css'
import ChangePassword from './ChangePassword/ChangePassword'
import GrantProfile from './GrantProfile/GrantProfile'
import UserProfile from './UserProfile/UserProfile'


const Account = () => {

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.accountNav}>
                    <div className={classes.header}>
                        <h2>Account Management</h2>
                    </div>
                    <div className={classes.options}>
                        <NavLink to='/account' exact activeClassName={classes.active}>
                            Account
                        </NavLink>
                        <NavLink to='/account/business-profile' activeClassName={classes.active}>
                            Grant Profile
                        </NavLink>
                    </div>
                </div>
                <div className={classes.accountDetails}>
                    <div className={classes.formContainer}>
                        <Switch>
                            <Route path='/account/business-profile' component={GrantProfile} />
                            <Route path='/account/change-password' component={ChangePassword} />
                            <Route path='/account' exact component={UserProfile} />
                        </Switch>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default withRouter(Account)