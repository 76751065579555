import React, { useContext, useEffect, useState } from 'react'
import instance from '../../../../Assets/Axios/AxiosInstance'
import { displayDeadline } from '../../../../Assets/Functions/DateFormater'
import { Mixpanel } from '../../../../Assets/Functions/MixPanel/MixPanel'
import { selectProfileImage } from '../../../../Assets/Functions/ProfileImagePicker'
import { StoreContext } from '../../../../Store/StoreContext'
import classes from './GrantListItem.module.css'


// props
// {
//     title: String,
//     subTitle: String,
//     amount: Int/String?,
//     type: String,
//     expiroryDate: Date/String?,
//     profileImage: String
//     new: Bool
//     clicked: fun
//     selected: bool
// }


const GrantListItem = (props) => {
    const { actions, state } = useContext(StoreContext)
    const [grantItem, setGrantItem] = useState({
        title: props.title,
        subTitle: props.subTitle,
        amount: props.amount,
        type: props.type,
        expiroryDate: props.expiroryDate,
        profileImage: props.image,
        new: props.new,
        selected: false,
        saved: props.saved,
        liked: props.liked
    })

    useEffect(() => {
        setGrantItem(old => {
            let res = {...old}
            res.liked = props.liked
            res.saved = props.saved
            return res
        })
    }, [props.saved, props.liked])

    let style1 = grantItem.new ? classes.new : classes.seen
    let style2 = grantItem.selected ? classes.selected : ''
    let style3 = grantItem.liked == 0 ? classes.thumbsDown : ''

    const bookMarkClick = (e) => {
        e.stopPropagation()
        instance.patch('/api/matches/' + props.id + '/', { saved: !grantItem.saved }).then(res => {
            console.log(res)
            actions.setGrantSaved(res.data)
            Mixpanel.track('Grant Saved', {
                grant_name: res.data.grant.name
            })
            setGrantItem(old => {
                let temp = {...old}
                temp.saved = res.data.saved
                return temp
            })
        }).catch(err => {
            console.log(err)
        })
    }
    
    let deadline = displayDeadline(props.expiroryDate)
    let image = selectProfileImage(props.title)

    const itemPicked = () => {
        actions.setGrantPicked({ picked: true, details: state.grantList[props.theKey], selected: props.theKey })
        if(grantItem.new){
            setGrantItem(old => {
                let temp = {...old}
                temp.new = false
                return temp
            })
            instance.patch('/api/matches/' + props.id + '/', { new: false }).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        if(state.grantDetails.selected == props.theKey && state.grantDetails.picked){
            setGrantItem(old => {
                let temp = {...old}
                temp.selected = true
                return temp
            })
        } else if(grantItem.selected){
            setGrantItem(old => {
                let temp = {...old}
                temp.selected = false
                return temp
            })
        }

    }, [state.grantDetails.selected, state.grantDetails.picked])

    return (
        <div className={classes.item + ' ' + style2 + ' ' + style3} onClick={() => itemPicked()}>
            <div className={classes.itemLeft}>
                <div className={classes.itemTop}>
                    <img src={image} />
                    <div className={classes.itemTitle}>
                        <h3>{grantItem.title}</h3>
                        {/* <p>{grantItem.subTitle}</p> */}
                    </div>
                    <button className={"clearButton " + classes.bookmarkContainer} onClick={(e) => bookMarkClick(e)}>
                        {grantItem.saved ?
                            <svg className={classes.bookmarkSvg + ' bi bi-bookmark-check-fill'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4 0a2 2 0 0 0-2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4zm6.854 5.854a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                            </svg> :
                            <svg className={classes.bookmarkSvg + ' bi bi-bookmark'} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                                <path fillRule="evenodd" d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                            </svg>
                        }
                    </button>
                </div>
                <div className={classes.itemBottom}>
                    <p className={classes.grantAmount}>{grantItem.amount}</p>
                    <p className={classes.grantType}>{grantItem.type}</p>
                    <p className={classes.grantDeadline}>{deadline}</p>
                </div>
            </div>
            <div className={style1 + ' ' + classes.itemRight}>

            </div>
        </div>
    )
}

export default GrantListItem