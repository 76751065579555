import { incorporated, companySize, yesNoCheck, heckYesCheck } from './DropDownSelection'
import classes from './GrantProfileCreationFormElements.module.css'

export const GrantProfileCreationFormElements = {
    /*
     * Each object inside "form" represents a different field during grant profile creation.
     * Options may be filled by setDropdownOptions() in DropDownSelections.js, which makes
     * a call to /dropdowns/ and retrieves options from backend/models/choices/. Options may also be
     * filled by the arrays exported by DropdownSelections.js.
     * 
     * NOTE: Options set in backend/models/choices must then be set in the Django admin page
     * (https://hellopocketed.io/admin/)
     */
    form: {
        email: {
            type: 'email',
            label: "Email",
            elementConfig: {
                placeholder: '',
                required: true
            },
            edit: true,
            value: '',
            entryValue: 'email'
        },
        firstName: {
            type: 'text',
            label: "First Name",
            elementConfig: {
                placeholder: '',
                required: true
            },
            id: 'fname',
            edit: true,
            value: '',
            entryValue: 'first_name'
        },
        lastName: {
            type: 'text',
            label: "Last Name",
            elementConfig: {
                placeholder: '',
                required: true
            },
            id: 'lname',
            edit: true,
            value: '',
            entryValue: 'last_name'
        },
        betaCode: {
            type: 'text',
            label: <p className="beta-label">Have a <b>Referral Code</b>? Great, enter it below for added benefits!</p>,
            elementConfig: {
                placeholder: '',
            },
            id: 'beta-code',
            edit: true,
            value: '',
            entryValue: 'referral_code'
        },
        password: {
            type: 'password',
            label: "Password",
            elementConfig: {
                placeholder: '',
                required: true
            },
            id: 'password',
            edit: true,
            value: '',
            entryValue: 'password1'
        },
        confirmPassword: {
            type: 'password',
            label: "Confirm Password",
            elementConfig: {
                placeholder: '',
                required: true
            },
            id: 'confirmPassword',
            edit: true,
            value: '',
            hint: <>
                <ul className={classes.passwordHint}>
                    <li>Your password must contain at least 8 characters</li>
                    <li>Your password can't be a commonly used password</li>
                    <li>Your password can't be entirely numeric</li>
                </ul>
            </>,
            entryValue: 'password2'
        },
        alphaUserCommit: {
            type: 'checkbox',
            label: "As a Pocketed User, I commit to:",
            elementConfig: {
                placeholder: '',
                required: true
            },
            edit: true,
            value: '',
            additionalClass: classes.emailCheckbox,
            hint: <>
                <ul className={classes.alphaUserCommit}>
                    <li>Sharing my feedback regularly with the Pocketed team </li>
                    <li>Featuring my experience with Pocketed in a case study</li>
                </ul>
            </>
        },
        emailCheckbox: {
            type: 'checkbox',
            label: "I agree to receive emails from Pocketed",
            elementConfig: {
                placeholder: '',
                required: true
            },
            edit: true,
            value: '',
            additionalClass: classes.emailCheckbox
        },
        businessName: {
            type: 'text',
            label: "Company Name*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            edit: true,
            value: '',
            entryValue: 'company_name'
        },
        website: {
            type: 'text',
            label: "Company Website",
            elementConfig: {
                placeholder: '',
            },
            edit: true,
            value: '',
            entryValue: 'company_site'
        },
        country: {
            type: 'dropdown',
            label: "In Which Country Does Your Company Operate?*",
            elementConfig: {
                required: true
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'country_abb',
            hasReliants: true,
            reliantInputs: [{ match: 'CA', display: 'region_ca' }, { match: 'US', display: 'region_us' }],
        },
        region_ca: {
            type: 'dropdown-multiple',
            label: "In Which Provinces Does Your Company Operate?*",
            elementConfig: {
                required: true
            },
            options: [], // filled by setDropdownOptions 
            edit: true,
            value: [],
            initialValue: [],
            multiple: true,
            entryValue: 'regions',
            show: true
        },
        region_us: {
            type: 'dropdown-multiple',
            label: "In Which State Does Your Company Operate?*",
            elementConfig: {
                required: false
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: [],
            initialValue: [],
            multiple: true,
            entryValue: 'regions',
            show: false
        },
        phone: {
            type: 'phone',
            label: "Phone Number* (Include your Country Code)",
            elementConfig: {
                placeholder: '',
                required: true
            },
            edit: true,
            value: '',
            entryValue: 'phone_number'
        },
        companyStatus: {
            type: 'dropdown',
            label: "Company Status*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...incorporated], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'incorporated'
        },
        companySize: {
            type: 'dropdown',
            label: "Company Size*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...companySize], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'company_size'
        },
        annualRevenue: {
            type: 'dropdown',
            label: "Annual Revenue*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'annual_revenue'
        },
        registrationDate: {
            type: 'date-picker',
            label: "Company Founded/Registered Date*",
            elementConfig: {
                selected: '',
                required: true
            },
            edit: true,
            entryValue: 'registration_date'
        },
        founderType: {
            type: 'dropdown-multiple',
            label: "Founder Demographics",
            elementConfig: {
                placeholder: ''
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: [],
            multiple: true,
            hint: <p className={classes.founderHint}>Disclaimer: <br />Optional field. Some grants offer priority access to founders within these demographics</p>,
            entryValue: 'founder_demos',
            show: true
        },
        industry: {
            type: 'dropdown-multiple',
            label: 'Industry*',
            elementConfig: {
                required: true
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: [],
            multiple: true,
            entryValue: 'industries',
            show: true
        },
        businessType: {
            type: 'dropdown',
            label: 'Business Type*',
            elementConfig: {
                required: true
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: [],
            multiple: false,
            entryValue: 'business'
        },
        description: {
            type: 'textarea',
            label: "Company Description*",
            elementConfig: {
                rows: 4,
                required: true,
                placeholder: 'A few lines to describe your company, and product'
            },
            edit: true,
            value: '',
            entryValue: 'description'
        },
        businessGoals: {
            type: 'dropdown-multiple',
            label: "What are your key business goals in the next 12 months?*",
            elementConfig: {
                required: true
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: [],
            multiple: true,
            entryValue: 'bus_goals',
            show: true
        },
        raisedCapitol: {
            type: 'dropdown',
            label: "Have you raised dilutive capital?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'raised_capital',
            hasReliants: true,
            reliantInputs: ['capitolAmount', 'lastRaisedDate']
        },
        capitolAmount: {
            type: 'dropdown',
            label: "If yes, how much dilutive capital have you raised?",
            elementConfig: {
                placeholder: '',
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'capital_amount',
            show: false
        },
        lastRaisedDate: {
            type: 'date-picker',
            label: "When did you complete your last raise?*",
            elementConfig: {
                selected: '',
                required: true
            },
            edit: true,
            value: '',
            entryValue: 'last_raise',
            show: false
        },
        accessedFunding: {
            type: 'dropdown',
            label: "Have you accessed grant funding before?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'accessed_funding',
            hasReliants: true,
            reliantInputs: ['awardedAmount', 'awardedTypes', 'fundingUsed', 'cashRestraint']
        },
        awardedAmount: {
            type: 'dropdown',
            label: "If yes, approximately how much grant funding have you been awarded?",
            elementConfig: {
                placeholder: '',
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'awarded_amount',
            show: false
        },
        awardedTypes: {
            type: 'dropdown-multiple',
            label: "What types of grants have you been awarded?",
            elementConfig: {
                placeholder: '',
            },
            options: [], // filled by setDropdownOptions
            edit: true,
            value: [],
            multiple: true,
            entryValue: 'awarded_grant_types',
            show: false
        },
        fundingUsed: {
            type: 'text',
            label: "Of the grants you have been awarded, approximately how much grant funding have you actually used?",
            elementConfig: {
                placeholder: '',
            },
            edit: true,
            value: '',
            entryValue: 'funding_used',
            show: false
        },
        cashRestraint: {
            type: 'dropdown',
            label: "Have you ever been awarded grant funding, but been unable to use it due to cash restraints?",
            elementConfig: {
                placeholder: '',
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'cash_restraint',
            show: false
        },
        nonDilutive: {
            type: 'dropdown',
            label: "If you could access non-dilutive financing (at founder-friendly interest rates) to bridge cash flow constraints so that you can use more grant funding, would you be interested in learning more?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...heckYesCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            entryValue: 'non_dilutive'
        },
        sredSubmitted: {
            type: 'dropdown',
            label: "Have you accessed R&D tax credits?*",
            elementConfig: {
                placeholder: '',
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'sred_submitted',
            hasReliants: true,
            reliantInputs: ['sredDate', 'sredValue', 'sredConsultant', 'sredFuture'],
            reliantInputsFalse: ['engageRD']
        },
        sredDate: {
            type: 'date-picker',
            label: "When did you make the R&D claim?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            id: 'sredDate',
            edit: true,
            value: '',
            entryValue: 'sred_date',
            show: false
        },
        sredValue: {
            type: 'text',
            label: "What was the value of the claim?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            id: 'sredDate',
            edit: true,
            value: '',
            entryValue: 'sred_value',
            show: false
        },
        sredConsultant: {
            type: 'dropdown',
            label: "Did you use a R&D consultant?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'sred_consultant',
            show: false
        },
        sredFuture: {
            type: 'dropdown',
            label: "Do you anticipate additional R&D claims in the future?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'sred_future',
            show: false
        },
        engageRD: {
            type: 'dropdown',
            label: "Do you engage in research and development?*",
            elementConfig: {
                placeholder: '',
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'engage_rd',
            hasReliants: true,
            reliantInputs: ['taxCredits'],
            show: false
        },
        taxCredits: {
            type: 'dropdown',
            label: "Would you be interested in learning more about tax credits for which your eligible?*",
            elementConfig: {
                placeholder: '',
                required: true
            },
            options: [...yesNoCheck], // filled by DropDownSelections
            edit: true,
            value: '',
            multiple: false,
            entryValue: 'tax_credits',
            show: false
        },
    },
    loading: true,
    editForm: false,
    backupForm: null,
    displayPaymentForm: false,
    gpCreating: false,
    gpCreationFailed: false,
    formFinished: false,
    freeAccount: true
}

const remove = ['email', 'firstName', 'lastName', 'password', 'confirmPassword', 'betaCode', 'alphaUserCommit', 'emailCheckbox', 'businessGoals', 'raisedCapitol', 'capitolAmount', 'lastRaisedDate', 'accessedFunding', 'awardedAmount', 'awardedTypes', 'fundingUsed', 'cashRestraint', 'nonDilutive', 'sredSubmitted', 'sredDate', 'sredValue', 'sredConsultant', 'sredFuture', 'engageRD', 'taxCredits']
const removeBetaElements = ['betaCode']
const removeAlphaNoGP = ['email', 'firstName', 'lastName', 'password', 'confirmPassword', 'betaCode', 'alphaUserCommit', 'emailCheckbox']
const removeClearCoNoGP = ['email', 'betaCode', 'alphaUserCommit']

export const onlyEssentialGrantFormElements = () => {
    let GrantProfileElements = { ...GrantProfileCreationFormElements }

    remove.forEach(element => {
        delete GrantProfileElements.form[element]
    });

    return GrantProfileElements
}

export const NonBetaGrantFormElements = () => {
    let GrantProfileElements = { ...GrantProfileCreationFormElements }

    removeBetaElements.forEach(element => {
        delete GrantProfileElements.form[element]
    });

    return GrantProfileElements
}

export const AlphaNoGPGrantFormElements = () => {
    let GrantProfileElements = { ...GrantProfileCreationFormElements }

    removeAlphaNoGP.forEach(element => {
        delete GrantProfileElements.form[element]
    });

    return GrantProfileElements
}


export const ClearCONoGPFormElements = () => {
    let GrantProfileElements = { ...GrantProfileCreationFormElements }

    removeClearCoNoGP.forEach(element => {
        delete GrantProfileElements.form[element]
    });

    return GrantProfileElements
}