import React, { useContext, useEffect, useRef, useState } from 'react'
import CustomButton from '../../../../Components/UI/CustomButton/CustomButton'
import logo from '../../../../Assets/Images/logo.png'
import classes from '../GrantProfileForm.module.css'
import instance from '../../../../Assets/Axios/AxiosInstance'
import { withRouter } from 'react-router-dom'
import InputArrayMapper from '../../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper'
import { GrantProfileCreationFormElements } from '../../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileCreationFormElements'
import { formatDate } from '../../../../Assets/Functions/DateFormater'
import Loading from '../../../../Components/UI/Loading/Loading'
import { Mixpanel } from '../../../../Assets/Functions/MixPanel/MixPanel'
import { checkPassword } from '../../../../Assets/Functions/ProfileCreation'
import { checkRequiredFunction, formChangeFunction, setDropdownOptions, goToPageFunction } from '../../../../Assets/Functions/GrantProfileCreationFormElements/GrantProfileFormFunctions'
import { StoreContext } from '../../../../Store/StoreContext'


// Beta user/grant profile creation form, attaches beta flag to profile upon creation, currently the only way to create a profile and access the platform
const GrantProfileFormBeta = () => {
    const { actions } = useContext(StoreContext)

    const [profileState, setProfileState] = useState({
        ...GrantProfileCreationFormElements,
        formInputUpperLimit: 8,
        formInputLowerLimit: 0,
        page: 1,
        firstUpperLimit: 8,
        secondUpperLimit: 22,
        thirdUpperLimit: 1000,
    })
    const pageRef = useRef()
    const errorRef = useRef()

    useEffect(() => {
        // gets dropdown options from backend
        instance.get('/dropdowns/').then(r => {
            // console.log(r.data)
            setDropdownOptions(r, setProfileState)
        })
    }, [])

    const formChange = formChangeFunction(setProfileState)
    const goToPage = goToPageFunction(profileState, setProfileState)
    const checkRequired = checkRequiredFunction(profileState)

    // changes page if not on page 3, otherwise submits form
    const formSubmit = (e) => {
        e.preventDefault()
        if (profileState.page == 1) {
            let formdata = new FormData()
            formdata.append('email', profileState.form['email'].value)
            instance.post('/user-exists/', formdata).then(res => {
                setProfileState(old => {
                    let temp = { ...old }
                    let res = checkPassword(temp.form.password.value, temp.form.confirmPassword.value)
                    if (res.flag) {
                        console.log(res)
                        temp.form.password.hint = <p ref={errorRef} className={classes.err}>{res.msg}</p>
                        if (errorRef.current) {
                            errorRef.current.scrollIntoView({
                                behavior: "smooth",
                            })
                        }
                    } else {
                        temp.page = 2
                        temp.formInputUpperLimit = profileState.secondUpperLimit
                        temp.formInputLowerLimit = profileState.firstUpperLimit
                        temp.form.password.hint = ''
                        if (pageRef.current) {
                            // let height = pageRef.getBoundingClientRect()
                            // console.log(height)
                            pageRef.current.scrollIntoView({
                                behavior: "smooth",
                            })
                        }
                    }
                    return temp
                })
            }).catch(err => {
                // console.log(err.response.data['user-exists'])
                if ('user-exists' in err.response.data && err.response.data['user-exists']) {
                    setProfileState(old => {
                        let res = { ...old }
                        res.form['email'].error = <p ref={errorRef} className={classes.err}>This email is already registered with our platform</p>
                        return res
                    })
                }
            })
        } else if (profileState.page == 2) {
            setProfileState(old => {
                let temp = { ...old }
                let res = checkRequired(temp.form)
                if (res.flag) {
                    console.log(res)
                    temp.form[res.ele].error = <p ref={errorRef} className={classes.err}>This field is required</p>
                    if (errorRef.current) {
                        // let parentEle = document.getElementById('altLayout')
                        errorRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                } else {
                    temp.page = 3
                    temp.formInputUpperLimit = profileState.thirdUpperLimit
                    temp.formInputLowerLimit = profileState.secondUpperLimit
                    if (pageRef.current) {
                        pageRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                }
                return temp
            })
        } else if (profileState.page == 3 && !profileState.freeAccount) {
            setProfileState(old => {
                let temp = { ...old }
                let res = checkRequired(temp.form)
                if (res.flag) {
                    console.log(res)
                    temp.form[res.ele].error = <p ref={errorRef} className={classes.err}>This field is required</p>
                    if (errorRef.current) {
                        // let parentEle = document.getElementById('altLayout')
                        errorRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                } else {
                    temp.page = 4
                    temp.displayPaymentForm = true
                    if (pageRef.current) {
                        pageRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                }
                return temp
            })
        }
        else {
            setProfileState(old => {
                let res = { ...old }
                res.loading = true
                return res
            })
            let temp = profileState.form
            let body = new Object()
            let user = new Object()
            let grantprofile = new Object()

            Object.keys(temp).forEach((i, k) => {
                if (i == 'betaCode') {
                    body['beta_code'] = temp[i].value
                } else if (k < 6) {
                    user[temp[i].entryValue] = temp[i].value
                } else if (k > 7) {
                    if (temp[i].value instanceof Date) {
                        grantprofile[temp[i].entryValue] = formatDate(temp[i].value)
                    } else if (temp[i].show !== undefined && !temp[i].show) {
                        // do nothing unless awardedTypes is the input, in that case assign an empty array
                        if (i == 'awardedTypes') {
                            grantprofile[temp[i].entryValue] = []
                        }
                    }
                    else if (temp[i].multiple) {
                        grantprofile[temp[i].entryValue] = temp[i].value.map(i => i.value)
                    } else if (temp[i].entryValue === undefined) {

                    }
                    else {
                        grantprofile[temp[i].entryValue] = temp[i].value
                    }
                }
            })
            user['username'] = temp.email.value
            body['grantprofile'] = grantprofile
            body['user'] = user

            let timeout;
            instance.post('/api/beta-profile/', body).then(res => {
                console.log(res.response)
                Mixpanel.track('Grant Profile Created', body)
            }).catch(err => {
                Mixpanel.track('Grant Profile Creation Failed', body)
                clearTimeout(timeout)
                setProfileState({ ...profileState, loading: false })
                actions.setAuthFailedState(true)
                console.log(err.response)
            })
            //This timeout is implemented as a workaround for the grant profile creation API taking > 2 mins to return a response, bandaid solution that should be removed later on
            timeout = setTimeout(() => {
                setProfileState(old => {
                    let res = { ...old }
                    res.loading = false
                    res.formFinished = true
                    return res
                })

            }, 10000)
        }
    }


    let buttons;
    // determines which buttons to show based on page
    if (profileState.page === 1) {
        buttons = <div className={classes.firstFormPageBtn}>
            <CustomButton css="light-blue" title="Next" type="submit" />
        </div>
    } else if (profileState.page === 2) {
        buttons = <div className={classes.secondFormPageBtn}>
            <a className={"light-blue"} onClick={() => goToPage(1)}>Previous</a>
            <CustomButton css="light-blue" title="Next" type="submit" />
        </div>
    } else if (profileState.page === 3) {
        {/* If the user selects the free account option,  allow them to create an account.
            If they select the paid option, remove the create account button with the redirect to payment button*/}
        buttons = <>{profileState.freeAccount ?
            <div className={classes.thirdFormPageBtn}>
                <a className={"light-blue"} onClick={() => goToPage(2)}>Previous</a>
                <CustomButton css="dark-blue" title="Create Your Account" type="submit" />
            </div> : <div className={classes.secondFormPageBtn}>
                <a className={"light-blue"} onClick={() => goToPage(2)}>Previous</a>
                <CustomButton css="light-blue" title="Next" type="submit" />
            </div>}</>

    } else {
        buttons = <div className={classes.thirdFormPageBtn}>
            <a className={"light-blue"} onClick={() => goToPage(3)}>Previous</a>
            <CustomButton css="dark-blue" title="Create Your Account" type="submit" />
        </div>
    }

    return (
        <form className={classes.form} onSubmit={(e) => formSubmit(e)} ref={pageRef}>
            {profileState.loading ? <div className={classes.loading}><Loading /><p>Sit tight! Here comes the cash &#128176;</p></div> : <>
                <a href='/'><img className={classes.img} src={logo} /></a>
                {profileState.formFinished ? <>
                    <div className={classes.successMessage}>Grant funding awaits!<br /> To access your Pocketed account, check your email to verify your address</div></> : <>
                    <div className={classes.header}>
                        <h2>Complete Your Profile</h2>
                        <p>Takes less than 3 mins<br /> Welcome to Beta!</p>
                    </div>
                    {/* Removed account selector from beta account creation, implemented in Stripe Account Creation Component */}
                    {/* {profileState.page === 2? <AccountSelection state={profileState} setState={setProfileState} /> : ''} */}
                    <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
                    <div className={classes.bottomContainer}>
                        {profileState.page !== 1 ? <p className={classes.requiredEx}>* required</p> : ''}
                        <div className={classes.formButtons}>
                            {buttons}
                        </div>
                        <p>Page {profileState.page} of  {profileState.freeAccount ? 3 : 4}</p>
                    </div>
                </>}
            </>}
        </form>
    )
}

export default withRouter(GrantProfileFormBeta)