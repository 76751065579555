const filterSelection1 = [
    {
        title: 'View',
        multiple: false,
        param: 'new',
        locked: false,
        options: [
            {
                name: 'All Grants'
            },
            {
                name: 'New Grants'
            }
        ]
    },
    {
        title: 'Amount',
        multiple: false,
        param: 'amountGte',
        locked: false,
        options: [
            {
                name: 'Lowest - Highest',
            },
            {
                name: 'Highest - Lowest',
            }
        ]
    },
    {
        title: 'Application Length',
        multiple: false,
        param: 'processTime',
        locked: false,
        options: [
            {
                name: 'Quick'
            },
            {
                name: 'Moderate'
            },
            {
                name: 'Long'
            }
        ]
    },
    {
        title: 'Funding Scheme',
        multiple: false,
        param: 'cashUp',
        locked: false,
        options: [
            {
                name: 'Cash upfront not required',
            },
            {
                name: 'Cash upfront required',
            }
        ]
    },
]

const filterSelection2 = [
    {
        title: 'Candidate Requirements',
        multiple: true,
        param: 'eligibilityCand',
        locked: true,
        options: []
    },
    {
        title: 'Role Type',
        multiple: true,
        param: 'roleReq',
        locked: true,
        options: []
    },
    {
        title: 'Role Length',
        multiple: true,
        param: 'roleReq',
        locked: true,
        options: []
    },
]

const filterSelection3 = [
    {
        title: 'Project Activities',
        multiple: true,
        param: 'eligibileAct',
        locked: true,
        options: []
    },
    {
        title: 'Project Length',
        multiple: true,
        param: 'eligibileProject',
        locked: true,
        options: []
    },
]

const filterOptions = {
    All: {
        title: 'All',
        type: 'dropdown',
        subselection: filterSelection1
    }
}

export {filterSelection1, filterSelection2, filterSelection3}
export default filterOptions