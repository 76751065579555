import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import classes from './AltNav.module.css'
import logo from '../../../Assets/Images/logo.png'
import logoWhite from '../../../Assets/Images/logoWhite.png'

const AltNav = (props) => {


    return (
        <Nav className={classes.container} id='altnavbar'>
            <div className={classes.content}>
                <div className={classes.dummy}>
                    <NavLink to='/about' className={classes.about + ' d-none d-md-flex'}>
                        <p id="navfactLink" className={classes.about}>ABOUT</p>
                    </NavLink>
                </div>
                <NavLink to='/' className={classes.icon}>
                    <img src={logoWhite} className={classes.img} />
                </NavLink>
                <div className={classes.authLinks}>
                    <NavLink to='/auth/sign-in' className={'d-flex'}>
                        <p className={classes.signin} id="signIn">Sign In</p>
                    </NavLink>
                    <NavLink to='/auth/create-account' className={classes.alphaButton + ' d-none d-sm-flex'}>
                        <p className={''} id="signIn">Join Pocketed</p>
                    </NavLink>
                </div>
            </div>
        </Nav>
    )
}

export default AltNav