/* global gapi */
import React, { useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import classes from './Auth.module.css';
import LoginForm from './LoginForm/LoginForm';
import logo from '../../Assets/Images/logoSymbolFullWhite.png';
import ContactUs from './ContactUs/ContactUs';
import LayoutNoNav from '../../HOC/Layout/LayoutNoNav/LayoutNoNav';
import FooterCOA from '../../Components/Footer/FooterCOA/FooterCOA';
import AccountActivated from './AccountActivated/AccountActivated';
import PasswordReset from './PasswordReset/PasswordReset';
import ConfirmPasswordReset from './ConfirmPasswordReset/ConfirmPasswordReset';
import ResendActivationLink from './ResendActivationLink/ResendActivationLink';
import GrantProfileForm from './GrantProfileForm/GrantProfileForm';
import GrantProfileFormNoGP from './GrantProfileForm/GrantProfileFrom-NoGP/GrantProfileForm-NoGP';
import GrantProfileFormBeta from './GrantProfileForm/GrantProfileForm-Beta/GrantProfileForm-Beta';
import AccountActivatedBeta from './AccountActivated/AccountActivated-Beta/AccountActivated-Beta';
import GrantProfileFormAlphaNoGP from './GrantProfileForm/GrantProfileForm-AlphaNoGP/GrantProfileForm-AlphaNoGP';
import GrantProfileFormWaitList from './GrantProfileForm/GrantProfileForm-WaitList/GrantProfileForm-WaitList';
import GrantProfileFormFriendReferral from './GrantProfileForm/GrantProfileForm-FriendReferral/GrantProfileForm-FriendReferral';
import GrantProfileFormUSAlphaWL from './GrantProfileForm/GrantProfileForm-USAlpha/GrantProfileForm-USAlphaWL';
import StripeForm from './StripeFormContainer/StripeFormContainer';
import GrantProfileFormClearcoNoGP from './GrantProfileForm/GrantProfileForm-ClearcoNoGP/GrantProfileForm-ClearcoNoGP';
import { StoreContext } from '../../Store/StoreContext';
import PopupModal from '../../Components/UI/PopUps/PopupModal';
import warningIcon from '../../Assets/Images/warning-icon.svg';
import CustomButton from '../../Components/UI/CustomButton/CustomButton';
import logoIcon from '../../Assets/Images/logoSymbolCropped.png';

const Auth = props => {
    const { actions, state } = useContext(StoreContext);

    const logoClick = () => {
        console.log(props.history.push('/'));
    };

    return (
        <LayoutNoNav>
            {state.shortGrantList ? (
                <PopupModal
                    type="custom"
                    backgroundClicked={() => actions.setShortGrantList(false)}
                    title={'Hey!'}
                    message={
                        <>
                            Your matched list is short, but that makes your job easier!
                            <br /> Don't fret, you'll have more matches coming soon!
                        </>
                    }
                    image={logoIcon}
                />
            ) : (
                ''
            )}
            {state.authFailed ? (
                <PopupModal
                    type="custom"
                    backgroundClicked={() => actions.setAuthFailedState(false)}
                    image={warningIcon}
                    title={'So Close!'}
                    message={
                        <>
                            Please check that all the fields are complete and in the correct format.
                            <br />
                            Still having trouble?
                            <br />
                            <CustomButton css="light-blue" title="" type="submit">
                                <a style={{ color: 'inherit' }} href="https://pocketed.zendesk.com/hc/en-us/requests/new">
                                    Click Here
                                </a>
                            </CustomButton>
                        </>
                    }
                />
            ) : (
                ''
            )}
            <div className={classes.container}>
                <div className={classes.leftSideContainer + ' d-none d-lg-flex'}>
                    <div className={classes.logoContainer}>
                        <img src={logo} onClick={() => logoClick()} />
                    </div>
                </div>
                <div className={classes.rightSideContainer}>
                    <div className={classes.formContainer}>
                        <Switch>
                            {/* <Route path="/auth/create-account" component={GrantProfileForm} /> */}
                            {/* <Route path="/auth/create-beta-account" component={GrantProfileForm} /> */}
                            {/* <Route path="/auth/create-waitlist-account" component={GrantProfileForm} /> */}
                            {/* <Route path="/auth/create-alpha-grantprofile" component={GrantProfileFormAlphaNoGP} /> */}
                            {/* <Route path="/auth/friend-referral" component={GrantProfileFormFriendReferral} /> */}
                            {/* <Route path="/auth/create-us-alpha-waitlist" component={GrantProfileFormUSAlphaWL} /> */}
                            <Route path="/auth/login" render={() => <LoginForm />} />
                            <Route path="/auth/create-grantprofile" component={GrantProfileFormClearcoNoGP} />
                            <Route path="/auth/contact-us" component={ContactUs} />
                            <Route path="/auth/reset-password" component={PasswordReset} />
                            <Route path="/auth/resend-activation" component={ResendActivationLink} />
                            <Route path="/auth/stripe" component={StripeForm} />
                            <Route path="/activate-account/:path/:token" render={() => <AccountActivated />} />
                            <Route path="/activate-beta-account/:path/:token" render={() => <AccountActivatedBeta />} />
                            <Route path="/confirm-password-reset/:path/:token" render={() => <ConfirmPasswordReset />} />
                            <Route path="*" render={() => <LoginForm />} />
                        </Switch>
                    </div>
                    <div>
                        <FooterCOA />
                    </div>
                </div>
            </div>
        </LayoutNoNav>
    );
};

export default withRouter(Auth);
