import classes from './AdditionalFilters.module.css'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../../Store/StoreContext'
import { filterSelection1, filterSelection2, filterSelection3 } from '../../../../Assets/Functions/Filters/FilterOptions'
import CustomButton from '../../../UI/CustomButton/CustomButton'
import lockFilled from './lock-fill.svg'
import PopupModal from '../../../UI/PopUps/PopupModal'
import logoIcon from '../../../../Assets/Images/logoSymbolCropped.png'

const AdditionalFilters = () => {
    const { actions, state } = useContext(StoreContext)
    const [filters, setFilters] = useState({
        flag: false,
        lockedPopupClicked: false,
        filterSelection2: filterSelection2,
        filterSelection3: filterSelection3,
        taxCredit: []
    })



    // Very messy way of adding the sub filter options retrieved from the backend, should eventually talk to backend to organize a less hard coded way of assigning the array
    useEffect(() => {
        setFilters(old => {
            let res = { ...old }
            res.filterSelection2[0].options = state.subFilters.data.candidate_req
            res.filterSelection2[1].options = state.subFilters.data.role_type
            res.filterSelection2[2].options = state.subFilters.data.role_length
            res.filterSelection3[0].options = state.subFilters.data.project_activity
            res.filterSelection3[1].options = state.subFilters.data.project_length
            res.taxCredit = state.subFilters.data['tax-credit']
            return res
        })
    }, [])

    // Bottom level filters are changed depending on Which Grant Type is picked
    const topLevelFilters = [
        { name: 'Hiring', filterLevel: 1 },
        { name: 'Research and Development', filterLevel: 1 },
        { name: 'Market Expansion', filterLevel: 2 },
        { name: 'Training', filterLevel: 0 },
        { name: 'COVID-19', filterLevel: 0 },
    ]


    let subFilters, fadeFilters;

    switch (state.filterSelection.level) {
        case 0:
            subFilters = false
            fadeFilters = true
            break;
        case 1:
            subFilters = filters.filterSelection2
            fadeFilters = false
            break;
        case 2:
            subFilters = filters.filterSelection3
            fadeFilters = false
            break;
        default:
            subFilters = filters.filterSelection2
            fadeFilters = true
            break;
    }


    const topLevelOptionPicked = (e, param) => {
        // console.log('top')

        let filterSelection = { ...state.filterSelection }
        let res = { ...filterSelection.selected }
        let level;
        res = {
            ...res,
            eligibilityCand: [],
            roleReq: [],
            eligibileAct: [],
            eligibileProject: []
        }
        // check to see if selected filter parent is already in the selected object, if it isn't create a new key/value pair with the parent/value
        if (res[param]?.length > 0) {
            let flag = res[param].indexOf(e.name)
            // if key/value is already in selection remove it, otherwise replace it
            if (flag > -1) {
                level = -1
                res[param].splice(flag, 1)
            }
            else {
                level = e.filterLevel
                res[param] = [e.name]
            }
        }
        else {
            level = e.filterLevel
            res[param] = [e.name]
        }

        // Flag used to prevent reset from from reloading grant list if nothing to reset
        if (level !== 0 && !filters.flag) {
            setFilters({ ...filters, flag: true })
        } else if (level === 0) {
            setFilters({ ...filters, flag: false })
        }

        actions.setFilterSelection({ selected: res, level: level })
    }

    const subOptionPicked = (e, param, multiple, locked, mobileFilter) => {
        if (state.filterSelection.level !== 0 || mobileFilter) {
            let filterSelection = { ...state.filterSelection }
            let res = { ...filterSelection.selected }
            
            // This check is what is currentll locking the sub filters until a paid tier is implemented
            if (locked) return setFilters({ ...filters, lockedPopupClicked: true })

            // check to see if selected filter parent is already in the selected object, if it isn't create a new key/value pair with the parent/value
            if (res[param]?.length > 0) {
                let flag = res[param].indexOf(e)
                // if key/value is already in selection remove it, if selection allows for multiple, add it to the selected key, otherwise replace it
                if (flag > -1) {
                    // console.log(1)
                    res[param].splice(flag, 1)
                }
                else if (multiple) {
                    // console.log(2)
                    res[param].push(e)
                }
                else {
                    // console.log(3)
                    res[param] = [e]
                }
            }
            else {
                // console.log(4)
                res[param] = [e]
            }
            // console.log(res)
            actions.setFilterSelection({ selected: res })
        }
    }


    const resetFields = () => {
        let filterSelection = { ...state.filterSelection }
        let res = { ...filterSelection.selected }
        res = {
            ...res,
            eligibilityCand: [],
            roleReq: [],
            eligibileAct: [],
            eligibileProject: [],
            grantTypes: [],
            new: ["All Grants"],
            amountGte: [],
            processTime: [],
            cashUp: []
        }
        actions.setFilterSelection({ selected: res, level: -1 })
    }

    const closeFilterPopup = () => {
        actions.setAdditionalFilters({ flag: false })
    }
    return (
        <div className={classes.container} onClick={e => e.stopPropagation()}>
            {filters.lockedPopupClicked ? <PopupModal
                type='custom'
                backgroundClicked={() => setFilters({ ...filters, lockedPopupClicked: false })}
                title={'Hey There!'}
                message={<><b>Advanced filtering, alerts and other premium platform features</b> are coming soon! Keep your eye out for the release of our Premium Tier later this year. 👀</>}
                image={logoIcon}
            /> : ''}
            <div className={classes.title}>
                <h2>Filters</h2>
                <CustomButton css="white-filter" title="Reset" onClick={() => resetFields()} />
                <CustomButton css="landing-page-button" title="Close" onClick={() => closeFilterPopup()} />
            </div>
            {/* The section below is for mobile users, as we combine all the filters into the additional filters popup in the mobile view*/}
            {filterSelection1.map((i, k) => {
                let fadeClass = ''
                return (
                    <div className={classes.section + ' d-flex d-lg-none ' + fadeClass} key={k}>
                        <div className={classes.sectionTitle}>
                            <h4>{i.title}</h4>
                        </div>
                        <div className={classes.inputContainer}>
                            {i.options.map((ii, kk) => {
                                let isChecked = false
                                // Checks if the param set in the filterSelection1 array item contains the option in the current filters selected object
                                if (state.filterSelection.selected[i.param] !== undefined && state.filterSelection.selected[i.param].includes(ii.name)) {
                                    isChecked = true

                                }
                                return (
                                    <div className={classes.inputGroup} key={kk}>
                                        <input
                                            type="checkbox"
                                            id={ii.name}
                                            value={ii.name}
                                            name={ii.name}
                                            onClick={() => subOptionPicked(ii.name, i.param, i.multiple, i.locked, true)}
                                            checked={isChecked}
                                            readOnly
                                        />
                                        <label htmlFor={ii.name}>{ii.name}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
            {/* Main Filter Group in Additional Filters, sub filters will render beneath this filter depending on which of these options is picked*/}
            <div className={classes.section}>
                <div className={classes.sectionTitle}>
                    <div className={classes.titleWithSubText}>
                        <h4>Grant Type</h4>
                        <p>(Pick One)</p>
                    </div>
                </div>
                <div className={classes.inputContainer}>
                    {topLevelFilters.map((i, k) => {
                        let isChecked = false
                        if (i.name == state.filterSelection.selected['grantTypes']) {
                            isChecked = true
                        }
                        return (
                            <div className={classes.inputGroup} key={k}>
                                <input
                                    type="checkbox"
                                    id={i.name}
                                    name="grantType"
                                    onClick={() => topLevelOptionPicked(i, 'grantTypes', i.multiple)}
                                    checked={isChecked}
                                    readOnly
                                />
                                <label htmlFor={i.name}>{i.name}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={classes.section}>
                <div className={classes.sectionTitle}>
                    <div className={classes.titleWithSubText}>
                        <h4>Funding Type</h4>
                    </div>
                </div>
                <div className={classes.inputContainer}>
                    {filters.taxCredit.map((i, k) => {
                        let isChecked = false
                        if (i.name == state.filterSelection.selected['taxCredit']) {
                            isChecked = true
                        }
                        return (
                            <div className={classes.inputGroup} key={k}>
                                <input
                                    type="checkbox"
                                    id={i.name}
                                    name="grantType"
                                    onClick={() => subOptionPicked(i.name, 'taxCredit', false)}
                                    checked={isChecked}
                                    readOnly
                                />
                                <label htmlFor={i.name}>{i.name}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
            {subFilters !== false ? subFilters.map((i, k) => {
                let fadeClass = ''
                if (fadeFilters) {
                    fadeClass = classes.fade
                }

                return (
                    <div className={classes.section + ' ' + fadeClass} key={k}>
                        <div className={classes.sectionTitle}>
                            <h4>{i.title}</h4>
                            <img src={lockFilled} />
                        </div>
                        <div className={classes.inputContainer}>
                            {i.options.map((ii, kk) => {
                                let isChecked = false
                                // Checks if the param set in the subFilters (either filterSelection1 or filterSelection2) array item contains the option in the current filters selected object
                                if (state.filterSelection.selected[i.param] !== undefined && state.filterSelection.selected[i.param].includes(ii.name)) {
                                    isChecked = true
                                }
                                return (
                                    <div className={classes.inputGroup} key={kk}>
                                        <input
                                            type="checkbox"
                                            id={ii.name}
                                            value={ii.name}
                                            name={ii.name}
                                            onClick={() => subOptionPicked(ii.name, i.param, i.multiple, i.locked)}
                                            checked={isChecked}
                                            readOnly
                                        />

                                        <label htmlFor={ii.name}>{ii.name}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            }) : ''}
        </div>
    )
}

export default AdditionalFilters