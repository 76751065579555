import A from '../Images/GrantIcons/A.png'
import B from '../Images/GrantIcons/B.png'
import C from '../Images/GrantIcons/C.png'
import D from '../Images/GrantIcons/D.png'
import E from '../Images/GrantIcons/E.png'
import F from '../Images/GrantIcons/F.png'
import G from '../Images/GrantIcons/G.png'
import H from '../Images/GrantIcons/H.png'
import I from '../Images/GrantIcons/I.png'
import J from '../Images/GrantIcons/J.png'
import K from '../Images/GrantIcons/K.png'
import L from '../Images/GrantIcons/L.png'
import M from '../Images/GrantIcons/M.png'
import N from '../Images/GrantIcons/N.png'
import O from '../Images/GrantIcons/O.png'
import P from '../Images/GrantIcons/P.png'
import Q from '../Images/GrantIcons/Q.png'
import R from '../Images/GrantIcons/R.png'
import S from '../Images/GrantIcons/S.png'
import T from '../Images/GrantIcons/T.png'
import U from '../Images/GrantIcons/U.png'
import V from '../Images/GrantIcons/V.png'
import W from '../Images/GrantIcons/W.png'
import X from '../Images/GrantIcons/X.png'
import Y from '../Images/GrantIcons/Y.png'
import Z from '../Images/GrantIcons/Z.png'

export const selectProfileImage = (str) => {
    let char
    if(str !== undefined){
        char = str[0].toLowerCase()
        
    }
    else{
        char = 'e'
    }
    
    switch(char){
        case 'a':
            return A
        case 'b':
            return B
        case 'c':
            return C
        case 'd':
            return D
        case 'e':
            return E
        case 'f':
            return F
        case 'g':
            return G
        case 'h':
            return H
        case 'i':
            return I
        case 'j':
            return J
        case 'k':
            return K
        case 'l':
            return L
        case 'm':
            return M
        case 'n':
            return N
        case 'o':
            return O
        case 'p':
            return P
        case 'q':
            return Q
        case 'r':
            return R
        case 's':
            return S
        case 't':
            return T
        case 'u':
            return U
        case 'v':
            return V
        case 'w':
            return W
        case 'x':
            return X
        case 'y':
            return Y
        case 'z':
            return Z
        default:
            return P 
    }
}