import React from 'react';
import Layout from '../../HOC/Layout/Layout';
import classes from './HomePage.module.css';
import search from '../../Assets/Images/searching.svg';
import wave from '../../Assets/Images/seeYouSoon.svg';
import payment from '../../Assets/Images/paymentProcessed.svg';
import HomePageCard from '../../Components/AppicationComponents/HomePageCard/HomePageCard';
import { withRouter } from 'react-router-dom';

const HomePage = props => {
    const sections = [
        {
            header: 'I logged into Pocketed. What do I do now?',
            body: (
                <>
                    <p>
                        You’re in! Pocketed matches you with grants that you’re eligible for based on the information you shared about your business.{' '}
                        <a target="_blank" href="https://www.youtube.com/watch?v=SYrZLcseZRc&ab_channel=PocketedGrantPlatform">
                            Here’s a quick video tour of Pocketed.
                        </a>
                    </p>
                    <p>
                        Click on the “Grants” button to your left to explore these grants. When you click on a grant from the list, you’ll see more information - including an overview of the program, details about the eligibility requirements, and
                        information about the application process.
                    </p>
                    <p>
                        Ready to apply for a grant? Click “Apply” and submit your application! <i>(Prefer to hire a grant writer? Click “Hire a Grant Writer”! More on that below.)</i>
                    </p>
                </>
            ),
        },
        {
            header: 'Where do I get help?',
            body: (
                <>
                    <p>
                        Looking for help?{' '}
                        <a href="https://meetings.hubspot.com/benpocketed/platform-help-session" target="_blank">
                            Click here to book a quick call with our team.
                        </a>{' '}
                        You can also shoot us an email using the purple “Help” button in the bottom right corner (we respond within 2 hours during business hours).{' '}
                    </p>
                    <p>
                        If you’re looking for information about grants, check out our{' '}
                        <a target="_blank" href="https://hellopocketed.medium.com/pocketed-grants-101-37872880f3ee">
                            Grants 101 blog post
                        </a>{' '}
                        and our{' '}
                        <a target="_blank" href="https://pocketed.zendesk.com/hc/en-us">
                            Knowledge Centre
                        </a>{' '}
                        to learn more!
                    </p>
                </>
            ),
        },
        {
            header: 'How much will I pay?',
            body: (
                <>
                    <p>Pocketed is free to use! There is also no cost to apply for grant funding - unless you hire a grant writer to do the work for you (more on that below).</p>
                </>
            ),
        },
        {
            header: 'What does it cost to hire a grant writer?',
            body: (
                <>
                    <p>
                        Grant writers are experts who can help you write and submit successful grant applications. The typical fee is 20% of the grant award. The good news: most fees are contingent on success - this means you only pay a fee if you
                        actually get the grant funding! Even better - most grant writers offer founder-friendly payment terms (eg. 120 days) so that you receive the grant money before you have to pay their fee.{' '}
                    </p>
                </>
            ),
        },
    ];

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.welcome}>
                        <h3>Welcome Dashboard</h3>
                    </div>
                    <div className={classes.hello}>
                        <h2>Hi there! ClearAngel x Pocketed are helping you unlock grant funding for your business! Click “Explore Grants” to get started</h2>
                    </div>
                    <div className={classes.cardContainer}>
                        {sections.map((i, k) => {
                            return (
                                <div key={k} className={classes.section}>
                                    <h4>{i.header}</h4>
                                    <div className={classes.body}>{i.body}</div>
                                </div>
                            );
                        })}
                    </div>

                    <div className={classes.footer}>
                        <a target="_blank" href="https://pocketed.zendesk.com/hc/en-us">
                            FAQ
                        </a>
                        <a target="_blank" href="https://pocketed.zendesk.com/hc/en-us/requests/new">
                            Share Feedback
                        </a>
                        <a target="_blank" href="https://hellopocketed.medium.com">
                            Blog
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(HomePage);
