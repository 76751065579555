import React from 'react'
import classes from './WufooForm.module.css';
import WufooForm from 'react-wufoo-embed'

const WufooFormTemplate = ({ type }) => {
    const forms = {
        referralPartner: {
            title: 'Become a Referral Partner',
            hash: 'mxi8ab91bhbcgj'
        },
        becomeWriter: {
            title: 'Become a Freelance Grant Writer',
            hash: 'q7bwrtz1vgdh78'
        },
        hireWriter: {
            title: 'I want to hire a grant writer',
            hash: 'r51qkio1ypwifg'
        },
        hireLawyer: {
            title: 'I want to hire a lawyer to help with incorporation ',
            hash: 'shjy7s60uajakd'
        },
        submitGrant: {
            title: 'Submit a Grant',
            hash: 'p1nm6qud1rva4u7'
        },
        contactUs: {
            title: 'Contact Us',
            hash: 'x37ptwj021cu7j'
        },
        applyFinancing: {
            title: 'Apply for Grant Based Financing',
            hash: 'wqt53c4184h5p7'
        },
        referFriend: {
            title: 'Refer A Friend',
            hash: 'z1dorlss17qiaeg'
        }
    }

    let form
    switch (type) {
        case 'referralPartner':
            form = forms.referralPartner
            break
        case 'becomeWriter':
            form = forms.becomeWriter
            break
        case 'hireWriter':
            form = forms.hireWriter
            break
        case 'hireLawyer':
            form = forms.hireLawyer
            break
        case 'submitGrant':
            form = forms.submitGrant
            break
        case 'contactUs':
            form = forms.contactUs
            break
        case 'applyFinancing':
            form = forms.applyFinancing
            break
        case 'refer-a-friend':
            form = forms.referFriend
            break;
        default:
            form = { title: 'if you are seeing this, tom made a very simple but stupid mistake' }
            break
    }



    return (
        <div className={classes.container}>
            <div className={classes.content}>
                {/* {form.title} */}
                <WufooForm userName='pocketed' formHash={form.hash} autoResize={true} />
            </div>
        </div>
    )
}

export default WufooFormTemplate