import React from 'react'
import AlternateLayout2 from '../../HOC/Layout/AlternateLayout2/AlternateLayout2'
import classes from './GetFunded.module.css'
import crossesBlue from '../../Assets/Images/crossesBlue.png'
import crossesWhite from '../../Assets/Images/crossesWhite.png'
import blobTopRight from '../../Assets/Images/blobTopRight.png'
import blobBottomLeft from '../../Assets/Images/blobBottomLeft.png'
import AltNav2 from '../../Components/Navigation/AlternateNavBar2/AltNav2'

const GetFunded = () => {
    return (
        <AlternateLayout2>
            <AltNav2/>
            <div className={classes.container}>
                <img className={classes.crossBlue} src={crossesBlue} />
                <img className={classes.crossWhite} src={crossesWhite} />
                <img className={classes.blobBottomLeft} src={blobBottomLeft} />
                <img className={classes.blobTopRight} src={blobTopRight} />
                <div className={classes.content}>
                    <div className={classes.message}>
                        <h2>Be a part of the funding revolution.</h2>
                        <h3>
                            Become a Beta Tester and get exclusive early access to the Pocketed platform
                            or sign up for updates to be notified when Pocketed goes live.
                        </h3>
                    </div>
                    <div className={classes.btnContainer}>
                        <a className={classes.btn} href="/auth/register">Apply for Beta</a>
                        <a className={classes.btn}>Get Updates</a>
                    </div>
                </div>
            </div>
        </AlternateLayout2>
    )
}

export default GetFunded