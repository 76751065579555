import React from 'react'
import { Button, DropdownButton } from 'react-bootstrap'
import classes from './CustomButton.module.css'

// props
// {
//      type: String
//      css: String 
// }
const CustomButton = (props) => {
    let button
    let style
    let extraClass

    switch (props.css) {
        case "white":
            style = "white"
            break;
        case "clear":
            style = "clear"
            break;
        case "clear no-arrow":
            style = "clear no-arrow"
            break;
        case "white float-bottom":
            style = "white float-bottom"
            break;
        case "dark":
            style = "dark"
            break
        case "landing-page-button":
            style = "landing-page-button"
            break
        case "light-blue":
            style = "light-blue"
        default:
            style = props.css
    }



    switch (props.type) {
        case "dropdown":
            button = <DropdownButton
                {...props}
                className={classes.button + ' ' + style}
            >
                {props.children}
            </DropdownButton>
            break;
        default:
            button = <Button
                {...props}
                className={classes.button + ' ' + style}
                type={props.type}
            >
                {props.title}{props.children}
            </Button>
    }

    return (
        <>{button}</>
    )
}

export default CustomButton