import {types} from './Reducer'

export const useActions = (dispatch) => {
    const showSearchBar = (item) => {
        dispatch({type: types.SET_SHOW_SEARCHBAR, payload: item})
    }

    const setGrantPicked = (item) => {
        dispatch({type: types.SET_GRANT_PICKED, payload: item})
    }

    const setGrantSaved = (item) => {
        dispatch({type: types.SET_GRANT_SAVED, payload: item})
    }

    const setPopup = (item) => {
        dispatch({type: types.SET_POPUP, payload: item})
    }

    const setGrantList = (item) => {
        dispatch({type: types.SET_GRANT_LIST, payload: item})
    }

    const setUserID = (item) => {
        dispatch({type: types.SET_USER_ID, payload: item})
    }

    const setFilterSelection = (item) => {
        dispatch({type: types.SET_FILTER_SELECTION, payload: item})
    }

    const setGrantListLoading = (item) => {
        dispatch({type: types.SET_GRANT_LIST_LOADING, payload: item})
    }

    const setCurrentUser = (item) => {
        dispatch({type: types.SET_CURRENT_USER, payload: item})
    }

    const setLikedState = (item) => {
        dispatch({type: types.SET_LIKED_STATE, payload: item})
    }

    const setTutorialState = (item) => {
        dispatch({type: types.SET_TUTORIAL_STATE, payload: item})
    }
    
    const setIndividualGrant = (item) => {
        dispatch({type: types.SET_INDIVIDUAL_GRANT, payload: item})
    }

    const setAdditionalFilters = (item) => {
        dispatch({type: types.SET_ADDITIONAL_FILTERS, payload: item})
    }

    const setAuthFailedState = (item) => {
        dispatch({type: types.SET_AUTH_FAILED, payload: item})
    }

    const setShortGrantList = (item) => {
        dispatch({type: types.SET_SHORT_GRANT_LIST, payload: item})
    }

    const setSubFilters = (item) => {
        dispatch({type: types.SET_SUBFILTERS, payload: item})
    }

    return{
        showSearchBar,
        setGrantPicked,
        setGrantSaved,
        setPopup,
        setGrantList,
        setUserID,
        setFilterSelection,
        setGrantListLoading,
        setCurrentUser,
        setLikedState,
        setTutorialState,
        setIndividualGrant,
        setAdditionalFilters,
        setAuthFailedState,
        setShortGrantList,
        setSubFilters
    }
}
