import React from 'react'
import classes from './ReportAtPortal.module.css'
import img from '../../../../Assets/Images/icon-reporting.png'

const ReportAtPortal = ({ title, link }) => {
    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <h3>{title}</h3>
                <a href={link} className="dark-purple" target="_blank">Go to Portal</a>
            </div>
            <div className={classes.imgContainer}>
                <img src={img} />
            </div>
        </div>
    )
}

export default ReportAtPortal