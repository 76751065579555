import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { StoreContext } from '../../../Store/StoreContext'
import classes from './PaginationBar.module.css'

const PaginationBar = () => {
    const { state, actions } = useContext(StoreContext)
    const [pagItems, setPagItems] = useState([])

    useEffect(() => {
        let tempState = { ...state }
        let count = Math.ceil(tempState.grantCount / 10)
        let items = []
        let flag = true, flag2 = true
        // If there 5 or less paginations, just show all 5 pagination
        if (count <= 5) {
            for (let x = 1; x <= count; x++) {
                if (x === state.filterSelection.page) {
                    items.push(<button key={x} onClick={() => changePagination(x)} className={classes.active}>{x}</button>)
                }
                else {
                    items.push(<button key={x} onClick={() => changePagination(x)} >{x}</button>)
                }
            }
        } else {
            // Else do this
            for (let x = 1; x <= count; x++) {
                // If x equals the current page, display it with the active class 
                if (x === state.filterSelection.page) {
                    items.push(<button key={x} onClick={() => changePagination(x)} className={classes.active}>{x}</button>)
                }
                // If the pagination is the first page, last page, 1 less than the current page, or one more than the current page, display it as a regular pagination number
                else if (x == 1 || x == count || (x >= (state.filterSelection.page - 1) && x <= (state.filterSelection.page + 1))) {
                    items.push(<button key={x} onClick={() => changePagination(x)} >{x}</button>)
                }
                // If the pagination meets none of the above criteria, the flag has not been triggered, and is less than the current page, display the goTo button and change flag to false
                else if (flag && x > state.filterSelection.page) {
                    items.push(<button key={x} onClick={() => goToPage()} >...</button>)
                    flag = false
                }
                // If the pagination meets none of the above criteria, the flag2 has not been triggered, and is more than the current page, display the goTo button and change flag2 to false
                else if (flag2 && x < state.filterSelection.page){
                    items.push(<button key={x} onClick={() => goToPage()} >...</button>)
                    flag2 = false
                }
            }
        }

        setPagItems(items)
    }, [state.grantList, state.filterSelection.page])

    const changePagination = (x) => {

        if (x !== state.filterSelection.page) {
            actions.setFilterSelection({ page: x })
        }
    }

    const goToPage = () => {
        let result = prompt("Go to page " + 1 + '-' + Math.ceil(state.grantCount / 10))
        if (result !== null && result !== '') {
            let num = parseInt(result)
            if (num !== state.filterSelection.page) {
                actions.setFilterSelection({ page: num })
            }
        }
    }


    return (
        <div className={classes.container}>
            {pagItems}
        </div>
    )
}

export default PaginationBar