import classes from './ChangePassword.module.css'
import React, { useState } from 'react'
import instance from '../../../Assets/Axios/AxiosInstance'
import Loading from '../../../Components/UI/Loading/Loading'
import InputArrayMapper from '../../../Components/UI/CustomInputs/InputArrayMapper/InputArrayMapper'
import { Link } from 'react-router-dom'
import CustomButton from '../../../Components/UI/CustomButton/CustomButton'
import { checkPassword } from '../../../Assets/Functions/ProfileCreation'

const ChangePassword = () => {
    const [profileState, setProfileState] = useState({
        form: {
            current_password: {
                type: 'password',
                label: "Current Password",
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                id: 'cpassword',
                edit: true,
                value: '',
                entryValue: 'old_password'
            },
            new_password: {
                type: 'password',
                label: "New Password",
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                id: 'npassword',
                edit: true,
                value: '',
                entryValue: 'new_password1'
            },
            confirm_new_password: {
                type: 'password',
                label: "Confirm New Password",
                elementConfig: {
                    placeholder: '',
                    required: true
                },
                id: 'cnpassword',
                edit: true,
                value: '',
                entryValue: 'new_password2'
            },
        },
        loading: true,
        editForm: false,
        formComplete: false
    })



    const formChange = (e, i) => {
        if (e instanceof Date) {
            setProfileState(old => {
                let temp = { ...old }
                temp.form[i].elementConfig.selected = e
                return temp
            })
        }
        else {
            e.persist()
            setProfileState(old => {
                let temp = { ...old }
                temp.form[i].value = e.target.value
                return temp
            })
        }
    }



    const formSavedSuccessfully = () => {
        setProfileState(old =>{
            let result = {...old}
            result.form.confirm_new_password.hint = <p className={classes.success}>Password Successfully Changed</p>
            result.form.new_password.hint = null
            return result
        })
    }


    const saveForm = (e) => {
        e.preventDefault()
        let temp = profileState.form
        let res = checkPassword(temp.new_password.value, temp.confirm_new_password.value)
        let formdata = new FormData()
        formdata.append(temp['current_password'].entryValue, temp['current_password'].value)
        formdata.append(temp['new_password'].entryValue, temp['new_password'].value)
        formdata.append( temp['confirm_new_password'].entryValue, temp['confirm_new_password'].value)
        if (res.flag) {
            setProfileState(old =>{
                let result = {...old}
                result.form.new_password.hint = <p className={classes.err}>{res.msg}</p>
                result.form.confirm_new_password.hint = null
                return result
            })
        } else {
            instance.post('/accounts/password_change/', formdata).then(res => {
                console.log('success', res)
                formSavedSuccessfully()
            }).catch(err => {
                console.log(err.response)
            })
        }
    }

    return (
        <form className={classes.form}>
            <div className={classes.header}>
                <h2>Change Password</h2>
            </div>
            <InputArrayMapper profileState={profileState} classes={classes} formChange={formChange} />
            <Link to="/account/">Cancel</Link>
            <div className={classes.formButtons}>
                <CustomButton title="Update" css="dark-purple" onClick={(e) => saveForm(e)} />
            </div>
        </form>
    )
}

export default ChangePassword